import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { History } from 'history';
import {
  IconButton, Menu, MenuItem, Divider, ListItemIcon, ListItemText,
  Tooltip, Grid, Avatar
} from '@mui/material';

import SmsIcon from '@mui/icons-material/Sms';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Payment from '@mui/icons-material/Payment';
import BuildIcon from '@mui/icons-material/Build';
import SettingsInputComponent from '@mui/icons-material/SettingsInputComponent';
import Settings from '@mui/icons-material/Settings';
import GetApp from '@mui/icons-material/GetApp';
import Code from '@mui/icons-material/Code';
import MeetingRoom from '@mui/icons-material/MeetingRoom';
import Apartment from '@mui/icons-material/Apartment';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MapIcon from '@mui/icons-material/MapOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BusinessIcon from '@mui/icons-material/Business';

import { withStyles } from '@mui/styles';
import { Link, Redirect } from 'react-router-dom';

import * as loginActions from '../../routes/Login/login.actions';

import {
  length,
  pathOr,
  includes,
  equals,
  cond,
  T,
} from 'ramda';
import './header.css';

import DircardDialog from '../DiscardDialog/DiscardDialog.component';
import BannerComponent from './banners.component';
import { Office } from '../../routes/Login/login.reducer';
import { useSelector } from '../../reducers';
import { GHLIcon } from '../../routes/Settings/routes/Integration/gohighlevel.page';

import { useStyles } from './header.styles';

const discardList = ['messages/new/', 'recurring/edit/', 'birthday/edit/', 'reactivation/edit/', 'apptchange/edit/', 'smart-reply-old/edit/'];

const GroupMenu = withStyles({
  paper: {
    width: 'min-content',
  },
})(Menu);

const newStyle = {
  backgroundColor: '#FFF',
  color: 'rgb(117,117,117)'
};

type HeaderProps = {
  history?: History;
  desktopOpen: boolean;
  setDesktopOpen: (open: boolean) => void;
  handleDrawerToggle: () => void;
  classes?: {
    menuButton?: string;
    desktopMenuButton?: string;
  };
}

function Header({
  history,
  handleDrawerToggle,
  desktopOpen,
  setDesktopOpen,
  classes,
}: HeaderProps) {
  const headerClasses = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ref = useRef<HTMLDivElement>(null);
  const open = Boolean(anchorEl);
  const [sideAnchorEl, setSideAnchorEl] = React.useState(null);
  const [discardOpen, setDiscardOpen] = React.useState(false);
  const swapOpen = Boolean(sideAnchorEl);

  const {
    user,
    admin,
    officeAdmin,
    selected,
    status,
    office,
    group,
    agency,
    features,
    parentOfficeFeatures,
    verifiedEmailStatus,
    tax,
  } = useSelector((state) => ({
    office: state.login.office,
    admin: state.login.admin,
    officeAdmin: state.login.officeAdmin,
    selected: state.login.selected,
    status: state.login.status,
    group: state.login.group,
    agency: state.login.agency,
    user: state.login.user,
    features: pathOr([], ['login', 'features'])(state),
    parentOfficeFeatures: pathOr([], ['login', 'parentOfficeFeatures'])(state),
    verifiedEmailStatus: pathOr('NotRequested', ['general', 'emailStatus'])(state),
    tax: pathOr(null, ['login', 'tax'])(state),
  }));

  const emailLetter = user.email ? user.email[0].toUpperCase() : 'U';
  const userLetter = user.name ? user.name[0].toUpperCase() : emailLetter;

  const destroySession = () => dispatch(loginActions.destroySession());
  const resetSelectedOffice = () => dispatch(loginActions.resetSelectedOffice());
  const selectOffice = (i: Office, h: History) => dispatch(loginActions.selectOffice(i, h));
  const getUserData = () => dispatch(loginActions.getUserData());

  const handleBack = () => {
    const pathname = history?.location.pathname;
    for (let i = 0; i < discardList.length; i++) {
      if (pathname.includes(discardList[i])) {
        setDiscardOpen(true);
        return;
      }
    }
    history?.goBack();
  };

  const handleDiscard = () => {
    setDiscardOpen(false);
    history?.goBack();
  };

  useEffect(() => {
    if (!user.id) {
      getUserData();
    }
  }, [user]);

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      dispatch(loginActions.patch({ headerHeight: ref.current.clientHeight || 54 }));
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  return (
    <Grid ref={ref}>
      <BannerComponent />
      <header key="header" style={newStyle} className={headerClasses.root}>
        {(!selected && (admin || agency)) && <Redirect to="/settings/select-office" />}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.menuButton}
          onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
        {!desktopOpen && <IconButton
          color="inherit"
          aria-label="open drawer"
          className={classes.desktopMenuButton}
          onClick={() => setDesktopOpen(true)}>
          <ArrowForwardIosIcon className={headerClasses.arrowIcon} />
        </IconButton>}
        <Grid display="flex" alignItems="center">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon className={headerClasses.navigateIcon} />
          </IconButton>
          <IconButton onClick={() => history?.goForward()}>
            <ArrowForwardIcon className={headerClasses.navigateIcon} />
          </IconButton>
        </Grid>
        <div style={{ flexGrow: 1 }}></div>
        <div className="header-right">
          {!includes(office.ehrSystem, ['Genesis', 'None', 'PlatinumApi', 'ChiroHD']) &&
            <h1 style={{ fontSize: 14 }}>
              {status}
              {cond([
                [equals('Connected'), () => (
                  <Tooltip
                    title="Your SKED server is alive and well!"
                    placement="bottom-start"
                    arrow>
                    <CheckCircleIcon
                      style={{
                        verticalAlign: 'middle',
                        fontSize: '20px',
                        color: 'green',
                        marginLeft: '5px',
                        marginBottom: 2
                      }}
                    />
                  </Tooltip>
                )],
                [equals('Disconnected'), () => (
                  <Tooltip
                    title="Your SKED server is down! Contact us at info@sked.life for support."
                    placement="bottom-start"
                    arrow>
                    <ReportProblemIcon
                      style={{
                        verticalAlign: 'middle',
                        fontSize: '20px',
                        color: 'red',
                        marginLeft: '5px',
                        marginBottom: 2
                      }}
                    />
                  </Tooltip>
                )],
                [equals('Not Installed'), () => (
                  <Tooltip
                    title="Your SKED server is down! Contact us at info@sked.life for support."
                    placement="bottom-start"
                    arrow>
                    <ReportProblemIcon
                      style={{
                        verticalAlign: 'middle',
                        fontSize: '20px',
                        color: 'red',
                        marginLeft: '5px',
                        marginBottom: 2
                      }}
                    />
                  </Tooltip>
                )],
                [T, () => (<span />)]
              ])(status)}
            </h1>}
          <GroupMenu
            style={{ width: 'auto' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            id="swap-menu"
            anchorEl={sideAnchorEl}
            open={swapOpen}
            onClose={() => setSideAnchorEl(null)}>
            {group.map((member) => {
              return (
                <MenuItem
                  className={'sked-test-kebab-' + member.id}
                  key={member.id}
                  onClick={() => {
                    selectOffice(member, history);
                    setSideAnchorEl(null);
                    setAnchorEl(null);
                  }}>
                  <ListItemText primary={member.name} />
                </MenuItem>
              );
            })}
          </GroupMenu>
          <div style={{ marginRight: 10 }} />
          <Menu
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}>
            {(!admin && !agency) && (
              <MenuItem
                className={'sked-test-kebab-update-profile'}
                onClick={() => setAnchorEl(null)}>
                <Link to="/settings/profile">
                  <ListItemIcon>
                    <Avatar style={{ width: 26, height: 26 }}>{userLetter}</Avatar>
                  </ListItemIcon>
                  <ListItemText primary='Update Profile' />
                </Link>
              </MenuItem>
            )}
            {(admin || agency) && <MenuItem
              className={'sked-test-kebab-select-office'}
              onClick={() => {
                setAnchorEl(null);
                resetSelectedOffice();
              }}>
              <ListItemIcon>
                <Apartment fontSize="large" />
              </ListItemIcon>
              <ListItemText primary='Select Office' />
            </MenuItem>}
            {length(group) > 1 && !includes('HideOfficeGroup', parentOfficeFeatures) && !agency &&
              <MenuItem
                className={'sked-test-kebab-change-office'}
                onClick={(e) => setSideAnchorEl(e.currentTarget)}>
                <ListItemIcon>
                  <SwapHoriz fontSize="large" />
                </ListItemIcon>
                <ListItemText primary='Change Office' />
                <ArrowRight />
              </MenuItem>}
            <MenuItem
              className={'sked-test-kebab-billing-information'}
              onClick={() => setAnchorEl(null)}>
              <Link to="/settings/subscription">
                <ListItemIcon>
                  <Payment fontSize="large" />
                </ListItemIcon>
                <ListItemText primary='Billing Information' />
              </Link>
            </MenuItem>
            {(officeAdmin || admin || agency) && (
              <MenuItem
                className={includes('MultipleOfficeUsers', features) ? 'sked-test-kebab-admin-settings' : 'sked-test-kebab-business'}
                onClick={() => setAnchorEl(null)}>
                <Link to="/settings/business">
                  <ListItemIcon>
                    {includes('MultipleOfficeUsers', features) ? <BuildIcon fontSize="large" /> : <BusinessIcon fontSize="large" />}
                  </ListItemIcon>
                  <ListItemText primary={includes('MultipleOfficeUsers', features) ? 'Admin Settings' : 'Business'} />
                </Link>
              </MenuItem>
            )}
            {includes('Locations', features) &&
              <MenuItem
                className={'sked-test-kebab-locations'}
                onClick={() => setAnchorEl(null)}>
                <Link to="/settings/locations">
                  <ListItemIcon>
                    <MapIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Locations' />
                </Link>
              </MenuItem>}
            <MenuItem
              className={'sked-test-kebab-integration'}
              onClick={() => setAnchorEl(null)}>
              <Link to="/settings/integration">
                <ListItemIcon>
                  <SettingsInputComponent fontSize="large" />
                </ListItemIcon>
                <ListItemText primary='Locations' />
              </Link>
            </MenuItem>
            <MenuItem
              className={'sked-test-kebab-integration'}
              onClick={() => setAnchorEl(null)}>
              <Link to="/settings/integration">
                <ListItemIcon>
                  <SettingsInputComponent fontSize="large" />
                </ListItemIcon>
                <ListItemText primary='Integration' />
              </Link>
            </MenuItem>
            {includes('GoHighLevel', features) &&
            <MenuItem
              className={'sked-test-kebab-ghl-integration'}
              onClick={() => setAnchorEl(null)}>
              <Link to="/settings/gohighlevel">
                <ListItemIcon>
                  <GHLIcon/>
                </ListItemIcon>
                <ListItemText primary='GoHighLevel Integration' />
              </Link>
            </MenuItem>}
            <MenuItem
              className={'sked-test-kebab-notification-settings'}
              onClick={() => setAnchorEl(null)}>
              <Link to="/settings/general">
                <ListItemIcon>
                  <Settings fontSize="large" />
                  {includes(verifiedEmailStatus, ['Requested', 'ErrorWithRequest']) &&
                  <div style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '10px',
                    backgroundColor: 'red',
                    position: 'absolute',
                    top: '7px',
                    left: '27px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: '10px',
                  }}>
                    1
                  </div>}
                </ListItemIcon>
                <ListItemText >
                Notification Settings
                </ListItemText>
              </Link>
            </MenuItem>
            <MenuItem
              className={'sked-test-kebab-legacy-webmodule'}
              onClick={() => setAnchorEl(null)}
              disabled={!includes('NPPortal', features)}>
              <Link to="/settings/webmodule">
                <ListItemIcon>
                  <Code fontSize="large" />
                </ListItemIcon>
                <ListItemText primary='Legacy Webmodule' />
              </Link>
            </MenuItem>
            {includes('CLA', features) &&
          <MenuItem
            className={'sked-test-kebab-cla-integration'}
            onClick={() => setAnchorEl(null)}>
            <Link to="/settings/cla">
              <ListItemIcon>
                <SettingsInputComponent fontSize="large" />
              </ListItemIcon>
              <ListItemText primary='CLA Integration' />
            </Link>
          </MenuItem>}
            {admin &&
            <MenuItem
              className={'sked-test-kebab-sked-client-installer'}
              onClick={() => setAnchorEl(null)}>
              <Link to="/settings/client-installer">
                <ListItemIcon>
                  <GetApp fontSize="large" />
                </ListItemIcon>
                <ListItemText primary='SKED Client Installer' />
              </Link>
            </MenuItem>}
            <MenuItem
              className={'sked-test-kebab-sms-messaging'}
              onClick={() => setAnchorEl(null)}>
              <Link to="/settings/sms-number">
                <ListItemIcon>
                  <SmsIcon fontSize="large" />
                  {tax && !tax.ein &&
                    <div style={{
                      width: '15px',
                      height: '15px',
                      borderRadius: '10px',
                      backgroundColor: 'red',
                      position: 'absolute',
                      top: '7px',
                      left: '27px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontSize: '10px',
                    }}>
                      1
                    </div>}
                </ListItemIcon>
                <ListItemText primary='SMS Messaging' />
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem
              className={'sked-test-kebab-sign-out'}
              onClick={() => {
                setAnchorEl(null);
                destroySession();
              }}>
              <ListItemIcon>
                <MeetingRoom fontSize="large" />
              </ListItemIcon>
              <ListItemText primary='Sign out' />
            </MenuItem>
          </Menu>
        </div>
        <DircardDialog
          open={discardOpen}
          onCancel={() => setDiscardOpen(false)}
          onDiscard={handleDiscard}
          title='Discard changes?'
          text="You haven't saved your changes. are you sure you want to leave?"
        />
      </header>
    </Grid>
  );
}

export default Header;
