import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const CopyIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 2.75035C14.8069 1.78492 13.4288 1.53049 12.4388 2.2065L7.34824 5.68297L5.49717 4.41868C6.18641 3.25805 5.86221 1.75617 4.71465 0.972553C3.50282 0.145218 1.83316 0.432331 0.984546 1.61404C0.136314 2.79581 0.430454 4.42462 1.64232 5.2523C2.47388 5.82018 3.52133 5.86276 4.37071 5.4518L6.63793 7.00009L4.37091 8.54821C3.52154 8.13745 2.47415 8.18003 1.64252 8.74771C0.430718 9.57539 0.136548 11.204 0.984751 12.3858C1.83336 13.5677 3.5032 13.8548 4.71483 13.0275C5.86224 12.2438 6.18638 10.742 5.49735 9.58133L7.34841 8.31704L12.4388 11.7933C13.4288 12.4695 14.8069 12.2149 15.5 11.2495L9.27702 7.00006L15.5 2.75035ZM3.18439 4.47234C2.4176 4.47234 1.79595 3.86603 1.79595 3.11816C1.79595 2.37028 2.41763 1.76397 3.18439 1.76397C3.95135 1.76397 4.57283 2.37028 4.57283 3.11816C4.57283 3.86603 3.95135 4.47234 3.18439 4.47234ZM3.18439 12.2363C2.4176 12.2363 1.79595 11.63 1.79595 10.8821C1.79595 10.1343 2.4176 9.52796 3.18439 9.52796C3.95135 9.52796 4.57283 10.1343 4.57283 10.8821C4.57283 11.63 3.95135 12.2363 3.18439 12.2363Z" fill={color || '#4F4F55'}/>
    </svg>
  );
};

export default CopyIcon;
