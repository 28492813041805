import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const QuickReplyIcon = ({ color, className }: Props) => {
  return (
    <svg width="30.131" height="16.495" viewBox="0 0 30.131 16.495" className={className}>
      <g id="Group_17747" data-name="Group 17747" transform="translate(-1241.094 -2096.011)">
        <path id="Path_9719" data-name="Path 9719" d="M51.317,188H44.954a.954.954,0,0,0,0,1.909h6.362a.954.954,0,0,0,0-1.909Z" transform="translate(1200.273 1909.032)" fill={color || '#4d4d4d'}/>
        <path id="Path_9717" data-name="Path 9717" d="M36.271,284.954a.954.954,0,0,0-.954-.954H28.954a.954.954,0,1,0,0,1.909h6.362A.954.954,0,0,0,36.271,284.954Z" transform="translate(1216.136 1821.562)" fill={color || '#4d4d4d'}/>
        <path id="Path_9718" data-name="Path 9718" d="M14.18,236.954a.954.954,0,0,0-.954-.954H4.954a.954.954,0,0,0,0,1.909h8.271A.954.954,0,0,0,14.18,236.954Z" transform="translate(1237.094 1864.85)" fill={color || '#4d4d4d'}/>
        <g id="Group_17270" data-name="Group 17270" transform="translate(1253.465 2096.011)">
          <path id="Path_5991" data-name="Path 5991" d="M14.913,8.269q-4.066,0-8.132,0H2.846A2.852,2.852,0,0,0,0,11.118q0,3.805,0,7.611a2.856,2.856,0,0,0,2.855,2.86c.309,0,.618,0,.95,0,0,.842,0,1.654,0,2.467a.643.643,0,0,0,.371.644.625.625,0,0,0,.714-.122q1.726-1.44,3.458-2.873a.536.536,0,0,1,.315-.111c2.087-.006,4.174,0,6.261-.006a2.858,2.858,0,0,0,2.833-2.841v-.223q0-3.7,0-7.407a2.852,2.852,0,0,0-2.845-2.848M12.7,13.659a1.269,1.269,0,1,1-1.28,1.262,1.274,1.274,0,0,1,1.28-1.262m-3.832,0a1.269,1.269,0,1,1-1.253,1.269,1.273,1.273,0,0,1,1.253-1.269M5.063,16.2a1.269,1.269,0,1,1,1.281-1.263A1.274,1.274,0,0,1,5.063,16.2" transform="translate(-0.001 -8.268)" fill={color || '#4d4d4d'}/>
        </g>
      </g>
    </svg>
  );
};

export default QuickReplyIcon;
