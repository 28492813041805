// TODO: Put admin stuff in the private routes
import React, { Suspense, useState } from 'react';
import { includes, cond, T, pathOr, path } from 'ramda';
import { useDispatch } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TextField, Paper, Container, CircularProgress } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { useSKEDAdmin } from './services/init.hook.js';
import { checkUser, patch, resetSelectedOffice } from './routes/Login/login.actions';
import Alerts from './components/Alerts/alerts.component';
import Snackbar from './components/Snackbar/snackbar.component';
import Header from './components/Header/header.component';
import AdminHeader from './components/Header/admin-header.component';
import DrawerMenu from './components/DrawerMenu/DrawerMenu.component';
import { useSelector } from './reducers';
import './styles/main.css';
import { SPARK_FEATURE } from './routes/Login/login.reducer';

const Dashboard = React.lazy(() => import('./routes/Dashboard/dashboard.component.jsx'));
const CalendarV2 = React.lazy(() => import('./routes/CalendarV2/calendar.component'));
const OverviewReport = React.lazy(() => import('./routes/OverviewReport/overview.component.jsx'));
const Schedule = React.lazy(() => import('./routes/Schedule/schedule.component.jsx'));
const Clients = React.lazy(() => import('./routes/Clients/Clients.page'));
const Leads = React.lazy(() => import('./routes/Leads/Leads.page'));
const OfficeHours = React.lazy(() => import('./routes/OfficeHours/OfficeHourTabs.jsx'));
const SpecialHoursPage = React.lazy(() => import('./routes/OfficeHours/SpecialHours.page.jsx'));
const EventHoursPage = React.lazy(() => import('./routes/OfficeHours/EventHours.page.jsx'));
const Messages = React.lazy(() => import('./routes/Messages/messages.component.jsx'));
const Inbox = React.lazy(() => import('./routes/Messages/Inbox/Inbox.page'));
const MessagesNew = React.lazy(() => import('./routes/Messages/routes/MessagesNew/messages-new.component.jsx'));
const MessageView = React.lazy(() => import('./routes/Messages/routes/MessagesView/messages-view.component.jsx'));
const MessageThread = React.lazy(() => import('./routes/Messages/routes/MessagesThread/messages-thread.component.jsx'));
const InboxChat = React.lazy(() => import('./routes/Messages/Inbox/InboxChat/InboxChat.component'));
const BirthdayMessages = React.lazy(() => import('./routes/RecurringMessage/birthday.page.jsx'));
const ReminderMessages = React.lazy(() => import('./routes/RecurringMessage/reminders.page.jsx'));
const ReactivationMessages = React.lazy(() => import('./routes/RecurringMessage/reactivation.page.jsx'));
const AutomationMessages = React.lazy(() => import('./routes/RecurringMessage/automation.page.jsx'));
const ChangeMessages = React.lazy(() => import('./routes/RecurringMessage/changes.page.jsx'));
const AutomatedMessageEdit = React.lazy(() => import('./routes/RecurringMessage/routes/RecurringEdit/automated-edit.page.jsx'));
const AutomatedMessageView = React.lazy(() => import('./routes/RecurringMessage/routes/RecurringView/automated-view.page.jsx'));
const AutoResponderMessages = React.lazy(() => import('./routes/RecurringMessage/autoresponder.page.jsx'));

const Templates = React.lazy(() => import('./routes/Templates/templates.component'));
const TemplateEdit = React.lazy(() => import('./routes/Templates/routes/TemplatesEdit/templates-edit.component'));
const Reviews = React.lazy(() => import('./routes/Reviews/reviews.component.jsx'));
const Announcements = React.lazy(() => import('./routes/Announcements/announcements.component'));
const Support = React.lazy(() => import('./routes/Support/support.component.jsx'));
const SettingsBusiness = React.lazy(() => import('./routes/Settings/routes/Business/business.component.jsx'));
const SettingsLocations = React.lazy(() => import('./routes/Settings/routes/Locations/locations.page'));
const SettingsInstaller = React.lazy(() => import('./routes/Settings/routes/Installer/installer.component.jsx'));
const Integration = React.lazy(() => import('./routes/Settings/routes/Integration/integration.component.jsx'));
const Profile = React.lazy(() => import('./routes/Settings/routes/Profile/profile.component'));
const Webmodule = React.lazy(() => import('./routes/Settings/routes/Webmodule/webmodule.component.jsx'));
const SettingsSubscription = React.lazy(() => import('./routes/Settings/routes/Subscription/subscription.component.jsx'));
const General = React.lazy(() => import('./routes/Settings/routes/General/general.component.jsx'));
const Professionals = React.lazy(() => import('./routes/Professionals/professionals.component.jsx'));
const AppSettings = React.lazy(() => import('./routes/Settings/routes/App/app.component.jsx'));
const ReviewsSettings = React.lazy(() => import('./routes/Settings/routes/Reviews/reviews.component.jsx'));
const GoogleIntegration = React.lazy(() => import('./routes/Settings/routes/GoogleIntegration/GoogleIntegration.page'));
const Tutorials = React.lazy(() => import('./routes/Tutorials/tutorials.component.jsx'));
const Offline = React.lazy(() => import('./routes/Offline/offline.component.jsx'));
const Login = React.lazy(() => import('./routes/Login/login.component'));
const ForgotPassword = React.lazy(() => import('./routes/ForgotPassword/forgot-password.component.jsx'));
const PasswordReset = React.lazy(() => import('./routes/ForgotPassword/password-reset.component.jsx'));
const PasswordResetClient = React.lazy(() => import('./routes/ForgotPassword/password-reset-client.component.jsx'));
const Unsubscribe = React.lazy(() => import('./routes/Unsubscribe/unsubscribe.component.jsx'));
const PrivateRoute = React.lazy(() => import('./PrivateRoute'));
const SelectOffice = React.lazy(() => import('./routes/Settings/routes/SelectOffice/selectOffice.component'));
const Registration = React.lazy(() => import('./routes/Registration/registration.component.jsx'));
const SignUp = React.lazy(() => import('./routes/SignUp/SignUp.page'));
const AccountDeletion = React.lazy(() => import('./routes/Help/AccountDeletion.page'));
const ResetUser = React.lazy(() => import('./routes/Admin/reset-user.component'));
const CheckUnsynced = React.lazy(() => import('./routes/Admin/check-unsynced.component'));
const WaitingRoom = React.lazy(() => import('./routes/waiting/waiting.component.jsx'));
const WaitingRoomNext = React.lazy(() => import('./routes/waiting/FrontDesk.page'));
const Tags = React.lazy(() => import('./routes/Tags/tags.component.jsx'));
const TransferOffice = React.lazy(() => import('./routes/Admin/transfer-office.component.jsx'));
const TransferMessages = React.lazy(() => import('./routes/Admin/transfer-messages.page'));
const TransferForms = React.lazy(() => import('./routes/Admin/transfer-forms.page'));
const TutorialsV2 = React.lazy(() => import('./routes/Tutorial/index.page.jsx'));

const ScheduleBlocksPage = React.lazy(() => import('./routes/Schedule/blocks.page.jsx'));
const SMSNumber = React.lazy(() => import('./routes/Settings/routes/SMSNumber/sms-number.component.jsx'));
const NewPatientPortalSettings = React.lazy(() => import('./routes/Settings/routes/NewPatientPortal/NewPatientPortal.page.jsx'));
const NewPatientPortalClients = React.lazy(() => import('./routes/Settings/routes/NewPatientPortal/Clients.page.jsx'));
const NewPatientPortalSettingsList = React.lazy(() => import('./routes/Settings/routes/NewPatientPortal/NewPatientPortalList.page.jsx'));
const CLASettingsPage = React.lazy(() => import('./routes/Settings/routes/CLA/cla.page.jsx'));
const CLAMatchPage = React.lazy(() => import('./routes/Settings/routes/CLA/cla-match.page.js'));

const AppointmentsReportPage = React.lazy(() => import('./routes/Reports/Appointments.page.jsx'));
const StopManagersReportPage = React.lazy(() => import('./routes/Reports/StopManagers.page.jsx'));

const Help = React.lazy(() => import('./routes/Help/Help.component'));
import ErrorBoundary from './components/error-boundary';
const Affiliates = React.lazy(() => import('./routes/Admin/affiliates.component'));
const Banners = React.lazy(() => import('./routes/Admin/Banners/banners.page'));
const Agencies = React.lazy(() => import('./routes/Admin/Agencies/agencies.page'));
const Users = React.lazy(() => import('./routes/Admin/Users/users.page'));
const ProfileSettings = React.lazy(() => import('./routes/Admin/Profile/profile.page'));
const OfficeNotes = React.lazy(() => import('./components/office-notes.component'));
const ClientLists = React.lazy(() => import('./routes/ClientLists/clientlists.page'));
const ClientList = React.lazy(() => import('./routes/ClientLists/clientlist.page'));
const Campaigns = React.lazy(() => import('./routes/ClientLists/campaigns.page'));
const Campaign = React.lazy(() => import('./routes/ClientLists/campaign.page'));
const SparkCampaign = React.lazy(() => import('./routes/ClientLists/spark.page'));
const SparkReporting = React.lazy(() => import('./routes/AppointmentLists/spark-reporting.page'));
const Automations = React.lazy(() => import('./routes/Automations/automations.page'));
const TemplateAutomations = React.lazy(() => import('./routes/Templates/automation-templates.page.jsx'));
const StopManagers = React.lazy(() => import('./routes/StopManagers/stop-managers.page.jsx'));

const IntakeSettingsList = React.lazy(() => import('./routes/IntakeForms/intake-list.page'));
const IntakeSettingsEdit = React.lazy(() => import('./routes/IntakeForms/intake-edit.page'));
const IntakeSubmissions = React.lazy(() => import('./routes/IntakeForms/intake-submissions.page'));
const IntakeSubmission = React.lazy(() => import('./routes/IntakeForms/intake-submission.page'));

const AutomationBaseMessageView = React.lazy(() => import('./routes/AutomationBasedMessage/view-message.page'));
const TimeBasedMessageEdit = React.lazy(() => import('./routes/AutomationBasedMessage/time-based-edit.page'));
const SmartReplyMessageEdit = React.lazy(() => import('./routes/AutomationBasedMessage/smart-reply-edit.page'));
const AutomationBasedTemplateEdit = React.lazy(() => import('./routes/Templates/automation-based-template-edit.page'));
const AutomationBasedMessageList = React.lazy(() => import('./components/AutomationBasedMessage/list-automation-message.component'));

const GoHighLevel = React.lazy(() => import('./routes/Settings/routes/Integration/gohighlevel.page'));
const SparkWebinar = React.lazy(() => import('./routes/SparkWebinar/SparkWebinar.page'));
const QuickReplies = React.lazy(() => import('./routes/Settings/routes/QuickReplies/quick-replies.page'));

import { useStyles } from './styles/app.styles';
import { skedTheme, sparkTheme } from './theme';
import { str2ab } from './services/utilities';
import Logo from '../src/images/logo-blue.png';

const attemptDecryption = async (value: string) => {
  try {
    const encryptedSalt = localStorage.getItem('ss');
    const salt = new Uint8Array(str2ab(encryptedSalt));
    const encodedPass = new Uint8Array(16);
    let i = 0;
    for (const n of new TextEncoder().encode(value)) {
      encodedPass[i] = n;
      i = i + 1;
    }
    for (const n of salt) {
      encodedPass[i] = n;
      i = i + 1;
    }
    const key = await window.crypto.subtle.importKey(
      'raw',
      encodedPass,
      'AES-GCM',
      true,
      [
        'encrypt',
        'decrypt',
      ]);
    const session = localStorage.getItem('sked-session');
    const decrypted = await window.crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: new Uint8Array(12) },
      key,
      str2ab(session),
    );
    const decoded = new window.TextDecoder().decode(new Uint8Array(decrypted));
    localStorage.setItem('sked-session', decoded);
    if (decoded) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const Main = ({ location, history }: RouteComponentProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);

  const setDesktopOpen = (value: boolean) => {
    dispatch(patch({ desktopOpen: value }));
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const {
    isAdmin,
    desktopOpen,
    isSpark,
  } = useSelector((state) => ({
    isAdmin: pathOr(false, ['login', 'admin'], state),
    desktopOpen: pathOr(true, ['login', 'desktopOpen'], state),
    isSpark: includes(SPARK_FEATURE, path(['login', 'features'], state)),
  }));

  // inits the websocket connections,
  // gets unread messages,
  // gets reviews
  // gets notifications for waiting room
  useSKEDAdmin();

  const [notesOpen, setNotesOpen] = useState(false);

  const handleKeyPress = React.useCallback((event) => {
    if (event.ctrlKey === true) {
      if (event.key === ';' && isAdmin) {
        dispatch(resetSelectedOffice());
      }
    }
  }, [isAdmin]);

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <React.Fragment>
      {(desktopOpen || mobileOpen) ? (
        <DrawerMenu
          history={history}
          handleDrawerToggle={handleDrawerToggle}
          desktopOpen={desktopOpen}
          mobileOpen={mobileOpen}
          setDesktopOpen={setDesktopOpen}
          location={location as unknown as Location} />
      ) : null}
      <main className={'sked-content ' + classes.main} style={{ marginLeft: (!desktopOpen ? '0' : '') }}>
        {isAdmin &&
          <div
            className={classes.key}>
            <div
              id={notesOpen ? 'notes-main-open' : 'notes-main'}
              style={{
                top: '0px',
                backgroundColor: 'white',
                cursor: notesOpen ? 'unset' : 'pointer',
              }}
              onClick={notesOpen ? undefined : () => {
                setNotesOpen(true);
              }}>
              <OfficeNotes isOpen={notesOpen} close={() => setNotesOpen(false)} />
            </div>
          </div>}
        <Header
          handleDrawerToggle={handleDrawerToggle}
          desktopOpen={desktopOpen}
          setDesktopOpen={setDesktopOpen}
          classes={classes}
          history={history} 
        />
        <div id='monkey' className={classes.contentScrollContainer} style={{ maxWidth: (!desktopOpen ? '100vw' : '') }}>
          <div id='monkey-inner' className={classes.contentInner}>
            <Suspense fallback={<Loading />}>
              <DndProvider backend={HTML5Backend}>
                <Switch>
                  <Route exact path='/' render={() => (<Redirect to={isSpark ? '/clients' : '/dashboard'} />)} />
                  <Route path='/dashboard' component={Dashboard} />
                  <Route path='/calendar-v2' component={CalendarV2} />
                  <Route path='/schedule' component={Schedule} />
                  <Route path='/clients' component={Clients} />
                  <Route path='/leads' component={Leads} />
                  <Route path='/professionals' component={Professionals} />
                  <Route path='/office-hours' component={OfficeHours} />
                  <Route path='/special-office-hours' component={SpecialHoursPage} />
                  <Route path='/office-events' component={EventHoursPage} />
                  <Route path='/messages/new/:id' component={MessagesNew} />
                  <Route path='/messages/view/:id' component={MessageView} />
                  <Route path='/inbox/thread/:id' component={MessageThread} />
                  <Route path='/inbox/chat/:id' component={InboxChat} />
                  <Route path='/messages' component={Messages} />
                  <Route path='/recurring/edit/:id' component={AutomatedMessageEdit} />
                  <Route path='/recurring/view/:id' component={AutomatedMessageView} />
                  <Route path='/change-messages' component={ChangeMessages} />
                  <Route path='/birthday-messages' component={BirthdayMessages} />
                  <Route path='/reminder-messages' component={ReminderMessages} />
                  <Route path='/reactivation-messages' component={ReactivationMessages} />
                  <Route path='/reactivation/edit/:id' component={AutomatedMessageEdit} />
                  <Route path='/reactivation/view/:id' component={AutomatedMessageView} />
                  <Route path='/birthday/edit/:id' component={AutomatedMessageEdit} />
                  <Route path='/birthday/view/:id' component={AutomatedMessageView} />
                  <Route path='/apptchange/edit/:id' component={AutomatedMessageEdit} />
                  <Route path='/apptchange/view/:id' component={AutomatedMessageView} />
                  <Route path='/autoresponder-messages' component={AutoResponderMessages} />
                  <Route path='/smart-reply-messages' component={AutomationMessages} />
                  <Route path='/smart-reply-old/edit/:id' component={AutomatedMessageEdit} />
                  <Route path='/smart-reply/view/:id' component={AutomatedMessageView} />
                  <Route path='/automation-messages' component={AutomationMessages} />
                  <Route path='/automation/edit/:id' component={AutomatedMessageEdit} />
                  <Route path='/automation/view/:id' component={AutomatedMessageView} />
                  <Route path='/inbox' component={Inbox} />
                  <Route path='/reviews' component={Reviews} />
                  <Route path='/announcements' component={Announcements} />
                  <Route path='/support' component={Support} />
                  <Route path='/tutorials' component={Tutorials} />
                  <Route path='/offline' component={Offline} />
                  <Route path='/waiting' component={WaitingRoom} />
                  <Route path='/front-desk' component={WaitingRoomNext} />
                  <Route path='/tags' component={Tags} />
                  <Route path='/settings/sms-number' component={SMSNumber} />
                  <Route path='/settings/business' component={SettingsBusiness} />
                  <Route path='/settings/locations' component={SettingsLocations} />
                  <Route path='/settings/integration' component={Integration} />
                  <Route path='/settings/gohighlevel' component={GoHighLevel} />
                  <Route path='/settings/profile' component={Profile} />
                  <Route path='/settings/subscription' component={SettingsSubscription} />
                  <Route path='/settings/client-installer' component={SettingsInstaller} />
                  <Route path='/settings/webmodule' component={Webmodule} />
                  <Route path='/settings/general' component={General} />
                  <Route path='/settings/app' component={AppSettings} />
                  <Route path="/settings/reviews" component={ReviewsSettings} />
                  <Route path="/settings/google-integration" component={GoogleIntegration} />
                  <Route path='/settings/new-patient-portal' exact component={NewPatientPortalSettingsList} />
                  <Route path='/settings/new-patient-portal/:id' component={NewPatientPortalSettings} />
                  <Route path='/settings/new-patient-portal-clients/:id' component={NewPatientPortalClients} />
                  <Route path='/settings/cla' exact component={CLASettingsPage} />
                  <Route path='/settings/cla/match' component={CLAMatchPage} />
                  <Route path='/settings/quick-replies' component={QuickReplies} />
                  <Route path='/reports/overview' component={OverviewReport} />
                  <Route path='/reports/appointments' component={AppointmentsReportPage} />
                  <Route path='/reports/stops' component={StopManagersReportPage} />
                  <Route path="/help" component={Help} />
                  <Route path='/schedule/blocks/:id' exact component={ScheduleBlocksPage} />
                  <Route path='/stop-managers' component={StopManagers} />
                  <Route path="/getting-started" component={TutorialsV2} />
                  <Route path="/client-lists" component={ClientLists} />
                  <Route path="/client-list/:id" component={ClientList} />
                  <Route path="/campaigns" component={Campaigns} />
                  <Route path="/campaign/:id" component={Campaign} />
                  <Route path="/spark-campaign" component={SparkCampaign} />
                  <Route path="/spark-reporting" component={SparkReporting} />
                  <Route path="/automations" component={Automations} />
                  <Route path="/sign-up" component={SignUp} />

                  <Route path="/spark-webinar" component={SparkWebinar} />
                  <Route path="/forms-settings" exact component={IntakeSettingsList} />
                  <Route path="/forms-edit/:id" component={IntakeSettingsEdit} />
                  <Route path="/forms-submissions" exact component={IntakeSubmissions} />
                  <Route path="/form-submission/:id" component={IntakeSubmission} />
                  <Route path="/time-based/edit/message/:id" component={TimeBasedMessageEdit} />
                  <Route path="/time-based/edit/template/:id" component={TimeBasedMessageEdit} />
                  <Route path="/time-based/view-message/:id" component={AutomationBaseMessageView} />
                  <Route path="/time-based/list" render={() => (
                    <AutomationBasedMessageList
                      history={history}
                      path='msg.rcr.time_based'
                      composeUrl='/time-based'
                      title='Set-Time Reminders'
                    />
                  )}/>
                  <Route path="/smart-reply/edit/message/:id" component={SmartReplyMessageEdit} />
                  <Route path="/smart-reply/edit/template/:id" component={SmartReplyMessageEdit} />
                  <Route path="/smart-reply/view-message/:id" component={AutomationBaseMessageView} />
                  <Route path="/smart-reply/list" render={() => (
                    <AutomationBasedMessageList
                      history={history}
                      path='msg.rcr.smart_reply'
                      composeUrl='/smart-reply'
                      title='Smart Replies Messages'
                    />
                  )}/>
                  <Route path="/loading" component={Loading} />
                </Switch>
              </DndProvider>
            </Suspense>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

const Loading = () => {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <CircularProgress />
    </div>
  );
};

const Public = () => (
  <Container maxWidth={false}>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/passwordReset/office" component={PasswordReset} />
        <Route path="/passwordReset/client" component={PasswordResetClient} />
        <Route path="/unsubscribe" component={Unsubscribe} />
        <Route path="/registration" component={Registration} />
        <Route path="/spark-registration" component={Registration} />
        <Route path="/account-deletion" component={AccountDeletion} />
        <Route path="/loading" component={Loading} />
        <Route path="/sso/zendesk" component={Login} />
      </Switch>
    </Suspense>
  </Container>
);

const Admin = () => (
  <div>
    <div className="sked-content" style={{ marginLeft: 'unset' }}>
      <AdminHeader />
      <div className="content-inner" style={{ overflowY: 'scroll' }}>
        <Suspense fallback={<div className="loader" />}>
          <Switch>
            <Route path="/settings/select-office" component={SelectOffice} />
            <Route path="/templates" component={Templates} />
            <Route path="/template/edit/:id" component={TemplateEdit} />
            <Route path="/automation-based-template/edit/:id" component={AutomationBasedTemplateEdit} />
            <Route path='/automations-templates' component={TemplateAutomations} />
            <Route path='/campaign-templates' render={(props) => <Campaigns {...props} template />} />
            <Route path='/campaign-template/:id' render={(props) => <Campaign {...props} template />} />
            <Route path="/reset-user" component={ResetUser} />
            <Route path="/check-unsynced" component={CheckUnsynced} />
            <Route path="/transfer-office" component={TransferOffice} />
            <Route path="/transfer-messages" component={TransferMessages} />
            <Route path="/transfer-forms" component={TransferForms} />
            <Route path="/affiliates" component={Affiliates} />
            <Route path="/banners" component={Banners} />
            <Route path="/loading" component={Loading} />
            <Route path="/agencies" component={Agencies} />
            <Route path="/users" component={Users} />
            <Route path="/profile/settings" component={ProfileSettings} />
          </Switch>
        </Suspense>
      </div>
    </div>
  </div>
);

const LockedPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.lockedDialog}>
      <Paper className={classes.lockedContainer}>
        <img src={Logo} width='200px' />
        <p style={{
          margin: '10px 0 0 0',
        }}>
          This device is locked. Please enter your pin to access the Admin.
        </p>
        <TextField
          label='Admin PIN'
          type='password'
          fullWidth
          style={{ width: 'unset' }}
          onChange={(e) => {
            const v = e.target.value;
            attemptDecryption(v).then((isDecrypted) => {
              if (isDecrypted)
                dispatch(patch({ locked: false }));
            });
          }}
        />
      </Paper>
    </div>
  );
};

const App = ({ history, location }: RouteComponentProps) => {
  const dispatch = useDispatch();
  const loading = location.pathname === '/loading';
  const isSSO = location.pathname.indexOf('/sso') > -1;
  const {
    locked,
    theme,
  } = useSelector((state) => ({
    locked: pathOr(false, ['login', 'locked'], state),
    theme: state.login.theme,
  }));

  React.useEffect(() => {
    const session = localStorage.getItem('sked-session');
    try {
      JSON.parse(session);
    } catch (e) {
      dispatch(patch({ locked: true }));
    }
  }, []);

  React.useEffect(() => {
    dispatch(checkUser(history, isSSO));
    /* Fixes issues with HubSpot tracking query params */
    const splitter = window.location.href.split(/\/\?/s);
    const afterDomain = splitter[1];
    const queryParams = afterDomain?.split('#/');
    if (queryParams && !isSSO) {
      window.location.href = `${splitter[0]}/#/${queryParams[1]}`;
    }
  }, []);

  return (
    <Suspense fallback={<div />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme === 'sked' ? skedTheme : sparkTheme}>
          <ErrorBoundary>
            <Alerts />
            <Snackbar />
            {locked && !loading &&
              <LockedPage />}
            {locked && loading &&
              <Loading />}
            {!locked &&
              <Switch>
                <Route path='/login' component={Public} />
                <Route path='/forgot-password' component={Public} />
                <Route path='/passwordReset/office' component={Public} />
                <Route path='/passwordReset/client' component={Public} />
                <Route path='/unsubscribe' component={Public} />
                <Route path='/registration' component={Public} />
                <Route path='/spark-registration' component={Public} />
                <Route path='/account-deletion' component={Public} />
                <Route path='/settings/select-office' component={Admin} />
                <Route path='/templates' component={Admin} />
                <Route path='/template/edit/:id' component={Admin} />
                <Route path='/automations-templates' component={Admin} />
                <Route path='/campaign-templates' component={Admin} />
                <Route path='/campaign-template/:id' component={Admin} />
                <Route path="/automation-based-template/edit/:id" component={Admin} />
                <Route path='/reset-user' component={Admin} />
                <Route path='/check-unsynced' component={Admin} />
                <Route path="/transfer-office" component={Admin} />
                <Route path="/transfer-forms" component={Admin} />
                <Route path="/transfer-messages" component={Admin} />
                <Route path="/affiliates" component={Admin} />
                <Route path="/banners" component={Admin} />
                <Route path="/agencies" component={Admin} />
                <Route path="/users" component={Admin} />
                <Route path="/profile/settings" component={Admin} />
                <Route path="/transfer-forms" component={Admin} />
                <Route path="/loading" component={Loading} />
                <Route path='/sso/zendesk' component={Public} />
                <PrivateRoute component={Main} />
              </Switch>
            }
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};

document.title = cond([
  [includes('localhost'), () => 'SKED Admin | Local'],
  [includes('plat'), () => 'SKED Admin | Plat'],
  [includes('int'), () => 'SKED Admin | Int'],
  [T, () => 'SKED Admin']
])(document.location.href);

export default withRouter(App);
