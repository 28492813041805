import * as React from 'react';
import {
  TableContainer, Table, TableRow,
  TableHead, TableBody, TableCell, Button,
  Typography, Tooltip, FormControlLabel, Checkbox,
} from '@mui/material';
import { Spacer } from '../../../components/PageHeader';
import AddIcon from '@mui/icons-material/Add';
import { append, remove, update } from 'ramda';
import {
  ComplexWidget, ComplexWidgetFlexibleTable, SimpleWidget, SectionChild,
  IndexAndConds
} from '../intake-types';
import {
  updateSimpleWidget, deleteSectionChild
} from '../intake.context';
import { SimpleWidgetComponent } from './SimpleWidgetContainer.component';
import { OrderButtons } from './SectionChildOrderButtons.component';
import { useDispatch } from 'react-redux';
import QuestionLabel from './QuestionLabel.component';

interface FlexibleTableEditProps {
  id: number;
  data: ComplexWidgetFlexibleTable,
  onSave: (
    defaultRowCount: number, columns: SimpleWidget[], required: boolean, forOffice: boolean
  ) => void,
  onCancel: () => void,
  required: boolean;
  forOffice: boolean;
}

interface FlexibleTableProps {
  id: number;
  data: ComplexWidget;
  index: number;
  maxIndex: number;
  required: boolean;
  forOffice: boolean;
  questionCond: IndexAndConds
}

export const FlexibleTableEditComponent = ({
  data, onSave, onCancel, required, forOffice, id
}: FlexibleTableEditProps) => {
  const [defaultRowCount] = React.useState<number>(data.defaultRowCount);
  const [columns, setColumns] = React.useState<SimpleWidget[]>(data.inputs);
  const [localRequired, setLocalRequired] = React.useState<boolean>(required);
  const [localForOffice, setLocalForOffice] = React.useState<boolean>(forOffice);

  const handleSave = () => {
    onSave(defaultRowCount, columns, localRequired, localForOffice);
  };

  const addColumn = () => {
    const newWidget: SimpleWidget = {
      id: columns.length + 1,
      type: 'SimpleWidget',
      label: '',
      questionType: { type: 'ShortText' },
      required: false,
      forOffice,
      width: 12,
    };
    setColumns(append(newWidget, columns));
  };

  const handleDelete = (idx: number) => {
    setColumns(remove(idx, 1, columns));
  };

  const handleUpdate = (idx: number) => (newWidg: SimpleWidget) => {
    setColumns(update(idx, newWidg, columns));
  };

  const handleCheck = (t: 'private' | 'required') => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (t === 'required') {
      setLocalRequired(e.target.checked);
    } else {
      setLocalForOffice(e.target.checked);
    }
  };

  return (
    <div>
      <Typography variant="body1" component="p" gutterBottom>
        Create a table without a set number of rows. The columns are used as the question, and the rows will be for the patients’ answers. Patients can add more rows to give more answers.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={localRequired || false}
            onChange={handleCheck('required')}
            color="primary"
          />}
        label="Required" />
      <br/>
      <FormControlLabel
        control={
          <Checkbox
            checked={localForOffice || false}
            onChange={handleCheck('private')}
            color="primary"
          /> }
        label="Private" />
      <br/>
      <br/>
      <div>Columns</div>
      {columns.map((col, idx) => {
        return (
          <SimpleWidgetComponent
            key={`flexable-${id}-${col.id}`}
            data={col}
            hideWidth
            hideRequired
            hideSave
            isEdit
            handleDelete={() => handleDelete(idx)}
            onUpdate={handleUpdate(idx)}
            noTemp
          />
        );
      })}
      <Button
        color="primary"
        variant="contained"
        onClick={addColumn}
        startIcon={<AddIcon />}
      >
        Add Column
      </Button>
      <br />
      <br />
      <Button color="primary" variant="outlined" onClick={handleSave}>Save</Button>
      &nbsp;&nbsp;
      <Button color="primary" variant="outlined" onClick={onCancel}>Cancel</Button>
    </div>
  );
};

export const FlexibleTableComponent = ({
  id, data, index, maxIndex, required, forOffice, questionCond
}: FlexibleTableProps) => {
  const tdata = data.typeData as ComplexWidgetFlexibleTable;
  const dispatch = useDispatch();
  const [edit, setEdit] = React.useState(false);
  const handleSave = (
    defaultRowCount: number, columns: SimpleWidget[], isRequired: boolean, forOffice: boolean
  ) => {
    setEdit(false);
    const updatedFlexibleTable: SectionChild = {
      id,
      width: 12,
      type: 'ComplexWidget',
      required: isRequired,
      forOffice,
      typeData: {
        type: 'FlexibleTable',
        defaultRowCount,
        inputs: columns,
      }
    };
    dispatch(updateSimpleWidget(updatedFlexibleTable));
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleDelete = () => {
    dispatch(deleteSectionChild(id, questionCond.index));
  };

  if (edit) {
    return (
      <FlexibleTableEditComponent
        id={id}
        data={tdata}
        onSave={handleSave}
        onCancel={handleCancel}
        required={required}
        forOffice={forOffice}
      />
    );
  }
  return (
    <div>
      {!forOffice &&
          <QuestionLabel cond={questionCond} complex />}
      <div style={{ display: 'flex' }}>
        <div>Flexible Table</div>
        <Spacer />
        <OrderButtons
          index={index}
          sectionMaxIndex={maxIndex}
          data={data}
          id={id}
          questionIndex={questionCond.index}
          handleDelete={handleDelete}
        />
      </div>
      <div>Required: {required ? 'yes' : 'no'}</div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tdata.inputs.map((i) => {
                return (
                  <TableCell align="center">
                    {i.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 2].map((j) => (
              <TableRow>
                {tdata.inputs.map((i) => {
                  if (i.questionType.type === 'Radio' ||
                    i.questionType.type === 'Dropdown' ||
                    i.questionType.type === 'Checkbox') {
                    const options = i.questionType.options.map((o) => {
                      return [
                        <br />,
                        o
                      ];
                    });
                    return (
                      <TableCell key={j} align="center">
                        <Tooltip arrow title={
                          <>
                            Options:
                            {options}
                          </>
                        }>
                          <div>
                            {i.questionType.type}
                          </div>
                        </Tooltip>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={j} align="center">
                      {i.questionType.type}
                    </TableCell>
                  );
                }
                )}
              </TableRow>
            )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        color="primary"
        onClick={() => setEdit(true)}
        variant="outlined" style={{
          marginTop: '10px',
        }}>
        Edit
      </Button>
    </div>
  );
};
