import React, { useState, useEffect } from 'react';
import { useSelector } from '../../../../reducers';
// import { useDispatch } from 'react-redux';
import {
  CircularProgress, TableHead, TableBody, Typography,
} from '@mui/material';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import Modal from '../../../../components/Modal/Modal.component';
import FilterText from '../../../../components/FilterText/FilterText.component';
import FilterSelect from '../../../../components/FilterSelect/FilterSelect.component';
import {
  TableContainer, HeaderCell, BodyCell, TableRow,
} from '../../../../components/CustomTable';
import { usePromise } from '../../../../services/promise.hook';
import {
  tzParseFormat,
} from '../../../../services/joda';
import { useModalStyles, useStyles } from './quick-replies.styles';
import {
  getReplies, QuickReply, emptyReply, deleteReply, saveReply,
  replacePlaceholders, sortOptions, generateDefaults,
} from './quick-replies.actions';
// import { History } from 'history';
import AddIcon from '@mui/icons-material/Add';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  TextField,
} from '../../../../components/components.component';
import { ThreadEditor } from '../../../../components/Editor/editor.component';
import {
  officePlaceholders, locationPlaceholders, apptPlaceholders,
} from '../../../../components/create-message.component';
import { Placeholder } from '../../../../components/Placeholders/Placeholders.component';
import { useTitle } from '../../../../services/useTitle';
import { isEmpty } from 'ramda';

interface RowContainerProps {
  reply: QuickReply;
  tz: string;
  open: (r: QuickReply) => void;
}

const RowContainer = ({ reply, tz, open }: RowContainerProps) => {
  return (
    <TableRow>
      <BodyCell fixed>
        <a onClick={() => open(reply)}>
          {reply.name}
        </a>
      </BodyCell>
      <BodyCell>
        {reply.body}
      </BodyCell>
      <BodyCell>
        {tzParseFormat(reply.updated, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      <BodyCell>
        {reply.used}
      </BodyCell>
    </TableRow>
  );
};

interface EditDialogProps {
  open: boolean;
  onClose: (reload: boolean) => void;
  reply: QuickReply;
}

export const clientPlaceholders: Placeholder[] = [
  { value: '{{client.firstName}} ', name: 'First Name' },
  { value: '{{client.lastName}} ', name: 'Last Name' },
];

export const proPlaceholders: Placeholder[] = [
  { value: '{{pro.firstName}} ', name: 'First Name' },
  { value: '{{pro.lastName}} ', name: 'Last Name' },
];

export const EditDialog = ({ open, onClose, reply }: EditDialogProps) => {
  const classes = useModalStyles();
  const textRef = React.useRef<HTMLInputElement>();
  const [localReply, setLocalReply] = useState(reply || emptyReply);
  const [deleteOpened, setDeleteOpened] = useState(false);

  useTitle('Quick Replies');

  const { office, features } = useSelector((state) => ({
    office: state.login.office,
    features: state.login.features,
  }));

  const placeholders = React.useMemo(() => {
    const filterByFeatures = (placeholders: Placeholder[]) => {
      return placeholders.filter(({ feature }) => {
        if (feature) {
          return features.includes(feature);
        }
        return true;
      });
    };

    return [
      { title: 'Next Appointment', placeholders: filterByFeatures(apptPlaceholders), },
      { title: 'Client', placeholders: filterByFeatures(clientPlaceholders), },
      features.includes('Locations') &&
        { title: 'Location', placeholders: filterByFeatures(locationPlaceholders), },
      { title: 'Office', placeholders: filterByFeatures(officePlaceholders), },
      { title: 'Professional', placeholders: filterByFeatures(proPlaceholders), },
    ];
  }, [features]);

  const saveState = usePromise(saveReply, null);
  const deleteState = usePromise(deleteReply, null);

  useEffect(() => {
    if (open) {
      setLocalReply(reply);
    }
  }, [reply, open]);

  useEffect(() => {
    if (textRef && open) {
      setTimeout(() => {
        textRef.current.focus();
      }, 100);
    }
  }, [textRef?.current, open]);

  const rendered = React.useMemo(() => {
    if (localReply.body.includes('{{') && localReply.body.includes('}}')) {
      return replacePlaceholders({
        body: localReply.body,
        office,
        html: true,
      }).replace(/\n/g, '<br/>');
    }
    return null;
  }, [localReply.body]);


  const loading = React.useMemo(() => {
    return (saveState.loading || deleteState.loading);
  }, [saveState.loading, deleteState.loading]);

  const onSave = () => {
    if (loading)
      return;
    const trimReply = {
      ...localReply,
      body: localReply.body.trim(),
      name: localReply.name.trim(),
    };
    saveState.invoke(trimReply).then(() => {
      onClose(true);
    });
  };

  const onDelete = () => {
    deleteState.invoke(localReply).then(() => {
      setDeleteOpened(false);
      onClose(true);
    });
  };

  const onChange = (prop: keyof QuickReply) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string) => {
      setLocalReply({
        ...localReply,
        [prop]: typeof e === 'object' ? e.target.value : e,
      });
    };

  const openDelete = () => {
    if (!loading)
      setDeleteOpened(true);
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      title='Edit Quick Reply'
      size='ms'
      className='sked-test-edit-quick-reply-modal'
      buttons={[
        loading && <CircularProgress />,
        localReply.id !== 0 &&
          <HeaderButton
            title='Delete'
            onClick={openDelete}
            disabled={deleteState.loading}
            color="danger"
            className={classes.button + ' sked-test-edit-quick-reply-delete-action-modal'}
          />,
        <HeaderButton
          title='Save'
          onClick={onSave}
          disabled={saveState.loading ||
            localReply.name.trim() === '' || localReply.body.trim() === ''}
          color="primary"
          className='sked-test-edit-quick-reply-save-action-modal'
        />
      ]}
    >
      <Modal
        open={deleteOpened}
        onClose={() => setDeleteOpened(false)}
        title="Delete Quick Reply?"
        size='xs'
        buttons={[<HeaderButton
          title='Delete'
          onClick={onDelete}
          color="danger"
          className='sked-test-edit-quick-reply-delete-action-delete-modal'
        />]}
      >
        <div className={classes.root}>
          <Typography component='p' color='error'>
            Delete the "{localReply.name}" quick reply?
          </Typography>
          <br />
          <Typography style={{ marginTop: 10 }} component='p'>
            This will be permanently deleted and cannot be undone.
          </Typography>
        </div>
      </Modal>
      <div className={classes.root}>
        <div className={classes.textContainer}>
          <label>Name</label>
          <TextField
            id='name'
            name='name'
            inputRef={textRef}
            placeholder={'Add title here...'}
            InputLabelProps={{ shrink: true }}
            onChange={onChange('name' as keyof QuickReply)}
            className={classes.text}
            value={localReply.name} />
        </div>
        <div className={classes.textContainer}>
          <label>Message</label>
          <ThreadEditor
            keyId='quick-reply-edit'
            label={null}
            placeholder={'A very common reply that includes {{placeholders}}...'}
            body={localReply.body}
            messagesPatch={onChange('body' as keyof QuickReply)}
            baseCharacterCount={0}
            minRows={5}
            placeholders={placeholders}
          />
        </div>
        {rendered &&
          <div className={classes.rendered} dangerouslySetInnerHTML={{ __html: rendered }}>
          </div>}
      </div>
    </Modal>
  );
};


type LoginState = {
  login: {
    office?: {
      timezone: string;
    };
  }
}

/*const now = () => {
  return format(LocalDateTime.now(), 'yyyy-MM-dd');
};*/

/* interface QuickRepliesProps {
  history: History;
}*/

const QuickReplies = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [name, setName] = useState(''); // TODO: use this for the search function
  const [perPage, setPerPage] = useState(50);
  const [editReply, setEditReply] = useState(null);
  const [sort, setSort] = React.useState('Last Edited Date (Newest First)');

  const {
    tz
  }: { tz: string } = useSelector((state: LoginState) => ({
    tz: state.login.office.timezone,
  }));

  const state = usePromise(getReplies, {
    data: [], perPage, totalCount: 1, totalPages: 1,
  });

  useEffect(() => {
    state.invoke({ name, perPage, page, sort });
  }, [name, sort]);

  const handleClickOpen = (reply: QuickReply) => {
    setEditReply(reply);
  };

  const onClose = (reload: boolean) => {
    setEditReply(null);
    if (reload) {
      state.invoke({ name, perPage, page });
    }
  };

  const handleSort = (value: string) => {
    setSort(value);
  };

  const handleChange = (value: string) => {
    setName(value);
  };

  const generate = async () => {
    state.setState({
      ...state,
      loading: true,
    });
    await generateDefaults();
    state.invoke({
      name, perPage, page,
    });
  };

  return (
    <>
      <Header
        title='Quick Replies'
        pageId='quick-replies'
        leftIcons={[
          <FilterSelect
            items={sortOptions.map(item => item.label)}
            selected={sort}
            setSelected={handleSort}
            Icon={SwapVertIcon}
            title='Sort'
            headerTitle='Sort By'
            noneText='Created'
            type='sort'
          />
        ]}
        rightIcons={[
          <FilterText
            value={name}
            headerTitle='Search'
            onChange={handleChange}
            helperText='Search all quick replies'
          />,
          <HeaderButton
            className='sked-test-quick-replies-add-button'
            title='Add Quick Reply'
            onClick={() => handleClickOpen(emptyReply)}
            Icon={AddIcon}
          />,
        ]}
        onlyIconsWidth={790}
        breakPoints={[
          {
            width: 490,
            mobileItems: [5],
          },
          {
            width: 418,
            mobileItems: [1, 5],
          },
        ]}
      />
      <EditDialog
        open={Boolean(editReply)}
        onClose={onClose}
        reply={editReply}
      />
      <div className={'page-root '} style={{
        overflow: 'unset',
        maxHeight: 'calc(100% - 61px)',
      }}>
        {isEmpty(state.data.data) && !state.loading &&
          <div className={classes.emptyBox}>
            <div className={classes.insideBox}>
              <Typography component='h1' className={classes.header}>
                No Quick Replies added yet
              </Typography>
              <Typography component='p' style={{ textAlign: 'center' }}>
              Create your first quick reply by clicking the
                <HeaderButton
                  className={`${classes.button} sked-test-quick-replies-add-button`}
                  title='Add Quick Reply'
                  onClick={() => handleClickOpen(emptyReply)}
                  Icon={AddIcon}
                  color='lightgray'
                />&nbsp;
                button in the top right or
                <a
                  href="javascript:void()"
                  className={classes.otherButton}
                  onClick={generate}>
                  use our templates
                </a>
                 to get started.
              </Typography>
            </div>
          </div>}
        <TableContainer
          pagination
          paginationData={{
            page,
            perPage,
            setPerPage,
            totalPages: state.data.totalPages,
            totalCount: state.data.totalCount,
            addPage: (n: number) => setPage(page + n),
            currentLength: state.data.data.length,
          }}
          fullPage
          headerFixed
        >
          <TableHead>
            <TableRow>
              <HeaderCell fixed minWidth={180}>
                Name
              </HeaderCell>
              <HeaderCell>
                Message
              </HeaderCell>
              <HeaderCell>
                Last Edit
              </HeaderCell>
              <HeaderCell>
                Times Used
              </HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.data.data.map((reply) => {
              return (
                <RowContainer
                  key={reply.id}
                  reply={reply}
                  tz={tz}
                  open={setEditReply}
                />
              );
            })}
          </TableBody>
        </TableContainer>
      </div>
    </>
  );
};

export default QuickReplies;
