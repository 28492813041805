import React, { useState, useEffect, useMemo } from 'react';
import { usePromise } from '../../services/promise.hook';
import { useTitle } from '../../services/useTitle';
import {
  getMessage, getAutomation, automationParser, MessageLogicType,
  GetAutomationType, makeDefaultMessageLogic, Message, SmartReplyType,
  TimeBasedReminderType, getProApptTypes, Tense, ClientStatus,
  AutomationMessageTypes, MessageType, saveMessage, automationConverter,
  saveAutomation, SaveMessageType, pathFromMessageType,
  saveTemplate, SaveAutomationType,
  saveAutomationTemplate, niceName,
} from './automation-message.actions';
import { Message as ApiMessage } from '../../routes/Templates/templates.reducer';
import { Automation } from '../../routes/Automations/automation-types';
import { clientStatusTags } from '../../routes/Automations/automations.page';
import {
  includes, isEmpty, has, remove, isNil, cond, equals, T, always, without,
  identity,
} from 'ramda';
import { Placeholder } from '../Placeholders/Placeholders.component';
import OneLineEditor from '../Editor/components/OneLineEditor.component';
import { withStyles, makeStyles } from '@mui/styles';
import {
  Paper, Tooltip, Button, Chip, MenuItem, ListItemText, SelectChangeEvent,
  Checkbox, CircularProgress, FormControlLabel,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
  capitalize, createEmailData, bodyToHtml,
} from '../../services/utilities.js';
import {
  MessageComposer, locationPlaceholders, officePlaceholders, clientPlaceholders,
  apptPlaceholders, removePlaceholders, outputErrorOptions, createSMSData,
} from '../create-message.component';
import {
  Email, Push, Sms
} from '../../routes/Templates/routes/TemplatesEdit/templates-edit.reducer';
import TypeSelector from '../TypeSelector/type-selector.component';
import { AppointmentType, Professional } from '../../routes/Appointments/appointments.types';
import {
  TextField, Select,
} from '../components.component';
import { statuses } from '../Appointments/AppointmentItem.component';
import Header from '../../components/PageHeader/PageHeader.component';
import SaveAsButton from '../../components/SaveButton/SaveAsButton.component';
import SaveButton from '../../components/SaveButton/SaveButton.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import { useSelector } from '../../reducers';
import {
  PopupTemplate, popupWithCancel,
} from '../../services/Popup.js';

const mkPlaceholders = (
  features: string[], messageType: string, filter: 'Clients' | 'Leads'
) => {
  const clientHolders = clientPlaceholders
    .filter((holder: Placeholder) => removePlaceholders(features, holder));
  const officeHolders = officePlaceholders
    .filter((holder) => removePlaceholders(features, holder));

  const newClientHolders = clientHolders.filter(holder => {
    if (filter === 'Leads') {
      return holder.name !== 'Registration Link';
    }
    return true;
  });

  const {
    officeTitle,
    lPlace,
  } = (includes('Locations', features) &&
  !includes(messageType, ['OneTime', 'Birthday'])) ? {
      officeTitle: 'Business',
      lPlace: true,
    } : { officeTitle: 'Office', lPlace: false };

  return [
    { title: 'Client', placeholders: newClientHolders },
    { title: officeTitle, placeholders: officeHolders },
    lPlace && { title: 'Locations', placeholders: locationPlaceholders },
    includes(messageType, ['TimeBasedReminder']) &&
    { title: 'Appointment', placeholders: apptPlaceholders },
  ];
};

const useStyles = makeStyles(() => ({
  middleAligned: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionMargin: {
    marginTop: '10px'
  },
  basicFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
}));

const Section = withStyles({
  root: {
    padding: '16px 20px 16px',
    marginBottom: 12,
  }
})(Paper);

interface CreateAutomationMessageProps {
  messageId?: number;
  filter: 'Clients' | 'Leads';
  automationMessageType: AutomationMessageTypes;
  messageType: MessageType;
  includeHeader?: boolean;
  goBack?: () => void;
  /* the next 2 must be set to 0 when not used */
  copying: number; // the id of the message you're copying
  fromTemplate: number; // the id of the sked template
}

interface MassPatchType {
  email?: Email;
  isEmail?: boolean;
  sms?: Sms;
  isSms?: boolean;
  push?: Push;
  isPush?: boolean;
}

interface SpecialMessageLogicType {
  messageLogic: MessageLogicType;
  setMessageLogic: (messageLogic: MessageLogicType) => void;
  messageType: MessageType;
}

interface CompType {
  setMessageLogic: (messageLogic: MessageLogicType) => void;
  messageType: MessageType;
}

interface SmartReplyCompType extends CompType {
  sr: SmartReplyType;
}

interface TimeBasedCompType extends CompType {
  tb: TimeBasedReminderType;
}

let srTimeout: NodeJS.Timeout;
const SmartReply = ({
  sr, setMessageLogic, messageType,
}: SmartReplyCompType) => {
  const classes = useStyles();
  const [keyword, setKeyword] = useState('');
  const [keywords, setKeywords] = useState(sr.keywords);

  useEffect(() => {
    setKeywords(sr.keywords);
  }, [sr.keywords]);

  const updateKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };
  const addKeyword = () => {
    setKeywords(keywords.concat([keyword]));
    setKeyword('');
  };
  const deleteKeyword = (idx: number) => () => {
    setKeywords(remove(idx, 1, keywords));
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addKeyword();
    }
  };

  useEffect(() => {
    if (srTimeout) {
      clearTimeout(srTimeout);
    }
    srTimeout = setTimeout(() => {
      setMessageLogic({
        ...sr,
        keywords,
      });
    }, 500);
  }, [keywords]);

  return (
    <Section className={classes.sectionMargin}>
      <h4>
        What keywords should trigger this {messageType}?
      </h4>
      <div className={classes.basicFlex}>
        <TextField
          label='Keyword'
          value={keyword}
          onChange={updateKeyword}
          onKeyPress={handleKeyPress}
          style={{ marginRight: '10px' }}
          InputLabelProps={{ shrink: true }}
        />
        <Button variant='contained' onClick={addKeyword}>
          Add
        </Button>
      </div>
      <br />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}>
        {keywords.map((k: string, idx: number) => (
          <Chip
            key={k + idx}
            style={{ fontSize: '14px', margin: '5px' }}
            label={k}
            onDelete={deleteKeyword(idx)}
          />
        ))}
      </div>
    </Section>
  );
};

let tbTimeout: NodeJS.Timeout;
const TimeBasedReminder = ({
  tb, setMessageLogic, messageType,
}: TimeBasedCompType) => {
  const classes = useStyles();
  const [time, setTime] = useState(tb.time);
  const [status, setStatus] = useState(tb.status);
  const [tense, setTense] = useState(tb.tense);
  const [amount, setAmount] = useState(tb.amount);
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>(null);
  const [clientStatus, setClientStatus] = useState(tb.clientStatus);
  /* The reason these are combined is due to the old ApptTypeSelector being dumb */
  const proApptTypeState = usePromise<void, (AppointmentType[] | Professional[])[]>(
    getProApptTypes, []
  );

  useEffect(() => {
    proApptTypeState.invoke().then((typesAndPros) => {
      const realTypes: AppointmentType[] = typesAndPros[0] as AppointmentType[];
      const actualTypes = tb.appointmentTypes.map((id) => {
        return realTypes.find((t) => t.id === id);
      }).filter(identity);
      setAppointmentTypes(actualTypes);
    });
  }, []);

  useEffect(() => {
    if (tbTimeout) {
      clearTimeout(tbTimeout);
    }
    tbTimeout = setTimeout(() => {
      setMessageLogic({
        ...tb,
        time,
        status,
        tense,
        amount,
        appointmentTypes: messageType === 'sked-template' ?
          undefined : appointmentTypes?.map(({ id }) => id),
        clientStatus
      });
    }, 500);
  }, [time, status, tense, amount, appointmentTypes, clientStatus]);

  const setApptTypes = (ts: AppointmentType[]) => {
    setAppointmentTypes(ts);
  };

  const changeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };

  const changeStatus = (e: SelectChangeEvent<string>) => {
    setStatus(e.target.value);
  };

  const changeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: number;
    try {
      value = Number(e.target.value);
    } catch (e) {
      console.log(e);
      return;
    }
    if (value < 0)
      return;
    setAmount(value);
  };

  const changeTense = (e: SelectChangeEvent<string>) => {
    setTense(e.target.value as Tense);
  };

  const changeClientStatus = (e: SelectChangeEvent<string>) => {
    setClientStatus(e.target.value as ClientStatus);
  };

  const emptyTime = useMemo(() => {
    return isEmpty(time) && messageType !== 'sked-template';
  }, [time]);

  console.log(appointmentTypes);

  return (
    <Section className={classes.sectionMargin}>
      {messageType !== 'sked-template' &&
        <>
          <h4>
          What type of appointment does this {messageType} apply to?
          </h4>
          <TypeSelector
            selected={isNil(appointmentTypes) ? null : appointmentTypes.map(({ id }) => id)}
            professionals={proApptTypeState.data[1] as Professional[] || []}
            types={proApptTypeState.data[0] as AppointmentType[] || []}
            onChange={(s: number[]) => setApptTypes(s.map((i) => {
              const appt = (proApptTypeState.data[0] as AppointmentType[])
                .find(({ id }) => i === id);
              return appt;
            }))}
          />
        </>}
      <h4>
        What should the status of the appointment be?
      </h4>
      <Select
        value={status}
        onChange={changeStatus}
        required
      >
        {statuses.map((status) => {
          return (
            <MenuItem key={status} value={status}>
              <ListItemText>{status}</ListItemText>
            </MenuItem>
          );
        })}
      </Select>
      <h4>
        What time should this {messageType} be sent?
      </h4>
      <TextField
        type='time'
        value={time}
        onChange={changeTime}
        error={emptyTime}
        helperText={emptyTime && 'Required'}
        style={{ width: window.navigator.userAgent.includes('Chrome') ? 125 : undefined }}
      />
      <h4>
        When should this message be sent?
      </h4>
      <div>
        <TextField
          type='number'
          value={amount}
          onChange={changeAmount}
          required
          InputProps={{
            style: {
              paddingLeft: '5px',
              paddingRight: '5px',
              width: '100%',
              height: '32px',
            },
          }}
          style={{
            width: '65px',
            margin: '0px 7px',
          }}
        />
        {amount === 1 ? 'day' : 'days'}
        <Select
          value={tense}
          onChange={changeTense}
          required
          style={{
            margin: '0px 7px',
          }}
        >
          {['after', 'before'].map((t) => {
            return (
              <MenuItem key={t} value={t}>
                <ListItemText>{t}</ListItemText>
              </MenuItem>
            );
          })}
        </Select>
        appointment
      </div>
      <h4>
        What should the client's status be?
      </h4>
      <Select
        value={clientStatus}
        onChange={changeClientStatus}
        required
      >
        {clientStatusTags.map((t) => {
          return (
            <MenuItem key={t} value={t}>
              <ListItemText>{t}</ListItemText>
            </MenuItem>
          );
        })}
      </Select>
    </Section>
  );
};

const SpecialMessageLogic = ({
  messageLogic, setMessageLogic, messageType
}: SpecialMessageLogicType) => {
  if (messageLogic.type === 'SmartReply') {
    return (
      <SmartReply
        sr={messageLogic}
        setMessageLogic={setMessageLogic}
        messageType={messageType}
      />
    );
  }
  if (messageLogic.type === 'TimeBasedReminder') {
    return (
      <TimeBasedReminder
        tb={messageLogic}
        setMessageLogic={setMessageLogic}
        messageType={messageType}
      />
    );
  }
};

const defaultEmail: Email = {
  subject: '',
  html: '',
  body: '',
  shouldFrame: true,
  attachments: [],
};

const CreateAutomationMessage = ({
  messageId, filter, messageType, automationMessageType,
  includeHeader, goBack, copying, fromTemplate,
}: CreateAutomationMessageProps) => {
  useTitle((messageId === 0 ? 'Create ' : 'Edit ') + niceName(automationMessageType));
  const classes = useStyles();
  const [messageLogic, setMessageLogic] = useState<MessageLogicType>(null);
  const [messageName, setMessageName] = useState<string>('');
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [SMSError, setSMSError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [pushError, setPushError] = useState(false);
  const [email, setEmail] = useState<Email>(defaultEmail);
  const [sms, setSms] = useState<Sms>({
    body: '',
    attachments: [],
  });
  const [push, setPush] = useState<Push>({
    subject: '',
    body: '',
  });
  const [forFeature, setForFeature] = useState('HtmlEmail');
  const messageState = usePromise<number, Message>(
    getMessage(fromTemplate ? 'sked-template' : messageType), null
  );
  const autoState = usePromise<GetAutomationType, Automation>(
    getAutomation, null
  );
  const saveMessageState = usePromise<SaveMessageType, ApiMessage>(
    saveMessage, null
  );
  const saveAutoState = usePromise<SaveAutomationType, Automation>(
    saveAutomation, null
  );
  const saveTemplateState = usePromise<SaveMessageType, ApiMessage>(
    saveTemplate, null
  );
  const saveAutoTemplateState = usePromise<
    SaveAutomationType, Automation
  >(saveAutomationTemplate, null);


  const {
    features, officeId,
  } = useSelector((state) => ({
    features: isEmpty(state.login.features) || messageType === 'sked-template' ? [
      'Locations', 'HtmlEmail', 'NewMessaging', 'SkedApp'
    ] : state.login.features,
    officeId: state.login.office.id,
  }));

  const hasHtml = useMemo(() => {
    return features.includes('HtmlEmail');
  }, [features]);

  const editorFeatures = useMemo(() => {
    return forFeature === 'HtmlEmail' ? features : without(['HtmlEmail'], features);
  }, [features, forFeature]);

  const lacksOptOutLink = useMemo(() => {
    return email && !email.shouldFrame &&
      email.html &&
      !(includes('{{client.optoutLink}}', email.html) ||
        includes('{{client.optoutUrl}}', email.html)) ?
      <p style={{ color: 'red' }}>
      Your email message doesn't have an opt out {outputErrorOptions}!
        <b>This message cannot not be sent without one!</b>
      </p>
      : null;
  }, [email]);

  const isAllEmpty = useMemo(() => {
    let emailEmpty = false;
    if (!email) {
      return false;
    }
    if (hasHtml && forFeature === 'HtmlEmail') {
      emailEmpty = isEmpty(email.html) || isNil(email.html);
    } else {
      emailEmpty = isEmpty(email.body);
    }
    return emailEmpty && isEmpty(push.body) && isEmpty(sms.body);
  }, [sms, email, push, hasHtml, forFeature]);

  // TODO: use `logicErrorMessage` somewhere and somehow
  const [logicError] = useMemo(() => {
    switch (messageLogic?.type) {
      case 'SmartReply': {
        const noKeywords = isEmpty(messageLogic.keywords);
        return [noKeywords, noKeywords && 'No keywords selected!'];
      }
      case 'TimeBasedReminder': {
        const error = isEmpty(messageLogic.time);
        return [error, error ? 'Missing send time!' : ''];
      }
      default:
        return [false, ''];
    }
  }, [messageLogic]);

  /*
    Automation-based messages have to throw an error if the
    automation can't be made, therefore, we must check the
    `logicError` even when we don't have the message enabled.
    Otherwise, it would fail to make the automation and
    then we'd all die.

    TODO: make it only care if the auto's trigger can't be made
    since filters can always be added in later.
  */
  const [saveDisabled, saveAsDisabled] = useMemo(() => {
    const loading = saveAutoState.loading || saveMessageState.loading;
    return ([
      isEmpty(messageName) ||
      SMSError || emailError || pushError ||
      Boolean(lacksOptOutLink) || isAllEmpty ||
      logicError || loading,
      isEmpty(messageName) || loading
    ]);
  }, [
    messageName, SMSError, emailError, pushError, lacksOptOutLink,
    isAllEmpty, logicError, saveAutoState.loading,
    saveMessageState.loading,
  ]);

  useEffect(() => {
    if (messageId === 0 && copying === 0 && fromTemplate === 0) {
      setMessageLogic(makeDefaultMessageLogic(
        messageType, automationMessageType
      ));
    } else {
      const realMsgId = messageId || copying || fromTemplate;
      messageState.invoke(realMsgId).then((msg) => {
        setMessageName(msg.name);
        if (msg.forFeature && messageType === 'sked-template') {
          setForFeature(msg.forFeature);
        }
        if (msg.email) {
          if (hasHtml && msg.email.html === '' && messageType !== 'sked-template') {
            setEmail({
              ...msg.email,
              html: bodyToHtml(msg.email.body),
              body: '',
              shouldFrame: true,
            });
          } else {
            setEmail(msg.email);
          }
        }
        if (msg.sms) {
          setSms(msg.sms);
        }
        if (msg.push) {
          setPush(msg.push);
        }
        setIsEnabled(Boolean(msg.isEnabled)); //if sked template, this is null
      });
      const msgType = fromTemplate ? 'sked-template' : messageType;
      const path = pathFromMessageType(msgType, automationMessageType);
      autoState.invoke({
        msgId: realMsgId || copying, type: msgType, path,
      }).then((auto) => {
        if (auto) {
          const a = automationParser(auto);
          if (fromTemplate) {
            setMessageLogic({
              ...a,
              autoId: undefined,
            });
          } else {
            setMessageLogic(a);
          }
        } else {
          setMessageLogic(makeDefaultMessageLogic(
            messageType, automationMessageType
          ));
        }
      });
    }
  }, []);

  const isEmail = useMemo(() => {
    return email && (
      (email.body && !isEmpty(email.body))
      ||
      (email.subject && !isEmpty(email.subject))
      ||
      (email.html && !isEmpty(email.html))
    );
  }, [email]);

  const isSMS = useMemo(() => {
    return sms.body && !isEmpty(sms.body);
  }, [sms]);

  const isPush = useMemo(() => {
    return includes('SkedApp', features) && (
      (push.body && !isEmpty(push.body))
      ||
      (push.subject && !isEmpty(push.subject))
    );
  }, [features, push]);

  const placeholders = useMemo(() => {
    return mkPlaceholders(features, messageLogic?.type, filter);
  }, [features, messageLogic]);

  const actions = {
    messagesPatch: (prop: string, data: Email | Sms | Push) => {
      if (prop === 'email') {
        console.log(data);
        return setEmail(data as Email);
      }
      if (prop === 'sms' && !has('html', data) && !has('subject', data)) {
        return setSms(data);
      }
      if (prop === 'push' && !has('html', data) && has('subject', data)) {
        return setPush(data);
      }
      return;
    },
    messageMassPatch: (mass: MassPatchType) => {
      if (has('email', mass)) {
        setEmail(mass.email);
      }
      if (has('sms', mass)) {
        setSms(mass.sms);
      }
      if (has('push', mass)) {
        setPush(mass.push);
      }
    }
  };

  const localSaveMessage = (
    saveAsTemplate: boolean
  ) => () => {
    const smsData = createSMSData(sms);
    const emailData = createEmailData(email);
    const message = {
      id: copying || messageState.data?.isTemplate !== saveAsTemplate ?
        undefined : messageState.data?.id,
      name: messageName,
      isEnabled: saveAsTemplate ? false : isEnabled,
      email: emailData,
      sms: smsData,
      push: !push.subject && !push.body ? undefined : push,
      messageType: { Automation: [] as [] },
      isTemplate: saveAsTemplate,
      createdAt: undefined as string,
      isDeleted: undefined as boolean,
      forFeature: undefined as string,
      // TODO: probably should actually use the campaignId
      campaignId: undefined as number,
      officeId,
    };
    const path = pathFromMessageType(messageType, automationMessageType);
    return saveMessageState.invoke({ message, path }).then((msg) => {
      const automation = automationConverter(messageLogic, msg);
      saveAutoState.invoke({ automation }).then(() => {
        if (goBack)
          goBack();
      }).catch(() => {
        if (goBack && !isEnabled)
          goBack();
      });
    });
  };

  const localSaveTemplate = () => () => {
    const smsData = createSMSData(sms);
    const emailData = createEmailData(email);
    const message = {
      id: copying ? undefined : messageState.data?.id,
      name: messageName,
      isEnabled: false,
      email: emailData,
      sms: smsData,
      push: !push.subject && !push.body ? undefined : push,
      messageType: { Automation: [] as [] },
      isTemplate: true,
      createdAt: undefined as string,
      isDeleted: undefined as boolean,
      forFeature,
      campaignId: undefined as number,
      officeId,
    };
    const path = pathFromMessageType(messageType, automationMessageType);
    return saveTemplateState.invoke({ message, path }).then((temp) => {
      const automation = automationConverter(messageLogic, temp);
      const tempPath = `${path}.msg_${temp.id}`;
      saveAutoTemplateState.invoke({ automation, path: tempPath }).then(() => {
        if (goBack)
          goBack();
      }).catch(() => {
        if (goBack && !isEnabled)
          goBack();
      });
    });
  };

  const title = useMemo(() => {
    return cond([
      [equals('SmartReply'), always('Smart Reply')],
      [equals('TimeBasedReminder'), always('Set-Time Reminder')],
      [T, always('Automation-Based')]
    ])(automationMessageType);
  }, [automationMessageType]);

  const messagePrefix = useMemo(() => {
    if (messageType === 'message') {
      return messageId === 0 ? 'New ' : 'Edit ';
    }
    return '';
  }, [messageType]);

  const sentAsText = useMemo(() => {
    return ` This message will be sent as:
${isEmail ? '- Email\n' : ''}${isSMS ? '- SMS\n' : ''}${isPush ? '- Push' : ''}`;
  }, [isEmail, isSMS, isPush]);

  const checkForFeature = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newone = e.target.checked ? 'HtmlEmail' : 'NewMessaging';
    setForFeature(newone);
    setEmail({
      ...email,
      html: newone === 'NewMessaging' ? '' : email.html,
      body: newone === 'HtmlEmail' ? '' : email.body,
    });
  };

  return (
    <>
      {includeHeader &&
       <Header
         title={`${messagePrefix}${title} ${messageType === 'message' ? '' : capitalize(messageType)}`}
         rightIcons={[
           (saveMessageState.loading || saveAutoState.loading ||
            messageState.loading || autoState.loading) &&
             <CircularProgress size={20} style={{
               marginRight: '5px',
             }} />,
           messageType === 'message' &&
             <HeaderButton borderSolid>
               <label>
                 <Checkbox
                   name="enabled"
                   style={{
                     paddingLeft: 0, paddingTop: 4, paddingBottom: 4,
                   }}
                   checked={isEnabled}
                   onChange={() => setIsEnabled(!isEnabled)} />
                Enabled
               </label>
             </HeaderButton>,
           messageType === 'template' &&
            <Tooltip arrow title='Update existing template'>
              <div>
                <HeaderButton
                  title='Update'
                  borderSolid
                  onClick={localSaveMessage(true)}
                />
              </div>
            </Tooltip>,
           messageType === 'sked-template' ?
             <SaveButton
               onClick={localSaveTemplate()}
               disabled={saveDisabled}
               title='Save'
             />
             :
             <SaveAsButton
               onClick={isEnabled ?
                 () => popupWithCancel(
                   'Are you sure you want to save?',
                   'This message is enabled with means that it could be sent!' + sentAsText,
                   localSaveMessage(false))
                 :
                 localSaveMessage(false)}
               disabled={saveDisabled}
               title='Save'
               disableOptions={saveAsDisabled}
               saveAsOptions={[{
                 onClick: localSaveMessage(true),
                 label: messageState?.data?.isTemplate ?
                   'Save as new template'
                   :
                   'Save as template',
               }]}
             />
         ]}
       />}
      <div className='page-root'>
        <PopupTemplate />
        <div
          style={{ margin: '20px' }}
        >
          <Section className={classes.sectionMargin}>
            <h4 className={classes.middleAligned}>
          What should this {messageType}'s title be?
          &nbsp;
              <Tooltip
                title={`Used so you can easily reference your ${messageType}!`}
                placement="top"
                arrow>
                <InfoIcon style={{
                  fontSize: '14px',
                }} />
              </Tooltip>
            </h4>
            <OneLineEditor
              title={`${capitalize(messageType)} Name`}
              name={messageName}
              messagesPatch={(d: string) => setMessageName(d)}
              required
            />
            { messageType === 'sked-template' &&
              <div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={forFeature === 'HtmlEmail'}
                        onChange={checkForFeature}
                        name="acknowledge-checkbox"
                        color="primary"
                      />
                    }
                    label="Enable HTML Email?"
                  />
                  <Tooltip
                    title="If HTML, the template will only show up for offices that have the HTML Email feature enabled."
                    placement="top"
                    arrow>
                    <InfoIcon style={{ fontSize: '14px' }} />
                  </Tooltip>
                </div>
                <i>IMPORTANT: You must make TWO separate templates for HTML and Plain Text Email!</i>
              </div>}
          </Section>

          {messageLogic &&
            <SpecialMessageLogic
              messageLogic={messageLogic}
              setMessageLogic={setMessageLogic}
              messageType={messageType}
            />}

          <Section className={classes.sectionMargin}>
            <h4 className={classes.middleAligned}>
          Compose {capitalize(messageType)}
            </h4>
            <div style={{
              color: '#008BCF',
              fontSize: '14px'
            }}>
          Select SMS, Email, and Push to create a different message for each method.
            </div>
            <br />
            <MessageComposer
              messageType='Automation'
              features={editorFeatures}
              forFeature={forFeature}
              isSMS={isSMS}
              isEmail={isEmail}
              isPush={isPush}
              subject=''
              body=''
              email={email}
              sms={sms}
              push={push}
              actions={actions}
              placeholders={placeholders}
              setSMSError={setSMSError}
              setEmailError={setEmailError}
              setPushError={setPushError}
            />
          </Section>
        </div>
      </div>
    </>
  );
};

export default CreateAutomationMessage;
