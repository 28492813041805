import React, {
  useEffect, useState, useRef, useMemo
} from 'react';
import {
  TableContainer as MuiTableContainer, Table, TableContainerProps,
} from '@mui/material';
import { isNil } from 'ramda';
import { useSelector } from '../../reducers';
import { useStyles } from './tableContainer.styles';
import Pagination, { PaginationData } from '../Pagination/Pagination.component';

interface Props extends TableContainerProps {
  noHover?: boolean;
  fullPage?: boolean;
  maxHeight?: number | string;
  headerFixed?: boolean;
  checkHasScroll?: boolean;
  pagination?: boolean;
  paginationData?: PaginationData;
  scrollPaddingBottom?: number;
}

export const TableContainer = ({
  children, noHover, maxHeight, className, fullPage, checkHasScroll,
  pagination, paginationData = {} as PaginationData, scrollPaddingBottom = 0,
  ...props
}: Props) => {
  const classes = useStyles();
  const [tableWidth, setTableWidth] = useState(100);
  const [hasScroll, setHasScroll] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const desktopOpen = useSelector(state => state.login.desktopOpen);

  const handleWidth = () => {
    const size = desktopOpen ? window.innerWidth - 240 : window.innerWidth;
    setTableWidth(window.innerWidth < 600 ? window.innerWidth : size);
  };

  const handleScroll = () => {
    setHasScroll(boxRef.current.scrollWidth > boxRef.current.clientWidth);
  };

  useEffect(() => {
    handleWidth();
  }, [desktopOpen]);

  useEffect(() => {
    handleScroll();
  }, [boxRef.current?.scrollWidth, boxRef.current?.clientWidth, checkHasScroll]);

  useEffect(() => {
    function handleResize() {
      handleWidth();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [desktopOpen]);

  /* adjust the height of the table to stop at the pagination bar */
  const actualMaxHeight = useMemo(() => {
    if (pagination) {
      if (isNil(maxHeight)) {
        return 'calc(100% - 21px)';
      }
      if (typeof maxHeight === 'string') {
        return `calc(${maxHeight} - 21px)`;
      }
      return maxHeight - 40;
    }
    return maxHeight;
  }, [maxHeight, pagination]);

  return (
    <MuiTableContainer
      className={`${classes.root} ${className} ${noHover ? '' : classes.hover}`}
      {...props}
    >
      <div
        className={classes.scrollableContainer}
        style={{
          width: fullPage ? tableWidth : '100%',
          maxHeight: actualMaxHeight,
          paddingBottom: scrollPaddingBottom || 'unset',
        }}
        ref={boxRef}
      >
        <Table
          size="small"
          className={
            `${classes.table} ${hasScroll ? classes.border : ''} ${maxHeight ? classes.shadow : ''}`
          }>
          {children}
        </Table>
      </div>
      {pagination && <Pagination {...paginationData} />}
    </MuiTableContainer>
  );
};

export default TableContainer;
