import React from 'react';
import {
  Typography, IconButton,
} from '@mui/material';
import { useStyles } from './warningRectangle.styles';
import WarningIcon from '../../icons/Warning.icon';
import { capitalize } from '../../services/utilities.js';
import CloseIcon from '@mui/icons-material/Close';

type WarningType = 'warning' | 'error' | 'info';

interface WarningObj {
  titleTag: string;
  color: string;
  typeClass: WarningType;
  DefIcon: ({ color, className, style }: { color?: string, className?: string, style?: object }) => JSX.Element;
}

interface WarningRectangleProps {
  title: string;
  tag?: string;
  body?: string;
  open: boolean;
  className?: string;
  bodyClassName?: string;
  style?: object;
  type: WarningType;
  Icon?: ({ color, className, style }: { color?: string, className?: string, style?: object }) => JSX.Element;
  WarningButton?: () => JSX.Element;
  onClose?: () => void;
}

const WarningRectangle = ({
  title, tag, body, open, className = '', Icon, type, WarningButton, style = {},
  bodyClassName = '', onClose
}: WarningRectangleProps) => {
  const classes = useStyles();

  const {
    titleTag, color, typeClass, DefIcon,
  }: WarningObj = React.useMemo(() => {
    const localTag = tag ? tag : capitalize(type);
    if (type === 'warning') {
      return {
        titleTag: localTag,
        color: '#fcc713',
        typeClass: 'warning',
        DefIcon: (props) => <WarningIcon {...props}/>,
      };
    }
    if (type === 'error') {
      return {
        titleTag: localTag,
        color: '#bf2828',
        typeClass: 'error',
        DefIcon: (props) => <WarningIcon {...props}/>,
      };
    }
    // TODO: this needs proper stuff
    if (type === 'info') {
      return {
        titleTag: localTag,
        color: 'blue',
        typeClass: 'info',
        DefIcon: (props) => <WarningIcon {...props}/>,
      };
    }
  }, [type]);

  if (!open)
    return (<div></div>);

  return (
    <div className={
      `${classes.container} ${classes[typeClass]} ${className}`
    } style={style}>
      <Typography className={classes.title}>
        <div style={{ display: 'flex' }}>
          {Icon ? <Icon /> : <DefIcon color={color} />}
          <b className={classes.titleBody}>{titleTag}: </b>
          {title}
        </div>
        {onClose &&
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={`${classes.closeButton} sked-test-warning-button-close`}>
            <CloseIcon />
          </IconButton>}
      </Typography>
      <div className={`${classes.body} ${bodyClassName}`}>
        {body}
      </div>
      {WarningButton &&
        <div className={classes.buttonContainer}>
          <WarningButton />
        </div>}
    </div>
  );
};

export default WarningRectangle;
