import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { Grid, IconButton, Checkbox, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import DraftsIcon from '@mui/icons-material/Drafts';
import ArchiveIcon from '@mui/icons-material/Archive';
import FilterListIcon from '@mui/icons-material/FilterList';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { SPARK_FEATURE } from '../../../../routes/Login/login.reducer';
import { PopupTemplate } from '../../../../services/Popup';
import { outputLocation } from '../../../../services/utilities';
import FindClient from '../../components/find-client.component.jsx';
import PerPage from '../../../../components/PerPage/PerPage.component';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import HeaderSearch from '../../../../components/HeaderSearch/HeaderSearch.component';
import HeaderTabs from '../../../../components/HeaderTabs/HeaderTabs.component';
import FilterSelect from '../../../../components/FilterSelect/FilterSelect.component';
import PartialCheckedIcon from '../../../../icons/PartialChecked.icon';
import CheckedIcon from '../../../../icons/Checked.icon';
import { History } from 'history';
import { Message } from '../../message-types';
import { Client } from '../../../Clients/clients.types';
import * as rawActions from '../../messages.actions';
import {
  handleArchiverCurrent, loadMessage, getUnreadMessages,
  messageThreadPatch
} from '../../routes/MessagesThread/messages-thread.actions';
import { useSelector } from '../../../../reducers';

import InboxItem from '../InboxListItem/InboxListItem.component';

import { useStyles } from './inboxList.styles';

type InboxListProps = {
  history: History;
  chatInNewPage?: boolean;
  resizeNumber?: number;
  onOpen?: () => void;
}

export const searchClientOptions = [
  { value: 'name', label: 'Name' },
  { value: 'phone', label: 'Phone' },
];

const InboxList = ({ history, chatInNewPage, resizeNumber, onOpen }: InboxListProps) => {
  const classes = useStyles();
  const [openNew, setOpenNew] = useState(false);
  const [reads, setReads] = useState(0);
  const [unreads, setUnreads] = useState(0);

  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);

  const {
    busy,
    inbox,
    tz,
    query,
    inboxFilter,
    inboxPage,
    headerHeight,
    contactType,
    tab,
    features,
    client,
    totalInboxCount,
    perPage,
    allLoaded,
  } = useSelector(state => ({
    all: state.messages.all,
    tab: state.messages.smsTab,
    busy: state.messages.busy,
    inbox: state.messages.inbox,
    query: state.messages.query,
    inboxFilter: state.messages.inboxFilter,
    inboxPage: state.messages.inboxPage,
    totalInboxCount: state.messages.totalInboxCount,
    perPage: state.messages.perPage,
    tz: state.login.office.timezone,
    useNewChat: R.includes('NewSMSThreads', R.pathOr([], ['login', 'features'], state)),
    contactType: state.messages.contactType,
    headerHeight: state.login.headerHeight,
    features: state.login.features,
    client: state.messageThread.client,
    allLoaded: state.messages.allLoaded,
  }));

  const isSpark = R.includes(SPARK_FEATURE, features);
  const hasLeads = R.includes('Leads', features) && !isSpark;

  const setTab = (value: string) => {
    actions.messagesPatch('smsTab', value);
  };
  const countSelecteds = (messages: Message[]) => {
    const selecteds = R.filter((message: Message) => message.selected, messages);
    const count = R.length(selecteds);
    const countUnreads = selecteds.filter(msg => msg.unread).length;
    setUnreads(countUnreads);
    setReads(selecteds.length - countUnreads);
    return count;
  };

  const refresh = () => {
    actions.refresh();
    dispatch(getUnreadMessages());
    dispatch(loadMessage({
      clientId: client?.id,
      unread: tab === 'unread',
      archived: tab === 'archived',
      perPage,
    }));
  };

  const selectMessage = ( index: number, value: boolean) => {
    actions.selectMessage({ type: 'inbox', index, value });
  };

  const selectAll = (value: boolean) => {
    actions.selectAll({ type: 'inbox', value });
  };

  const goto = (client: Client) => {
    if (chatInNewPage) {
      history.push(`/inbox/chat/${client.id}`);
    } else {
      dispatch(messageThreadPatch({
        client: {},
      }));
      onOpen?.();
      dispatch(loadMessage({
        clientId: client?.id,
        unread: tab === 'unread',
        archived: tab === 'archived',
        perPage,
      }));
    }
  };

  const unreadSelected = () => {
    actions.unreadSelected(inbox,
      {
        query,
        filter: inboxFilter,
        unread: tab === 'unread',
        contactType,
        perPage
      }
    );
  };

  const readSelected = () => {
    actions.readSelected(inbox,
      {
        query, 
        filter: inboxFilter,
        unread: tab === 'unread',
        page: inboxPage,
        contactType,
        perPage
      }
    );
  };

  const archiveSelected = () => {
    actions.archiveSelected({ messages: inbox, unread: tab === 'unread', contactType, perPage });
    dispatch(handleArchiverCurrent(inbox, true));
  };

  const unArchiveSelected = () => {
    actions.unArchiveSelected({ messages: inbox, contactType, perPage });
    dispatch(handleArchiverCurrent(inbox, false));
  };

  const handleTab = (value: string) => {
    setTab(value);
    actions.refresh(value, 1);
    actions.messagesPatch('query', '');
  };

  const totalSelecteds = React.useMemo(() => {
    return countSelecteds(inbox);
  }, [inbox]);

  const getMessages = (page: number, isPagination = false) => {
    actions.searchInbox({
      query,
      // filter,
      page,
      unread: tab === 'unread',
      // isLead,
      contactType: hasLeads ? contactType : 'Client',
      perPage,
      archived: tab === 'archived',
      isPagination,
    });
  };

  React.useEffect(() => {
    getMessages(inboxPage);
    actions.messagesPatch('query', '');
  }, [perPage, hasLeads]);

  React.useEffect(() => {
    actions.refresh();
  }, [contactType]);

  React.useEffect(() => {
    if (!hasLeads) {
      actions.messagesPatch('contactType', 'Client');
    }
  }, [hasLeads]);

  return (
    <Grid className={classes.root}>
      <PopupTemplate />
      <FindClient
        open={openNew}
        onClose={() => setOpenNew(false)}
        history={history}
        goto={({ client }: {client: Client}) => goto(client)}
        isLead={contactType === 'Lead'}
        leadsAndClients={(contactType === '' && R.includes('Leads', features) ? true : undefined)}
      />
      <Header
        title='SMS Inbox'
        pageId="inbox"
        updateSize={resizeNumber}
        leftIcons={[
          <HeaderTabs
            value={tab}
            setTab={handleTab}
            tabs={[
              { value: 'all', label: 'All' },
              { value: 'unread', label: 'Unread' },
              { value: 'archived', label: 'Archived' },
            ]}
          />
        ]}
        rightIcons={[
          <Grid marginX="5px">
            <IconButton disabled={busy} onClick={refresh}>
              <SyncIcon className={`${classes.headerIcon} ${busy ? 'sked-spin-l' : ''}`} />
            </IconButton>
          </Grid>,
          R.includes('Leads', features) &&
            <FilterSelect
              items={['Client', 'Lead']}
              title='Filter'
              headerTitle='Contact Type'
              noneText=''
              type='filter'
              selected={contactType}
              setSelected={(value) => actions.messagesPatch('contactType', value)}
              Icon={FilterListIcon}
            />,
          <HeaderButton
            disabled={busy}
            onClick={() => setOpenNew(true)}
            Icon={AddIcon}
            title='New Message'
          />,
        ]}
        onlyIconsWidth={chatInNewPage ? 672 : 668}
        breakPoints={[
          {
            width: 522,
            mobileItems: [0]
          },
        ]}
      />
      <Grid>
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding={1}
          paddingBottom={0.5}
        >
          <Grid>
            <Checkbox
              className={classes.checkbox}
              checked={inbox.length && (totalSelecteds === inbox.length || totalSelecteds > 0)}
              onChange={(event) => selectAll(event.target.checked)}
              checkedIcon={totalSelecteds > 0 && totalSelecteds < inbox.length ? <PartialCheckedIcon /> : <CheckedIcon />}
            />
          </Grid>
          {totalSelecteds > 0 ? (
            <Grid>
              {!!reads && tab !== 'archived' && (
                <Button
                  startIcon={<DraftsIcon className={classes.buttonIcon} />}
                  onClick={unreadSelected}
                  className={classes.button}
                  color="primary"
                >
                  Mark Unread
                </Button>
              )}
              {!!unreads && tab !== 'archived' && (
                <Button
                  startIcon={<MarkunreadIcon className={classes.buttonIcon} />}
                  onClick={readSelected}
                  className={classes.button}
                  color="primary"
                >
                  Mark Read
                </Button>
              )}
              {tab !== 'archived' ? (
                <Button
                  startIcon={<ArchiveIcon className={classes.buttonIcon} />}
                  onClick={archiveSelected}
                  className={classes.button}
                  color="primary"
                >
                  Archive
                </Button>
              ) : (
                <Button
                  startIcon={<ArchiveIcon className={classes.buttonIcon} />}
                  onClick={unArchiveSelected}
                  className={classes.button}
                  color="primary"
                >
                  Unarchive
                </Button>
              )}
            </Grid>
          ) : (
            <Grid display="flex" alignItems="center" style={{ background: '#FFF' }}>
              <HeaderSearch
                placeholder={inboxFilter === 'name' ? 'LastName FirstName' : 'Phone Number'}
                value={query}
                timeOut={400}
                onChange={(v) => {
                  actions.messagesPatch('query', v);
                  if (inboxFilter === 'name') {
                    const splitUp = v.split(' ');
                    const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
                    const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
                    const phone = splitUp[2] ? splitUp[2].trim() : undefined;
                    return actions.searchInbox({
                      query: {
                        lastName,
                        firstName,
                        phone,
                      },
                      page: inboxPage,
                      perPage,
                      unread: tab === 'unread',
                      contactType,
                      archived: tab === 'archived'
                    });
                  }
                  return actions.searchInbox({
                    query: {
                      [inboxFilter]: v || undefined,
                    },
                    page: inboxPage,
                    perPage,
                    unread: tab === 'unread',
                    contactType,
                    archived: tab === 'archived'
                  });
                }}
                options={searchClientOptions}
                selectedOption={inboxFilter}
                setOption={(value) => actions.messagesPatch('inboxFilter', value)}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          paddingTop={1}
          className={classes.content}
          style={{
            height: `calc(100vh - ${headerHeight}px - 90px - 50px)`,
          }}
        >
          {inbox.map((message: Message, index: number) => (
            <InboxItem
              key={message.msgId}
              message={message}
              tz={tz}
              goto={(client) => {
                goto(client);
                actions.readAllMessages(client.id);
                onOpen?.();
              }}
              selectMessage={(value) => selectMessage(index, value)}
            />
          ))}
        </Grid>
        {!inbox.error &&
        <Grid className={classes.pageControls}>
          <PerPage perPage={perPage} setPerPage={(p) => actions.messagesPatch('perPage', p)} />
          <Grid className={classes.pageArrows}>
            <div style={{ marginRight: 5, marginLeft: 5, fontSize: 14 }}>
              {outputLocation(inboxPage, perPage, totalInboxCount, inbox.length)}
            </div>
            <IconButton
              disabled={inboxPage === 1}
              onClick={() => getMessages(inboxPage - 1, true)}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              disabled={allLoaded}
              onClick={() => getMessages(inboxPage + 1, true)}>
              <ChevronRightIcon />
            </IconButton>
          </Grid>
        </Grid>
        }
      </Grid>
    </Grid>
  );

};

export default InboxList;
