import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAlert-root': {
      borderRadius: 26,
      padding: '7px 10px 7px 12px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiAlert-message': {
      padding: 0,
    },
    '& .MuiAlert-icon': {
      marginRight: 8,
      padding: 0,
      opacity: 1,

      '& svg': {
        width: 14,
        height: 14,
      }
    }
  },
  success: {
    color: '#F5F5F5',
    backgroundColor: '#65B67F',

    '& button': {
      '& svg': {
        color: '#F5F5F5'
      }
    }
  },
  close: {
    marginLeft: 8,
    padding: 1,

    '& .MuiSvgIcon-root': {
      fontSize: 18,
    }
  },
}));
