import api, { skedApi } from '../../services/api.js';
import {
  IntakeForm, FormChild, SectionChild, SimpleWidgetType,
  ComplexWidget, defaultPictureUrl
} from './intake-types';
import {
  CrForm, Form, Revision, CrRevision, CrPage, CrPageChild,
  CrPageItem, CrQuestion, SimpleQuestionType, QuestionThingy,
  ScaleDataType, Question, PageItem, Submission, defaultRevision,
} from './ServerTypes';
import {
  tail, includes, prepend, cond, T, always, sortBy, prop, reverse,
  isEmpty,
} from 'ramda';
import axios from 'axios';
import { convertToIntakeAnswers } from './answer.service';
import { SubmissionAnswers, IntakeAnswer } from './answer-types';
import { getSocket } from '../../services/websockets';
import { remoteAction } from '../../services/actionService.js';
import * as at from './intake-forms.actionTypes';

export const formsPatch = (data: object) => ({
  type: at.FORMS_PATCH,
  data,
});

export const fetchFormList = (): Promise<Form[]> => {
  return api.get('form');
};

export const createForm = (body: CrForm): Promise<Form> => {
  return api.post('form', body);
};

export const fetchFormRevisions = (formId: number) => {
  return api.get(`form/${formId}/revisions/latest`);
};

export const fetchLatestFormRevision = (formId: number) => {
  return remoteAction({
    type: at.FORMS_REMOTE_GET_LATEST_REVISION,
    action: () => api.get(`form/${formId}/revisions/latest`).then((rev) => {
      if (rev) {
        return rev;
      }
      return fetchForm(formId);
    }).catch((e) => {
      if (e?.response?.status === 404) {
        return fetchForm(formId)
          .then(({ id, name, description, sendNotification }) => {
            const revision = {
              children: [] as FormChild[],
              ...defaultRevision,
              formName: name,
              formId: id,
              formDescription: description,
              formSendNotification: sendNotification,
            };
            return revision;
          });
      }
      return e.response;
    }),
  });
};

export const fetchForm = (formId: number): Promise<IntakeForm> => {
  return api.get(`form/${formId}`).then(({ id, name, description, sendNotification }: Form) => {
    return {
      id,
      name,
      description,
      sendNotification,
      children: [],
      pdfEhr: null,
      ehrFolder: null,
      pdfGeneration: null,
      logoUrl: null,
      logoAlignment: null,
      info: null,
      formId: id,
      conditionals: [],
    };
  });
};

export const getForm = (formId: number) => {
  return remoteAction({
    type: at.FORMS_REMOTE_GET_FORM,
    action: () => api.get(`form/${formId}`),
  });
};

export const oldGetForm = (formId: number) => {
  return api.get(`form/${formId}`);
};

export const saveFormRevision = (formId: number, form: IntakeForm): Promise<Revision> => {
  const crTest = convertToBackEnd(form, form.description);
  return api.post(`form/${formId}`, crTest);
};

export const copyForm = (form: IntakeForm): Promise<number> => {
  const body = {
    name: form.name + '(Copy)',
    description: form.description,
    sendNotification: !!form.sendNotification,
  };
  return api.post('form', body).then((f: Form) => {
    return saveFormRevision(f.id, form).then(() => {
      return f.id;
    });
  });
};

export const saveForm = (body: CrForm) => {
  return api.put(`form/${body.id}`, body);
};

export const savedForm = (data: object) => ({
  type: at.FORMS_SAVED,
  data,
});

const convertToBackEnd = (form: IntakeForm, description: string): CrRevision => {
  const pages = (form.children || []).reduce<CrPage[]>((acc, item, order) => {
    if (acc.length === 0 || item.type === 'PageBreak') {
      const newPage: CrPage = {
        items: [],
        pageNumber: acc.length + 1
      };
      acc = acc.concat([newPage]);
    }
    const curPage = acc[acc.length - 1];
    const pageItem = formChildToItem(item, order);
    if (pageItem.child) {
      curPage.items.push(pageItem);
    }
    return acc;
  }, []);

  const revision = {
    description,
    pages,
    logoUrl: form.logoUrl,
    logoAlignment: form.logoAlignment,
    info: form.info,
    conditionals: sortBy(prop('questionIndex'), form.conditionals),
  };

  return revision;
};

const formChildToItem = (formChild: FormChild, order: number): CrPageItem => {
  return {
    child: mkPageChild(formChild),
    order
  };
};

const mkPageChild = (formChild: FormChild): CrPageChild => {
  if (formChild.type === 'Header') {
    return {
      Header: formChild.text
    };
  }
  if (formChild.type === 'Instruction') {
    return {
      Text: formChild.text
    };
  }
  if (formChild.type === 'Section') {
    return {
      Section: {
        sectionNumber: 1,
        name: formChild.name,
        description: formChild.description,
        questions: formChild.children
          .flatMap((sectionChild, order) => {
            return toCrQuestion(sectionChild, order, false);
          })
      },
    };
  }
  if (formChild.type === 'PrivateSection') {
    return {
      Section: {
        sectionNumber: 1,
        name: formChild.name,
        description: formChild.description,
        questions: formChild.children
          .flatMap((sectionChild, order) => {
            return toCrQuestion(sectionChild, order, true);
          })
      },
    };
  }
};

const toCrQuestion = (sectionChild: SectionChild, order: number, forOffice: boolean): CrQuestion[] => {
  //Backend doesn't support headers
  if (sectionChild.type === 'Header' ||
    sectionChild.type === 'Instruction') {
    return [{
      width: 12,
      height: 1,
      isRequired: false,
      forOffice,
      order,
      question: {
        SimpleQuestion: {
          questionType: {
            None: []
          },
          label: sectionChild.text
        }
      }
    }];
  }
  if (sectionChild.type === 'ComplexWidget') {
    const alwaysRequired = includes(sectionChild.typeData.type, ['ConsentPapers', 'Signature']);
    return [{
      width: 12,
      height: 1,
      isRequired: sectionChild.required || alwaysRequired || false,
      forOffice,
      order,
      question: mkComplexQuestionType(sectionChild)
    }];
  }
  if (sectionChild.type === 'SimpleWidget') {
    return [{
      width: sectionChild.width,
      height: 1,
      isRequired: sectionChild.required || false,
      forOffice,
      order,
      metadata: {
        placeholder: sectionChild.placeholder,
      },
      question: {
        SimpleQuestion: {
          label: sectionChild.label,
          questionType: mkSimpleQuestionType(sectionChild.questionType)
        }
      }
    }];
  }
  return [];
};

const mkComplexQuestionType = (question: ComplexWidget): QuestionThingy => {
  if (question.typeData.type === 'RadioGrid') {
    return {
      RadioGrid: {
        optionType: ScaleDataType.Radio,
        labels: question.typeData.columnLabels,
        options: question.typeData.rowLabels.map(o => ({
          label: o
        }))
      }
    };
  }
  if (question.typeData.type === 'FlexibleTable') {
    return {
      FlexibleTable: {
        columns: question.typeData.inputs.map(sw => ({
          label: sw.label,
          questionType: mkSimpleQuestionType(sw.questionType)
        }))
      }
    };
  }
  if (question.typeData.type === 'FixedTable') {
    return {
      FixedTable: {
        columns: question.typeData.inputs.map(sw => ({
          label: sw.label,
          questionType: mkSimpleQuestionType(sw.questionType)
        })),
        labels: prepend(question.typeData.rows.header, question.typeData.rows.labels),
      }
    };
  }
  if (question.typeData.type === 'FileUpload') {
    return {
      DocumentUpload: {
        ...question.typeData
      },
    };
  }
  // TODO how to support multiple papers?
  // Probably should update the front end to be like
  // the backend here
  if (question.typeData.type === 'ConsentPapers') {
    return {
      DocumentAgreement: {
        ...question.typeData,
      }
    };
  }
  if (question.typeData.type === 'DrawablePicture') {
    return {
      Picture: {
        image: question.typeData.pictureUrl,
        colors: question.typeData.options
      }
    };
  }
  if (question.typeData.type === 'Signature') {
    return {
      Signature: [] as number[]
    };
  }
};

const mkSimpleQuestionType = (sWidgetType: SimpleWidgetType): SimpleQuestionType => {
  if (sWidgetType.type === 'ShortText') {
    return {
      ShortText: []
    };
  }
  if (sWidgetType.type === 'LongText') {
    return {
      LongText: []
    };
  }
  if (sWidgetType.type === 'Email') {
    return {
      EmailQuestion: []
    };
  }
  if (sWidgetType.type === 'Date') {
    return {
      DateQuestion: []
    };
  }
  if (sWidgetType.type === 'Number') {
    return {
      NumberQuestion: {
        minNumber: 0,
        maxNumber: 1e9
      }
    };
  }
  if (sWidgetType.type === 'Dropdown') {
    return {
      DropdownOptions: {
        options: sWidgetType.options.map(o => ({
          label: o
        }))
      }
    };
  }
  if (sWidgetType.type === 'Radio') {
    return {
      RadioOptions: {
        options: sWidgetType.options.map(o => ({
          label: o
        }))
      }
    };
  }
  if (sWidgetType.type === 'Checkbox') {
    return {
      CheckboxOptions: {
        options: sWidgetType.options.map(o => ({
          label: o
        }))
      }
    };
  }
  if (sWidgetType.type === 'CheckboxSingle') {
    return {
      SingleCheckbox: true
    };
  }
  if (sWidgetType.type === 'SectionInstruction') {
    return {
      SectionInstruction: []
    };
  }
  return {
    None: []
  };
};

const simpleQuestionTypetoSimpleWidgetType = (qt: SimpleQuestionType): SimpleWidgetType => {
  if (qt.LongText) {
    return { type: 'LongText' };
  }
  if (qt.ShortText) {
    return { type: 'ShortText' };
  }
  if (qt.DateQuestion) {
    return { type: 'Date' };
  }
  if (qt.EmailQuestion) {
    return { type: 'Email' };
  }
  if (qt.NumberQuestion) {
    return { type: 'Number' };
  }
  if (qt.DropdownOptions) {
    return {
      type: 'Dropdown',
      options: qt.DropdownOptions.options.map(o => o.label)
    };
  }
  if (qt.RadioOptions) {
    return {
      type: 'Radio',
      options: qt.RadioOptions.options.map(o => o.label)
    };
  }
  if (qt.CheckboxOptions) {
    return {
      type: 'Checkbox',
      options: qt.CheckboxOptions.options.map(o => o.label)
    };
  }
  if (qt.SingleCheckbox) {
    return {
      type: 'CheckboxSingle'
    };
  }
  if (qt.SectionInstruction) {
    return { type: 'SectionInstruction' };
  }
  return { type: 'Spacer' };
};


//  This is the code to convert from backend to admin
const questionToSectionChild = (q: Question): SectionChild => {
  if (q.question.SimpleQuestion) {
    return {
      id: q.questionId,
      label: q.question.SimpleQuestion.label,
      width: q.width,
      required: q.isRequired,
      forOffice: q.forOffice,
      type: 'SimpleWidget',
      officeNote: q.officeNote,
      placeholder: q.metadata?.placeholder || '',
      questionType: simpleQuestionTypetoSimpleWidgetType(q
        .question
        .SimpleQuestion
        .questionType)
    };
  }

  if (q.question.DocumentUpload) {
    return {
      type: 'ComplexWidget',
      width: 12,
      id: q.questionId,
      required: q.isRequired,
      forOffice: q.forOffice,
      officeNote: q.officeNote,
      typeData: {
        type: 'FileUpload',
        ...q.question.DocumentUpload
      }
    };
  }

  if (q.question.Picture) {
    return {
      type: 'ComplexWidget',
      width: 12,
      id: q.questionId,
      required: q.isRequired,
      forOffice: q.forOffice,
      officeNote: q.officeNote,
      typeData: {
        type: 'DrawablePicture',
        pictureUrl: q.question.Picture.image || defaultPictureUrl,
        options: q.question.Picture.colors
      }
    };
  }

  if (q.question.FlexibleTable) {
    return {
      type: 'ComplexWidget',
      width: 12,
      id: q.questionId,
      required: q.isRequired,
      forOffice: q.forOffice,
      officeNote: q.officeNote,
      typeData: {
        type: 'FlexibleTable',
        defaultRowCount: 4,
        inputs: q.question.FlexibleTable.columns.map((r, i) => ({
          id: i,
          label: r.label,
          type: 'SimpleWidget',
          forOffice: q.forOffice,
          questionType: simpleQuestionTypetoSimpleWidgetType(r.questionType)
        }))
      }
    };
  }

  if (q.question.FixedTable) {
    return {
      type: 'ComplexWidget',
      width: 12,
      id: q.questionId,
      required: q.isRequired,
      forOffice: q.forOffice,
      officeNote: q.officeNote,
      typeData: {
        type: 'FixedTable',
        rows: {
          header: q.question.FixedTable.labels[0],
          labels: tail(q.question.FixedTable.labels)
        },
        inputs: q.question.FixedTable.columns.map((r, i) => ({
          id: i,
          label: r.label,
          type: 'SimpleWidget',
          forOffice: q.forOffice,
          questionType: simpleQuestionTypetoSimpleWidgetType(r.questionType)
        }))
      }
    };
  }

  if (q.question.RadioGrid) {
    return {
      type: 'ComplexWidget',
      width: 12,
      id: q.questionId,
      required: q.isRequired,
      forOffice: q.forOffice,
      officeNote: q.officeNote,
      typeData: {
        type: 'RadioGrid',
        columnLabels: q.question.RadioGrid.labels,
        rowLabels: q.question.RadioGrid.options.map(o => o.label)
      }
    };
  }

  if (q.question.DocumentAgreement) {
    return {
      type: 'ComplexWidget',
      width: 12,
      id: q.questionId,
      required: q.isRequired,
      forOffice: false,
      officeNote: q.officeNote,
      typeData: {
        type: 'ConsentPapers',
        ...q.question.DocumentAgreement
      }
    };
  }

  if (q.question.Signature) {
    return {
      type: 'ComplexWidget',
      width: 12,
      id: q.questionId,
      forOffice: q.forOffice,
      required: q.isRequired,
      officeNote: q.officeNote,
      typeData: {
        type: 'Signature'
      }
    };
  }

  return {
    id: q.questionId,
    type: 'Header',
    size: 2,
    text: 'Not implemented - ',
    width: 12,
  };
};



const questionsToRows = (questions: Question[]): SectionChild[] => {
  return questions
    .sort((a, b) => a.order - b.order)
    .map(questionToSectionChild);
};

const backendItemToChild = (pageItem: PageItem): FormChild => {
  if (pageItem.child.Header) {
    return {
      id: pageItem.pageItemId,
      type: 'Header',
      size: 1,
      text: pageItem.child.Header,
      width: 12,
    };
  }
  if (pageItem.child.Text) {
    return {
      id: pageItem.pageItemId,
      type: 'Instruction',
      text: pageItem.child.Text,
      width: 12,
    };
  }
  if (pageItem.child.Section) {
    const section = pageItem.child.Section;
    const isPrivate = section.questions.reduce((acc, cur) => {
      return acc && cur.forOffice;
    }, true);
    return {
      id: pageItem.pageItemId,
      type: isPrivate && !isEmpty(section.questions) ? 'PrivateSection' : 'Section',
      name: section.name,
      description: section.description,
      count: section.questions.length,
      children: questionsToRows(section.questions)
    };
  }
  return {
    id: pageItem.pageItemId,
    type: 'Header',
    size: 1,
    text: '',
    width: 12,
  };
};

//TODO this needs help
export const revisionToForm = (revision: Revision): IntakeForm => {
  const children = revision.pages
    .sort((a, b) => a.pageNumber - b.pageNumber)
    .map(rp => {
      return rp.items
        .sort((a, b) => a.order - b.order)
        .map(backendItemToChild)
        //Add page brake in
        .concat({
          id: rp.pageId,
          width: 12,
          type: 'PageBreak',
        });
    })
    .flat()
    //Remove the last pagebreak item
    .slice(0, -1);
  return {
    id: revision.revisionId,
    name: revision.formName,
    description: revision.formDescription,
    sendNotification: revision.formSendNotification,
    children,
    logoUrl: revision.logoUrl,
    pdf: revision.pdf,
    logoAlignment: revision.logoAlignment,
    info: revision.info,
    formId: revision.formId,
    conditionals: revision.conditionals,
  };
};

interface AppointmentQuery {
  locationId?: number;
  after?: string;
  before?: string;
}

interface ClientQuery {
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export interface SubmissionQ {
  clientId?: number;
  revId?: number;
  isSubmitted?: boolean;
  formId?: number;
  submitted?: string;
  subId?: number;
  client?: ClientQuery;
  appointment?: AppointmentQuery;
  formName?: string;
  archived?: boolean;
  created?: string;
  started?: boolean;
}

export interface QuerySubmission {
  page: number;
  perPage: number;
  query: SubmissionQ;
}

export interface QuerySubmissionResponse {
  page: number;
  totalPages: number;
  totalCount: number;
  data: Submission[];
}

export interface SubmissionResponse {
  revId: number;
  answers: IntakeAnswer[];
  isComplete: boolean;
  updated: string;
  uploaded: string;
  officeNotes: OfficeNote[];
  revision: Revision;
  submitted: string;
}

export const fetchSubmissions = (data: QuerySubmission): Promise<QuerySubmissionResponse> => {
  const newData = {
    ...data,
    query: {
      ...data.query,
      submittedAfter: data.query.submitted,
      submittedBefore: data.query.submitted ? data.query.submitted.split('T')[0] + 'T23:59:59Z' : undefined,
      createdAfter: data.query.created,
      createdBefore: data.query.created ? data.query.created.split('T')[0] + 'T23:59:59Z' : undefined,
    }
  };
  return api.post('form/submission/query', newData);
};

export const fetchSubmission = (id: number): Promise<SubmissionResponse> => {
  return api.get(`form/submission/${id}`).then((data: SubmissionAnswers) => {
    return {
      revId: data.revId,
      answers: convertToIntakeAnswers(data.answers),
      isComplete: Boolean(data.submitted),
      updated: data.updated,
      uploaded: data.uploaded,
      officeNotes: data.officeNotes,
      revision: data.revision,
      submitted: data.submitted,
    };
  });
};

export const fetchFormRevision = (id: number): Promise<IntakeForm> => {
  return api.get(`form/revisions/${id}`).then((data) => {
    return revisionToForm(data);
  });
};



const prodRevIds = [
  28, 2, 3, 4, 5, 32, 7, 4679, 9, 10, 11
];
const qa1RevIds = [
  148, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
];

export const unprotectedFetchFormRevisionById = (): Promise<IntakeForm[]> => {
  const revIds = cond([
    [includes('qa1'), always(qa1RevIds)],
    [includes('int'), always([])],
    [includes('plat'), always([])],
    [includes('jonny'), always([])],
    [includes('yannick'), always([])],
    [T, always(prodRevIds)]
  ])(process.env.API_URL);
  return Promise.all(revIds.map((id) => {
    return axios.get<Revision>(`${process.env.PORTAL_URL}/api/form/app/revision/${id}`);
  })).then((revisions) => revisions
    .map(({ data }: any) => revisionToForm(data))
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }));
};

export interface OfficeNote {
  subId: number,
  questionId: number,
  officeNote: string,
}

export const updateOfficeNote = (data: OfficeNote): Promise<void> => {
  return api.put('form/submission/notes', data);
};

export const deleteForm = (id: number): Promise<void> => {
  return api.delete(`form/delete/${id}`, { data: {} });
};

export interface PdfGen {
  created: string; // datetime
  phiId: number;
  url: string;
  uploaded?: string; // datetime
  submissionId?: number;
  revId?: number;
}

export const getPdfs = (id: number): Promise<PdfGen[]> => {
  return api.get(`form/submission/${id}/pdf`).then((gens: PdfGen[]) => {
    const orderedGens = reverse(sortBy(prop('created'), gens));
    return orderedGens;
  });
};

export const pushPdfToEhr = (phiId: number): Promise<void> =>
  api.post(`form/submission/${phiId}/pdf/ehr`);

export const generatePdf = (id: number): Promise<void> => {
  return api.post(`form/submission/${id}/pdf`, {});
};

export const uploadImage = (file: File): Promise<string> => {
  const config = {
    headers: {
      'Content-Type': file.type,
      'Authorization': skedApi.defaults.headers.common.Authorization,
      'X-As-Office': skedApi.defaults.headers.common['X-As-Office'],
    },
  };
  return axios.post(
    `${process.env.API_URL}/form/image`,
    file,
    config).then(({ data }: any) => data.location);
};

interface Attachment {
  attachmentName: string;
  attachmentId: number;
  attachmentUrl: string;
  attachmentMimeType: string;
}

interface AttachmentLists {
  officeAttachments: Attachment[];
  featuredAttachments: Attachment[];
}

export interface GenerateBlank {
  revId: number;
  attachmentId?: number;
}

export const generateBlankPdf = ({ revId }: GenerateBlank): Promise<string> => {
  return api.post(`form/revisions/${revId}/pdf`).then(() => {
    return new Promise((res, rej) => {
      setTimeout(() => {
        return api.get(`form/revisions/${revId}`)
          .then((rev) => {
            return api.post('attachment', { attachmentIds: [rev.pdf] })
              .then(({ officeAttachments }: AttachmentLists) => {
                res(officeAttachments[0]?.attachmentUrl);
              });
          })
          .catch((err) => rej(err));
      }, 500);
    });
  });
};


type Tag = 'FormPdfCreated' | 'FormBlankPdfCreated';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const messageHandler = (
  setPhi: (url: PdfGen) => void,
  tag: Tag
) => (event: any) => {
  const parsed = JSON.parse(event.data);
  if (parsed.tag === tag) {
    setPhi(parsed.contents);
  }
};

let removeListener: () => void;
export const initPdfListener = (setPhi: (url: PdfGen) => void, tag: Tag) => {
  const socket = getSocket();
  console.log('the socket: : ', socket);
  if (!socket) {
    removeListener = () => null;
    return false;
  } else {
    socket.addEventListener('message', messageHandler(setPhi, tag));
    removeListener = () => {
      socket.removeEventListener('message', messageHandler(setPhi, tag));
    };
    return true;
  }
};

export const closePdfEvents = () => {
  if (removeListener) {
    removeListener();
  }
};

export interface CreateSubmission {
  clientId: number;
  formId: number;
}

export const makeNewSub = (body: CreateSubmission): Promise<Submission> => {
  return api.post('form/submission', body);
};

export const makeNewOneTimeToken = (subId: number): Promise<string> => {
  return api.post(`form/submission/${subId}/onetimetoken`).then(({ token }) => token);
};

export interface GlobalSettings {
  pin: string;
}

export const getSettingsForm = (): Promise<GlobalSettings> => {
  return api.get('form/settings');
};

export const saveSettings = (data: GlobalSettings): Promise<GlobalSettings> => {
  return api.post('form/settings', data);
};

export const archiveSub = (sub: Submission): Promise<boolean> => {
  const archive = !sub.archived;
  return api.post(`form/submission/${sub.subId}/archive`, { archive }).then(() => archive);
};

interface PasteEntireFormProps {
  type: 'top' | 'bottom';
  data: FormChild[];
}

export const pasteEntireForm = (data: PasteEntireFormProps) => ({
  type: at.FORMS_PASTE_ENTIRE_FORM,
  data,
});
