import React from 'react';

import { Button, Tooltip, Grid } from '@mui/material';

import { useStyles } from './headerButton.styles';

type ButtonProps = {
  title?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  borderSolid?: boolean;
  disabled?: boolean;
  Icon?: ({ color, className, style }: { color?: string, className?: string, style?: object }) => JSX.Element;
  EndIcon?: ({ color, className, style }: { color?: string, className?: string, style?: object }) => JSX.Element;
  children?: JSX.Element | JSX.Element[];
  type?: 'hide' |'filter' |'sort' | 'group' | 'archiveChat';
  color?: 'primary' | 'danger' | 'lightgray';
  active?: boolean;
  onlyIcon?: boolean;
  warningIcon?: boolean;
  iconStyle?: React.CSSProperties;
  iconClass?: string;
  className?: string;
  marginRight?: number;
  marginLeft?: number;
  borderRadius?: number;
  padding?: number;
}

const HeaderButton = ({
  title, onClick, Icon, EndIcon, iconStyle, color, iconClass,
  borderSolid, disabled, children, active, type, onlyIcon,
  warningIcon, className, marginRight, marginLeft, borderRadius,
  padding,
}: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      startIcon={(Icon && !onlyIcon) ? <Icon className={`${classes.buttonIcon} ${iconClass} ${disabled ? classes.btnDisabled : ''}`} color={`${disabled ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.6)'}`} style={iconStyle} /> : null}
      endIcon={(EndIcon && !onlyIcon) ? <EndIcon className={`${classes.buttonIcon} ${iconClass} ${disabled ? classes.btnDisabled : ''}`} color={`${disabled ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.6)'}`} style={iconStyle} /> : null}
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
      className={`${classes.customButtom} ${className} ${onlyIcon && Icon ? classes.onlyIcon : ''} ${(borderSolid && !(onlyIcon && Icon)) ? classes.borderSolid : ''} ${color ? classes[color] : ''} ${active && type ? classes[type] : ''}`}
      color="primary"
      style={{ marginRight, marginLeft, borderRadius, padding }}
    >
      {(onlyIcon && Icon) ? (
        <Tooltip
          title={title}
          placement='bottom'
          arrow
        >
          <Grid display="flex" alignItems="center">
            <Icon className={`${classes.buttonIcon} ${iconClass} ${disabled ? classes.btnDisabled : ''}`} color={`${disabled ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.6)'}`} style={iconStyle} />
          </Grid>
        </Tooltip>
      ) : children || title}
      {warningIcon && <div className={classes.warningIcon} />}
    </Button>
  );
};

export default HeaderButton;
