import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  title: {
    fontSize: 13,
    fontWeight: 600,
    color: 'rgba(0,0,0,0.8)',
    marginBottom: 6,
    marginTop: 9,
    textTransform: 'uppercase',
  },
  item: {
    border: '1px solid rgba(0,0,0,0.2)',
    padding: '2px 8px',
    borderRadius: 6,
    color: 'rgba(0,0,0,0.5)',
    marginBottom: 6,
    fontSize: 14,
  }
}));
