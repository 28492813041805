import React, { ComponentProps } from 'react';

const PaperClipIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} className={className}>
      <path d="M6.02869 10.4947C6.02869 9.007 5.99159 7.5183 6.03699 6.0319C6.11219 3.5708 8.85749 1.3473 11.8471 2.7897C13.1551 3.4207 13.9366 4.5513 13.9661 5.9935C14.0213 8.6988 13.9914 11.4057 13.9962 14.1121C13.9963 14.1818 13.991 14.2519 13.9832 14.3213C13.9465 14.6495 13.7017 14.8596 13.3569 14.861C13.0618 14.8622 12.8275 14.6527 12.8016 14.3269C12.7684 13.909 12.7455 13.4894 12.7442 13.0705C12.7386 11.233 12.742 9.3956 12.7429 7.5581C12.7431 7.0396 12.7752 6.5193 12.7413 6.003C12.6677 4.8847 11.9036 3.9799 10.7801 3.6286C9.73629 3.3023 8.63599 3.6381 7.87069 4.4788C7.38099 5.0168 7.21789 5.6481 7.21759 6.3321C7.21629 9.0883 7.22449 11.8446 7.22939 14.6008C7.23059 15.2577 7.36779 15.8538 8.00379 16.2312C8.57289 16.5688 9.16019 16.5844 9.74469 16.2931C10.3191 16.0067 10.6028 15.521 10.6198 14.9075C10.6389 14.219 10.6257 13.5296 10.6251 12.8405C10.6236 11.1029 10.6224 9.3652 10.6171 7.6275C10.6166 7.4689 10.6067 7.3059 10.5677 7.1529C10.4935 6.8623 10.1824 6.6655 9.88659 6.6986C9.60329 6.7303 9.40539 6.9416 9.38429 7.2589C9.36909 7.4878 9.37309 7.7181 9.37359 7.9477C9.37739 9.7453 9.38429 11.5429 9.38479 13.3405C9.38479 13.5484 9.37549 13.7613 9.32939 13.9632C9.26389 14.2499 9.03109 14.4039 8.73979 14.3872C8.44109 14.3702 8.26139 14.2052 8.20469 13.9315C8.16849 13.7566 8.15739 13.5744 8.15739 13.3956C8.15719 11.4681 8.15939 9.5407 8.16559 7.6133C8.16729 7.075 8.24359 6.5494 8.64499 6.1354C9.15649 5.6079 9.77789 5.3612 10.5213 5.5825C11.2673 5.8046 11.6699 6.3324 11.8011 7.0585C11.8382 7.2637 11.8425 7.4762 11.8431 7.6855C11.8496 9.9924 11.8528 12.2993 11.856 14.6062C11.8583 16.2921 10.8461 17.4144 9.11359 17.649C7.93599 17.8085 6.51869 16.8756 6.16679 15.6592C6.04659 15.2438 6.02949 14.7944 6.01299 14.3589C5.98849 13.7107 6.00619 13.061 6.00609 12.4119C6.00609 11.7728 6.00609 11.1338 6.00609 10.4947C6.01359 10.4947 6.02129 10.4947 6.02869 10.4947Z" fill={color || '#4F4F55'} />
    </svg>
  );
};

export default PaperClipIcon;
