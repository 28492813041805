import api, { skedApi } from '../../../../services/api.js';
import { etd } from '../../../../services/utilities.js';
import {
  groupBy, prop, evolve, map, omit, pipe, split, trim, last,
} from 'ramda';

const sparkNppPath = 'spark.0.np_alists';

export const fetchSettingsList = (type = null) =>
  api.get('settings/npp').then((npps) => {
    return npps.filter(({ nppType }) => nppType !== type).sort((a, b) => {
      return a.name > b.name;
    });
  });

export const fetchSparkPortals = () =>
  api.post('paths/query', {
    page: 1,
    perPage: 1000,
    query: {
      isDirectDescendant: sparkNppPath.split('.'),
    },
  }).then(({ data }) => {
    /* Path looks like: [ "spark", "0", "np_alists", "npp_${nppId}_alist" ]
       the nppId is in the path.
    */
    return data
      .map(({ path }) => {
        try {
          return Number(last(path).split('_')[1]);
        } catch (e) {
          console.log(e);
          return path;
        }
      })
      .filter((n) => n);
  });

export const saveImage = (id, image) => {
  const config = {
    headers: {
      'Content-Type': image.type,
    },
  };
  console.log(image);
  return skedApi.post('/settings/npp/image/' + id, image, config);
};

export const deleteImage = (id) => {
  return skedApi.delete('/settings/npp/image/' + id);
};

export const fetchClients = (params) => {
  const localParams = params;
  const pastAList = params.aListTotalPages ? false : params.page > params.aListTotalPages;
  if (pastAList && params.aListTotalPages) {
    localParams.page -= params.aListTotalPages;
  }
  return Promise.all([
    api.post('settings/npp/client/query', params),
    api.get(`paths/path/npp.npp_${params.query.newPatientPortalSettingsId}.alist`),
  ].map(etd)).then(([old, alist]) => {
    if (alist.error) {
      return old.data;
    }
    return api.post('/list/appointment/appointments/query', {
      ...params,
      query: {
        listId: alist.data.resource.AppointmentList.id,
      },
    }).then((alistData) => {
      const correctData = alistData.data.map(({ appointment, created }) => {
        return {
          client: appointment.client,
          clientId: appointment.client.id,
          time: created,
        };
      });
      let data = correctData;
      if (pastAList) {
        data = old.data.data;
      }
      if (!pastAList && alistData.data.length <= params.perPage) {
        data = data.concat(old.data.data);
      }
      console.log(Math.floor(alistData.totalCount / params.perPage));
      return {
        totalCount: alistData.totalCount + old.data.totalCount,
        aListTotalPages: alistData.totalPages,
        totalPages: Math.floor(alistData.totalCount / params.perPage) + old.data.totalPages,
        data,
      };
    });
  });
};

export const fetchSettings = (id) => {
  return Promise.all([
    api.get('appointmentType'),
    api.get('professional'),
    api.get('/settings/npp/' + id),
    api.get(`paths/path/${sparkNppPath}.npp_${id}_alist`),
  ].map(etd)).then(([apptTypes, professionals, settings, spark]) => ({
    apptTypesGroupped: groupBy(prop('professionalId'), apptTypes.data),
    apptTypes: apptTypes.data,
    professionals: professionals.data,
    settings: {
      ...settings.data,
      isSpark: Boolean(spark.data),
    },
  }));
};

export const saveSettings = async (settings) => {
  console.log('save these settings: ', settings);
  const body = evolve({
    newClient: map(omit(['appointmentType'])),
    primaryColor: s => s ? s.toUpperCase() : null,
    secondaryColor: s => s ? s.toUpperCase() : null,
    backgroundColor: s => s ? s.toUpperCase() : null,
    referrals: pipe(split('\n'), map(trim))
  }, settings);
  let saved;
  try {
    saved = await api.put('/settings/npp', body);
  } catch (e) {
    console.log(e);
    return e;
  }
  const url = `paths/path/${sparkNppPath}.npp_${saved.newPatientPortalSettingsId}_alist`;
  try {
    const inPath = await api.get(url);
    if (!settings.isSpark && inPath) {
      return await api.delete(url);
    }
  } catch (e) {
    if (settings.isSpark) {
      try {
        const alistPath = await api.get(`paths/path/npp.npp_${saved.newPatientPortalSettingsId}.alist`);
        return await api.post('paths', {
          path: `${sparkNppPath}.npp_${saved.newPatientPortalSettingsId}_alist`.split('.'),
          resource: {
            AppointmentList: alistPath.resource.AppointmentList.id,
          },
        });
      } catch (er) {
        /* If the list doesn't exist, it means no one has scheduled on this
           PP yet and therefore it hasn't been made. Therefore, we make it.
        */
        console.log(er);
        const newAList = await api.post('list/appointment', {
          name: `Patient Portal ${saved.newPatientPortalSettingsId} - Appointments`
        });
        await api.post('paths', {
          path: `npp.npp_${saved.newPatientPortalSettingsId}.alist`.split('.'),
          resource: {
            AppointmentList: newAList.id,
          },
        });
        return await api.post('paths', {
          path: `${sparkNppPath}.npp_${saved.newPatientPortalSettingsId}_alist`.split('.'),
          resource: {
            AppointmentList: newAList.id,
          },
        });
      }
    }
  }
};

export const defaultEmailBodyLandingPageDescription =
  '<p style="textAlign: left;tab: 0;">Welcome! We\'re so glad you\'re here. We look forward to meeting you!</p>';
export const defaultConfirmationDescription =
  '<p style="textAlign: left;tab: 0;">Thank you for selecting your appointment! We are excited to meet you.</p>';
export const defaultCalendarDirections =
  'Select appointment type then date and time';
export const defaultContactDirection =
  'Enter in your contact details';

export const createSettings = ({ name, nppType }) => {
  const body = {
    name,
    newClient: [],
    referrals: [],
    scheduleNewPatient: true,
    showLogo: false,
    showName: true,
    showDuration: true,
    allowSameDayScheduling: false,
    hidePro: false,
    birthdayOption: 'Optional',
    nppType,
    landingPageDescription: defaultEmailBodyLandingPageDescription,
    confirmationDescription: defaultConfirmationDescription,
    calendarDirections: defaultCalendarDirections,
    contactDirection: defaultContactDirection,
    allowInactive: false,
  };
  return api.post('/settings/npp', body).then((n) => {
    return api.post('list/appointment', {
      name: `Patient Portal ${n.newPatientPortalSettingsId} - Appointments`
    }).then(({ id }) => {
      return api.post('paths', {
        path: `npp.npp_${n.newPatientPortalSettingsId}.alist`.split('.'),
        resource: {
          AppointmentList: id,
        },
      }).then(() => {
        return n;
      });
    });
  });
};

export const deleteSettings = id => {
  return api.delete('/settings/npp/' + id);
};

export const fetchLink = (id) => {
  return api.get(`/settings/npp/link/${id}`);
};

export const linkPortal = ({ currentPortalId, linkedPortalId }) => {
  return api.post('/settings/npp/link', {
    sourcePortalId: currentPortalId,
    targetPortalId: linkedPortalId,
  });
};

export const unlinkPortal = ({ currentPortalId, linkedPortalId }) => {
  return api.delete('/settings/npp/unlink', {
    data: {
      sourcePortalId: currentPortalId,
      targetPortalId: linkedPortalId,
    },
  });
};
