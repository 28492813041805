import React from 'react';
import { useDispatch } from 'react-redux';

import { Snackbar as MuiSnackbar, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from '../../reducers';
import { WarningIcon, CheckMarkIcon, ExclamationIcon, InfoIcon } from '../../icons';

import { closeAlert } from './snackbar.actions';
import { useStyles } from './snackbar.styles';

const Snackbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const alerts = useSelector((state) => state.snackbar);

  const handleClose = (id: number) => {
    dispatch(closeAlert(id));
  };

  return (
    <section className={classes.root}>
      {alerts.map((alert, index) => (
        <MuiSnackbar
          key={`${alert.message}-${index}`}
          style={{ marginBottom: index * 48 }}
          open
          autoHideDuration={alert.timer}
        >
          <div>
            <Alert
              severity={alert.severity}
              elevation={6}
              variant="filled"
              className={alert.severity === 'success' ? classes.success : ''}
              iconMapping={{
                success: <CheckMarkIcon />,
                warning: <WarningIcon color='#4F4F55' />,
                error: <ExclamationIcon />,
                info: <InfoIcon />,
              }}
            >
              {alert.message}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                className={classes.close}
                onClick={() => handleClose(alert.id)}
              >
                <CloseIcon />
              </IconButton>
            </Alert>
          </div>
        </MuiSnackbar>
      ))}
    </section>
  );
};

export default Snackbar;
