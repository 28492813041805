import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DownloadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z" />
    </SvgIcon>
  );
};

export const DriveFileMoveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d='M20 6h-8l-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V6h5.17l1.41 1.41.59.59H20v10zm-7.84-6H8v2h4.16l-1.59 1.59L11.99 17 16 13.01 11.99 9l-1.41 1.41L12.16 12z' />
    </SvgIcon>
  );
};

export const UploadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z" />
    </SvgIcon>
  );
};

export const FolderOffIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d='M20 6h-8l-2-2H7.17l4 4H20v9.17l1.76 1.76c.15-.28.24-.59.24-.93V8c0-1.1-.9-2-2-2zM2.1 2.1.69 3.51l1.56 1.56c-.15.28-.24.59-.24.93L2 18c0 1.1.9 2 2 2h13.17l3.31 3.31 1.41-1.41L2.1 2.1zM4 18V6.83L15.17 18H4z' />
    </SvgIcon>
  );
};

export const ConditionalIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path transform="translate(3, 5)" d="M10.6,2.95H7.3c0,.043-.015.073,0,.084A3.942,3.942,0,0,1,8.2,5.861c-.026.685.02,1.372-.011,2.057a3.79,3.79,0,0,0,.84,2.528,3.588,3.588,0,0,0,1.537,1.329,4.542,4.542,0,0,0,.72-.56,2.279,2.279,0,0,1,2.858-.222,2.447,2.447,0,0,1,.793,2.642,2.317,2.317,0,0,1-3.447,1.085,2.358,2.358,0,0,1-.938-1.479.539.539,0,0,0-.259-.328,5.107,5.107,0,0,1-2.049-1.5,4.812,4.812,0,0,1-1.126-2.95c-.016-.96-.007-1.92-.013-2.88a2.427,2.427,0,0,0-.86-1.972,4.127,4.127,0,0,0-.985-.524c-.193-.083-.332-.019-.424.234a2.305,2.305,0,0,1-1.885,1.6A2.437,2.437,0,0,1,.476,3.879a2.118,2.118,0,0,1-.33-2.118A3,3,0,0,1,.9.7,2.143,2.143,0,0,1,2.784.025a2.54,2.54,0,0,1,2,1.556.369.369,0,0,0,.4.258q2.538-.014,5.077,0c.223,0,.352-.04.416-.277a1.985,1.985,0,0,1,.989-1.12A1.853,1.853,0,0,1,12.977.089,2.281,2.281,0,0,1,15,2.891a2.286,2.286,0,0,1-3.482,1.328A2.064,2.064,0,0,1,10.6,2.95" />
    </SvgIcon>
  );
};

export const PencilIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 12.792 12.767">
        <g id="Group_10717" data-name="Group 10717" transform="translate(0 0)">
          <g id="Group_7176" data-name="Group 7176" transform="translate(0 0)">
            <path id="Path_5588" data-name="Path 5588" d="M7.9,10.73l2.766,2.755a1.119,1.119,0,0,0-.172.115q-3.371,3.367-6.737,6.74a1.247,1.247,0,0,1-.632.32c-.91.221-1.817.451-2.727.671a.451.451,0,0,1-.263-.018c-.124-.052-.154-.17-.114-.347.047-.2.1-.4.151-.606.19-.763.381-1.525.568-2.289a.851.851,0,0,1,.233-.417Q4.3,14.329,7.633,11c.085-.085.167-.172.263-.271" transform="translate(0 -8.575)" fill="#474a54"/>
            <path id="Path_5589" data-name="Path 5589" d="M46.159,4.078,43.6,1.513c-.156-.157-.155-.155,0-.307.27-.266.531-.541.809-.8a1.365,1.365,0,0,1,1.953.008c.266.252.523.514.772.783a1.34,1.34,0,0,1-.012,1.937c-.292.3-.588.59-.882.885a.682.682,0,0,1-.076.057" transform="translate(-34.746 0)" fill="#474a54"/>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export const WarningIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 17.477 16.483">
        <g id="Group_16336" data-name="Group 16336" transform="translate(0 0)" opacity="0.548">
          <g id="Group_16057" data-name="Group 16057" transform="translate(0 0)">
            <path id="Path_9047" data-name="Path 9047" d="M8.742,16.482c-1.945,0-3.891,0-5.836,0a3.463,3.463,0,0,1-1.466-.272,2.51,2.51,0,0,1-1.262-3.16c.294-.677.661-1.323,1.012-1.975q1.267-2.354,2.549-4.7,1.308-2.4,2.621-4.8A2.865,2.865,0,0,1,7.692.23a2.52,2.52,0,0,1,3.23,1.023c.332.53.612,1.092.912,1.642q2.33,4.275,4.655,8.551c.282.52.577,1.037.806,1.581a2.5,2.5,0,0,1-1.314,3.206,3.081,3.081,0,0,1-1.249.244c-2,.007-3.993,0-5.99,0v0M9.768,7.757h.007c0-.758,0-1.517,0-2.275a2.727,2.727,0,0,0-.044-.526,1.013,1.013,0,0,0-1.956-.067A2.437,2.437,0,0,0,7.7,5.5c0,1.511,0,3.021,0,4.532,0,.114,0,.228.01.342a.987.987,0,0,0,.609.9,1.008,1.008,0,0,0,1.064-.138.991.991,0,0,0,.379-.824c.006-.849,0-1.7,0-2.548M8.726,14.428a1,1,0,0,0,1.038-1.015,1.026,1.026,0,1,0-1.038,1.015" transform="translate(0 0)" fill="#444f53"/>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
