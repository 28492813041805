import React from 'react';
import { useDispatch } from 'react-redux';
import { History } from 'history';
import {
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
  Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  ArrowForwardIos as ChevronRight,
  ArrowBackIosNew as ChevronLeft,
  Print as PrintIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { pathOr, pipe, is, includes, isEmpty, prepend, isNil } from 'ramda';
import Appointments from '../Appointments/Appointments.component';
import * as actions from '../Calendar/calendar.actions';
import {
  getCalendarInit, getCalendarApts,
  reskedAppointmentRemote, getCalendarAptsLoading,
  updateSettings, initListenUpdates
} from './calendar.actions';
import { useSocket } from '../../services/websocket.hook.js';

import { CalendarInternal } from './components/calendar-internal.jsx';
import { CalendarInternalWeek } from './components/calendar-internal-week.jsx';
import { Scheduler } from './components/scheduler.component.jsx';
import { LocalDate, DateTimeFormatter, ZonedDateTime, ZoneId, LocalDateTime } from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import { getDow } from './services/calendar.service.js';
import { SettingsPopUp } from './components/SettingsPopup.jsx';
import FilterFields from './components/FilterFields/FilterFields.component';
import FilterGroup from './components/FilterGroup/FilterGroup.component';
import FilterSpacing from './components/FilterSpacing/FilterSpacing.component';
import { useTitle } from '../../services/useTitle.js';
import { getLocations } from '../Settings/routes/Business/business.actions.js';

import PageHeader from '../../components/PageHeader/PageHeader.component';
import SelectProfessional from '../../components/SelectProfessional/SelectProfessional.component';
import FilterLocations from '../../components/FilterLocations/FilterLocations.component';
import CalendarIcon from '../../icons/CalendarDate.icon';
import CalendarViewIcon from '../../icons/CalendarView.icon';
import ListViewIcon from '../../icons/ListView.icon';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';
import { useSelector } from '../../reducers';
import { Appointment } from '../Appointments/appointments.types';
import { useStyles } from './calendar.styles';

const format = DateTimeFormatter.ofPattern('eeee, MMMM d, yyyy').withLocale(Locale.US);
const dayTimeFormat = DateTimeFormatter.ofPattern('eee, MMM d yyyy h:mm a').withLocale(Locale.US);

const formatAptDayTime = ({ time, timezone }: { time: string; timezone: string }) => {
  try {
    return ZonedDateTime
      .parse(time)
      .withZoneSameInstant(ZoneId.of(timezone))
      .format(dayTimeFormat);
  } catch (e) {
    return 'Error parsing date';
  }
};

const MySelect = withStyles({
  outlined: {
    padding: '10px 14px'
  }
})(Select);


const Calendar = ({ history }: { history: History }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    const locations = pathOr([], ['business', 'locations'], state);
    const hasLocations = pipe(
      pathOr([], ['login', 'features']),
      includes('Locations')
    )(state);
    return ({
      ...state.calendar,
      tz: state.login.office.timezone || 'America/New_York',
      ehrSystem: state.login.office.ehrSystem,
      timeIntervals: state.calendarv2.localInterval || pathOr(15, ['login', 'ehrSettings', 'blockLength'])(state),
      office: state.login.office,
      admin: pathOr(false, ['login', 'admin'])(state),
      locations: hasLocations && !isEmpty(locations) ?
        prepend(
          { id: 0, name: 'All Locations' },
          locations,
        ) : [],
      hasLocations,
      headerHeight: state.login.headerHeight,
    });
  });

  const calState = useSelector(({ calendarv2 }) => calendarv2);

  const [possibleAppointments, setPossibleAppointments] = React.useState([]);
  const [isSchedulerOpen, setIsSchedulerOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(LocalDate.now().toString());
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [selectedView, setSelectedView] = React.useState('day');
  const [professional, setProfessional] = React.useState(0);
  const [location, setLocation] = React.useState(0);
  const [selectedPrintView, setSelectedPrintView] = React.useState('calendar');
  const [showDay, setShowDay] = React.useState(false);
  const [state, setState] = React.useState({
    scheduleId: -1
  });

  const selectTime = (time: string | ZonedDateTime) => {
    setIsSchedulerOpen(true);
    setSelectedTime(time);
  };

  useTitle('Calendar');

  const socket = useSocket();

  React.useEffect(() => {
    const view = localStorage[`sked-calendar-view-${store.office.id}`];
    if (view) {
      setSelectedView(view);
    }
    const initProf = Number(localStorage[`sked-calendar-professional-${store.office.id}`]);
    if (initProf) {
      setProfessional(initProf || 0);
    }
  }, [store.office.id]);

  React.useEffect(() => {
    const scheduleId = Number(localStorage[`sked-calendar-scheduleId-${store.office.id}`]);
    const exists = calState.schedules.find(schedule => schedule.id === scheduleId);
    if (scheduleId && exists) {
      setState(s => ({
        ...s,
        scheduleId
      }));
    }
  }, [store.office.id, calState.schedules]);

  React.useEffect(() => {
    const cleanupSocket = dispatch(initListenUpdates(socket));
    return () => {
      cleanupSocket();
    };
  }, [dispatch, socket]);

  React.useEffect(() => {
    const view = localStorage[`sked-calendar-view-${store.office.id}`];
    if (store.tz) {
      dispatch(actions.getFilterData(true, history));
      dispatch(getCalendarInit({
        date: selectedDate,
        view: view || selectedView,
        timezone: store.tz,
        officeId: store.office.id,
        admin: store.admin
      }));
    }
    if (store.hasLocations) {
      dispatch(getLocations());
      try {
        const location = localStorage[`sked-calendar-location-${store.office.id}`];
        if (isNil(location) || isNaN(Number(location))) {
          setLocation(0);
        } else {
          setLocation(Number(location));
        }
      } catch (e) {
        console.log('error gettings calendar location');
      }
    }
  }, [dispatch, store.tz, store.office.id, store.admin]);

  const timeoutRef = React.useRef(null);
  React.useEffect(() => {
    // Debouce the button... This is done imperatively because we need to keep the
    // timeout in the state
    if (calState.init) {
      return;
    }
    const timeout = timeoutRef.current;
    dispatch(getCalendarAptsLoading());
    const later = () => {
      setState(s => ({
        ...s,
        timeout: null,
      }));
      dispatch(getCalendarApts({
        date: selectedDate,
        view: selectedView,
        timezone: store.tz
      }));
    };
    clearTimeout(timeout);
    const newTimeout = setTimeout(later, 300);
    timeoutRef.current = newTimeout;
    return () => {
      clearTimeout(timeout);
    };
  }, [selectedDate, selectedView, store.tz]);

  React.useEffect(() => {
    localStorage[`sked-calendar-location-${store.office.id}`] = location;
  }, [location]);

  React.useEffect(() => {
    localStorage[`sked-calendar-professional-${store.office.id}`] = professional;
  }, [professional]);

  React.useEffect(() => {
    localStorage[`sked-calendar-view-${store.office.id}`] = selectedView;
  }, [selectedView]);

  const refreshToday = () => {
    dispatch(getCalendarApts({ date: selectedDate, view: selectedView, timezone: store.tz }));
  };

  const selectDate = (newDate: string | LocalDateTime) => {
    if (!newDate) {
      return;
    }
    const formatedDate = is(String, newDate) ? newDate : newDate.toString();
    setSelectedDate(formatedDate);
  };

  const setDateToday = () => {
    const formatedDate = LocalDate.now().toString();
    setSelectedDate(formatedDate);
  };

  const setDateNext = () => {
    const daysToAdd = selectedView === 'day' ? 1 : 7;
    const formatedDate = LocalDate.parse(selectedDate).plusDays(daysToAdd).toString();
    setSelectedDate(formatedDate);
  };

  const setDatePrev = () => {
    const daysToAdd = selectedView === 'day' ? 1 : 7;
    const formatedDate = LocalDate.parse(selectedDate).minusDays(daysToAdd).toString();
    setSelectedDate(formatedDate);
  };

  const openAptSection = () => {
    setSelectedTime(null);
    setIsSchedulerOpen(true);
  };

  const displayDate = () => {
    if (selectedView === 'day') {
      return LocalDate.parse(selectedDate).format(format);
    }

    const dow = getDow(selectedDate);

    const start = LocalDate
      .parse(selectedDate)
      .minusDays(dow)
      .format(format);

    const end = LocalDate
      .parse(selectedDate)
      .minusDays(dow)
      .plusDays(6)
      .format(format);

    return `${start} - ${end}`;
  };

  const handleSelectSchedule = (scheduleId: number) => {
    setState(s => ({
      ...s,
      scheduleId
    }));
    localStorage[`sked-calendar-scheduleId-${store.office.id}`] = scheduleId;
  };

  const onError = () => null as string;

  const [openSettings, setOpenSettings] = React.useState(false);

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  const handleSaveSettings = (settingsForm: object) => {
    setOpenSettings(false);
    dispatch(updateSettings({ settings: settingsForm, officeId: store.office.id, admin: store.admin }));
  };

  const handleSaveNameDisplay = (nameDisplay: string) => {
    dispatch(updateSettings({ settings: { nameDisplay }, officeId: store.office.id, admin: store.admin }));
  };

  const handleReset = () => {
    setDateToday();
    handleSaveNameDisplay('abbr_l');
    handleSelectSchedule(-1);
    setProfessional(0);
    handleSaveSettings({
      groupByProfessional: false,
      cellHeight: 35,
      cellWidth: 100,
      localInterval: 15,
    });
    const moreButton = document.getElementById('more-header-button');
    moreButton?.click();
  };

  React.useEffect(() => {
    if (selectedView === 'day') {
      setTimeout(() => {
        setShowDay(true);
      }, 200);
    } else {
      setShowDay(false);
    }
  }, [selectedView]);

  return (
    <>
      <PageHeader
        title='Calendar'
        pageId='calendar-v2'
        helpMaxWidth={1035}
        leftIcons={[
          <FilterSelect
            items={['Calendar', 'List']}
            title={selectedPrintView === 'calendar' ? 'Calendar' : 'List'}
            setSelected={(value) => setSelectedPrintView(value === 'Calendar' ? 'calendar' : 'print')}
            selected={selectedPrintView === 'calendar' ? 'Calendar' : 'List'}
            Icon={selectedPrintView === 'calendar' ? CalendarViewIcon : ListViewIcon}
            horizontalMenu='left'
            simpleList
            neverOnlyIcon
            minWidth={200}
            noneText={selectedPrintView === 'calendar' ? 'Calendar' : 'List'}
            className='sked-test-calendar-view'
            nameForTest='calendar-view'
          />,
          <HeaderButton
            title='Today'
            onClick={setDateToday}
            borderSolid
            className='sked-test-calendar-today-button'
          />,
          <HeaderButton Icon={ChevronLeft} onClick={setDatePrev} onlyIcon className='sked-test-calendar-day-previous-arrow' />,
          <TextField
            className={classes.headerDate}
            value={LocalDate.parse(selectedDate)}
            onChange={(event) => selectDate(event.target.value)}
            type='date'
            size="small"
            variant="outlined"
            onError={onError}
            inputProps={{ className: 'sked-test-calendar-date-picker' }}
          />,
          <HeaderButton Icon={ChevronRight} onClick={setDateNext} onlyIcon className='sked-test-calendar-day-next-arrow' />,
          <FormControl variant="outlined" size="small" style={{ marginRight: 8 }}>
            <MySelect
              labelId="view-select-label"
              id="view-select"
              size='small'
              value={selectedView}
              className={classes.select}
              inputProps={{ className: 'sked-test-calendar-select-view' }}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedView(e.target.value as string)}>
              <MenuItem className='sked-test-calendar-select-view-day' value={'day'}>Day</MenuItem>
              <MenuItem className='sked-test-calendar-select-view-week' value={'week'}>Week</MenuItem>
            </MySelect>
          </FormControl>,
          <FilterFields
            nameDisplay={calState.nameDisplay}
            setNameDisplay={handleSaveNameDisplay}
          />,
          <FilterSelect
            title='Provider Schedule'
            Icon={CalendarIcon}
            noneText='All Schedules'
            horizontalMenu='left'
            items={calState.schedules?.map(item => item.name)}
            selected={calState.schedules?.find(item => item.id === state.scheduleId)?.name}
            setSelected={(value) => {
              const id = calState.schedules?.find(item => item.name === value)?.id;
              handleSelectSchedule(id);
            }}
            className='sked-test-calendar-provider-schedule'
            saveClassName='sked-test-calendar-provider-schedule-save-button'
            resetClassName='sked-test-calendar-provider-schedule-reset-button'
            nameForTest='calendar-provider-schedule'
          />,
          <SelectProfessional
            allProfessionals={store.professionals}
            selectedProId={professional}
            setPropId={setProfessional}
            className='sked-test-calendar-professional'
            saveClassName='sked-test-calendar-professional-save-button'
            resetClassName='sked-test-calendar-professional-reset-button'
          />,
          <FilterLocations
            hasLocations={store.hasLocations}
            selectedLocationId={location}
            allLocations={store.locations}
            setSelectedLocationId={setLocation}
            className='sked-test-calendar-location'
            saveClassName='sked-test-calendar-location-save-button'
            resetClassName='sked-test-calendar-location-reset-button'
          />,
          <FilterGroup
            isGroupBy={!!calState.groupByProfessional}
            setIsGroupBy={(value) => handleSaveSettings({ groupByProfessional: value })}
          />,
          <FilterSpacing
            value={{
              cellWidth: calState.cellWidth,
              cellHeight: calState.cellHeight,
              localInterval: calState.localInterval || 15
            }}
            save={handleSaveSettings}
          />
        ]}
        rightIcons={[
          calState.loading ? <CircularProgress size={18} /> : <div />,
          selectedPrintView === 'calendar' ? <div /> : <HeaderButton Icon={PrintIcon} onClick={() => window.print()} onlyIcon />,
          <HeaderButton title='Add Appt' onClick={openAptSection} Icon={AddIcon} className='sked-test-calendar-scheduler' />,
          <Grid marginLeft="5px">
            <HeaderButton
              onClick={handleReset}
              title='Reset Filters'
              borderSolid
            />
          </Grid>
        ]}
        onlyIconsWidth={1570}
        breakPoints={[
          {
            width: 1030,
            mobileItems: [15],
          },
          {
            width: 956,
            mobileItems: [8, 9, 10, 11, 15],
          },
          {
            width: 855,
            mobileItems: [6, 7, 8, 9, 10, 11, 15],
          },
          {
            width: 770,
            mobileItems: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15],
          },
          {
            width: 542,
            mobileItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 15],
          },
        ]}
      />
      <div className={classes.root}>
        <div className={classes.columnContainer}>
          <div
            className={selectedPrintView === 'print' ? classes.scroll : ''}
            style={{
              width: isSchedulerOpen ? '50%' : '100%',
              maxHeight: selectedPrintView === 'print' ? `calc(100vh - ${store.headerHeight + 45}px)` : '100%',
            }}>
            <div style={{ width: '100%', height: 10 }} />
            {selectedPrintView === 'print' &&
              <div className={classes.pageHeader + ' ' + classes.dateHeader}>
                Appointments from {displayDate()}
              </div>
            }
            {showDay && selectedView === 'day' && selectedPrintView === 'calendar' &&
              <CalendarInternal
                viewAppointment={(appt: Appointment) => dispatch(actions.selectAppointment(appt))}
                reskedAppointment={(appt: Appointment) => dispatch(reskedAppointmentRemote(appt))}
                markAppointment={(appt: Appointment, status: string) => dispatch(actions.changeAppointmentStatus(appt, status))}
                skedAppointment={selectTime}
                possibleAppointments={isSchedulerOpen && possibleAppointments.length > 0 ? possibleAppointments : []}
                state={{
                  ...state,
                  ...calState
                }}
                selectedProfessional={professional}
                selectedLocation={location}
                selectedDate={selectedDate}
                store={store}
              />
            }
            {selectedView === 'week' && selectedPrintView === 'calendar' &&
              <CalendarInternalWeek
                viewAppointment={(appt: Appointment) => dispatch(actions.selectAppointment(appt))}
                reskedAppointment={(appt: Appointment) => dispatch(reskedAppointmentRemote(appt))}
                markAppointment={(appt: Appointment, status: string) => dispatch(actions.changeAppointmentStatus(appt, status))}
                skedAppointment={selectTime}
                selectedProfessional={professional}
                selectedLocation={location}
                possibleAppointments={isSchedulerOpen && possibleAppointments.length > 0 ? possibleAppointments : []}
                state={{
                  ...state,
                  ...calState
                }}
                selectedDate={selectedDate}
                store={store} />
            }

            {selectedPrintView === 'print' &&
              <div style={{ marginLeft: '20px', paddingBottom: '20px' }}>
                {calState.appointments.map(a => (
                  <div key={a.id} style={{ marginBottom: '20px', breakInside: 'avoid' }}>
                    <div>
                      {formatAptDayTime({ time: a.time, timezone: store.tz })}
                    </div>
                    <div>
                      {a.client.firstName} {a.client.lastName}
                    </div>
                    <div>
                      {a.appointmentType.name} {a.appointmentType.duration}m
                    </div>
                    <div>
                      {a.status.Scheduled && 'Scheduled'}
                      {a.status.Arrived && 'Arrived'}
                      {a.status.Missed && 'Missed'}
                      {a.status.Preview && 'Preview'}
                    </div>
                  </div>
                ))}
              </div>
            }

          </div>
          {isSchedulerOpen &&
            <Scheduler
              isOpen={isSchedulerOpen}
              refreshToday={refreshToday}
              setPossibleAppointments={setPossibleAppointments}
              timezone={store.tz}
              interval={store.timeIntervals}
              selectDate={selectDate}
              types={store.types}
              professionals={store.professionals}
              selectedTime={selectedTime}
              close={() => setIsSchedulerOpen(false)}
              ehrSystem={store.ehrSystem}
            />}
        </div>

        <Appointments
          open={store.state === 'SKED_APT'}
          appointment={store.selectedAppointment}
          tz={store.tz}
          from={'SKED'}
          history={history}
          busy={store.aptBusy}
          onClose={() => dispatch(actions.backToSked())}
          gotoClient={(c) => dispatch(actions.selectClientDialog(c))}
          cancel={() => dispatch(actions.cancelAppointment(store.selectedAppointment))}
          back={() => dispatch(actions.back())}
          proTypes={store.proTypes}
          resked={(appt) => dispatch(actions.reskedAppointment(appt))}
        />

        {openSettings && <SettingsPopUp
          openSettings={openSettings}
          handleCloseSettings={handleCloseSettings}
          handleSaveSettings={handleSaveSettings}
          defaultForm={calState} />}
      </div>
    </>
  );
};

export default Calendar;
