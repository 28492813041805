import { AnyAction } from 'redux';
import {
  merge, lens, assoc, prop, set, over, append, evolve, view, compose,
  lensProp, lensIndex, insert, pipe, update, remove, inc, dec, map,
  filter, isEmpty, last, reverse, take, ifElse, has, concat, slice,
} from 'ramda';
import * as at from './intake-forms.actionTypes';
import {
  IntakeForm, FormChild, SectionChild, ComplexWidget, Section,
  ComplexWidgetType, SimpleWidget, SimpleWidgetType, defaultPictureUrl,
} from './intake-types';
import { Form, Conditional, Target } from './ServerTypes';
import {
  findMaxId, createNewFormChild, IntakeFormEditHistory, emptyIntakeForm,
  findSimpleWidgetPath, reindexQuestions, getIndex,
} from './intake.context';
import {
  revisionToForm,
} from './intake.service';

type FormsReducer = {
  busy: boolean;
  revision: IntakeForm,
  form: Form,
  previewId: number;
  canPreview: boolean;
  editHistoryValue: IntakeFormEditHistory,
  savedOn: number;
  errorMessage: string;
  maxIndex: number;
  newChild: string;
}

const init: FormsReducer = {
  busy: false,
  revision: emptyIntakeForm,
  form: null,
  previewId: null,
  canPreview: false,
  editHistoryValue: {
    current: emptyIntakeForm,
    currentIndex: 0,
    history: [emptyIntakeForm]
  },
  savedOn: 0,
  errorMessage: null,
  maxIndex: 0,
  newChild: null,
};

const flens = lens(prop('form'), assoc('form'));
const rlens = lens(prop('revision'), assoc('revision'));
const ulens = lens(prop('editHistoryValue'), assoc('editHistoryValue'));

export default (forms = init, action: AnyAction): FormsReducer => {
  switch (action.type) {
    case at.FORMS_PATCH: {
      return merge(forms, {
        ...action.data,
      });
    }
    case at.FORMS_REMOTE_GET_LATEST_REVISION: {
      if (action.state === 'REQUEST') {
        return merge(forms, {
          busy: true,
        });
      }
      if (action.state === 'RESPONSE') {
        if (action.data.revisionId) {
          const revision = revisionToForm(action.data);
          const newone = merge(forms, {
            revision,
            previewId: revision.id,
            busy: false,
            maxIndex: revision.children.length - 1,
          });
          return over(ulens, evolve({
            history: () => [newone.revision],
          }),
          newone
          ) as FormsReducer;
        }
        return merge(forms, {
          revision: action.data,
          busy: false,
        });
      }
      if (action.state === 'ERROR') {
        return merge(forms, {
          busy: false,
          errorMessage: action.data.message,
        });
      }
      return forms;
    }
    case at.FORMS_REMOTE_GET_FORM: {
      if (action.state === 'REQUEST') {
        return merge(forms, {
          busy: true,
        });
      }
      if (action.state === 'RESPONSE') {
        return merge(forms, {
          form: action.data,
          busy: false,
        });
      }
      if (action.state === 'ERROR') {
        return merge(forms, {
          busy: false,
        });
      }
      return forms;
    }
    case at.FORMS_EDIT_REVISION: {
      const newone = merge(forms, {
        revision: {
          ...forms.revision,
          ...action.data,
        },
      });
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone
      ) as FormsReducer;
    }
    case at.FORMS_EDIT_REVISION_WITHOUT_UNDO: {
      return merge(forms, {
        revision: {
          ...forms.revision,
          ...action.data,
        },
      });
    }
    case at.FORMS_REMOTE_PUT_FORM: {
      if (action.state === 'REQUEST') {
        return merge(forms, {
          busy: true,
        });
      }
      if (action.state === 'RESPONSE') {
        const {
          name, description, sendNotification
        } = action.data;
        return merge(forms, {
          revision: {
            ...forms.revision,
            name,
            description,
            sendNotification,
          },
          form: {
            ...forms.form,
            sendNotification: sendNotification !== forms.form.sendNotification ?
              sendNotification : forms.form.sendNotification,
          },
          editForm: false,
          busy: false,
        });
      }
      if (action.state === 'ERROR') {
        return merge(forms, {
          busy: false,
        });
      }
      return forms;
    }
    case at.FORMS_APPEND_FORM_CHILD: {
      const newId = findMaxId(forms.revision) + 1;
      const newFormChild = createNewFormChild(newId, action.data.type);
      const newone = over(
        rlens,
        ifElse(
          has('children'),
          evolve({
            children: append<FormChild>(newFormChild)
          }),
          assoc('children', [newFormChild])
        ),
        forms
      ) as unknown as FormsReducer;
      const newone1 = over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone
      ) as FormsReducer;
      return merge(newone1, {
        newChild: `${action.data.type}-${newId}`,
        maxIndex: newone1.maxIndex + 1,
      });
    }
    case at.FORMS_SAVED: {
      return over(
        flens,
        evolve({
          name: () => action.data.name,
          description: () => action.data.description,
        }),
        forms) as unknown as FormsReducer;
    }
    case at.FORMS_REMOTE_PUT_SAVE: {
      if (action.state === 'REQUEST') {
        return merge(forms, {
          busy: true,
        });
      }
      if (action.state === 'RESPONSE') {
        const { revisionId } = action.data;
        return merge(forms, {
          revision: {
            ...forms.revision,
            id: revisionId,
          },
          savedOn: forms.editHistoryValue.currentIndex,
          previewId: revisionId,
          editForm: false,
          busy: false
        });
      }
      if (action.state === 'ERROR') {
        return merge(forms, {
          busy: false,
        });
      }
      return forms;
    }
    case at.FORMS_EDIT_FORM_CHILD: {
      const child = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const fci = form.children.findIndex(fc => fc.id === child.id);
      const l = compose(
        lensProp<IntakeForm>('children'),
        lensIndex<FormChild>(fci)
      );
      const newone = over(rlens, set(l, child), forms) as FormsReducer;
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone
      ) as FormsReducer;
    }
    case at.FORMS_APPEND_SECTION_CHILD: {
      const { sectionId, type, questionType } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const fci = form.children.findIndex(fc => fc.id === sectionId);

      const l = compose(
        lensProp<IntakeForm>('children'),
        lensIndex<FormChild>(fci),
        lensProp<Section>('children')
      );
      let child: SectionChild;
      if (type === 'SimpleWidget') {
        const id = findMaxId(form) + 1;
        child = {
          id,
          type: 'SimpleWidget',
          label: '',
          questionType: {
            type: questionType,
          } as SimpleWidgetType,
          required: false,
          forOffice: false,
          width: 12,
          isEdit: true,
        };
      }
      let section = form.children[fci];
      let questionIndex = 0;
      const upperFormChildren: FormChild[] = reverse(take(fci + 1, form.children));
      /* Find the a previous section that has questions so we can get the question index */
      upperFormChildren.every((sect: FormChild) => {
        if (sect.type === 'Section') {
          const hasQuestion = sect.children
            .find(({ type }: SectionChild) =>
              type === 'SimpleWidget' || type === 'ComplexWidget');
          if (hasQuestion) {
            section = sect;
            return false;
          }
          return true;
        }
        return true;
      });
      /* Get the question index so we can reindex everything under this section */
      if (section.type === 'Section') {
        const question = last(section.children
          .filter(({ type }) => {
            return type === 'SimpleWidget' || type === 'ComplexWidget';
          }));
        questionIndex = getIndex(form.children, 'question', question?.id || 0);
      }
      const conds = reindexQuestions({
        conds: form.conditionals,
        questionIndex,
        amount: 1,
        type: 'consecutive'
      });

      const children: SectionChild[] = view(l, form) as SectionChild[];
      const newList = append(child, children);
      const newone = over(rlens, set(l, newList), forms) as FormsReducer;
      const newone1 = over(rlens, evolve({
        conditionals: () => conds,
      }), newone) as FormsReducer;
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_APPEND_SECTION_CHILD_WITH_QUESTION: {
      const { sectionId, questionIndex, data } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const fci = form.children.findIndex(fc => fc.id === sectionId);

      const conds = reindexQuestions({
        conds: form.conditionals,
        questionIndex,
        amount: 1,
        type: 'consecutive'
      });

      const l = compose(
        lensProp<IntakeForm>('children'),
        lensIndex<FormChild>(fci),
        lensProp<Section>('children')
      );

      const maxId = findMaxId(form);

      console.log('new widget', { ...data, id: maxId });

      //Tyepscript is being dumb so I have to do this in 3 steps intead
      //of user over
      const children: SectionChild[] = view(l, form) as SectionChild[];
      const newList = append({
        ...data,
        id: maxId,
      }, children);
      const newone = over(rlens, set(l, newList), forms) as FormsReducer;
      const newone1 = over(rlens, evolve({
        conditionals: () => conds,
      }), newone) as FormsReducer;
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_APPEND_COMPLEX_WIDGET: {
      const { sectionId, type } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const fci = form.children.findIndex(fc => fc.id === sectionId);

      const l = compose(
        lensProp<IntakeForm>('children'),
        lensIndex<FormChild>(fci),
        lensProp<Section>('children')
      );

      let cType: ComplexWidgetType;
      if (type === 'RadioGrid') {
        cType = {
          type,
          columnLabels: [],
          rowLabels: [],
        };
      } else if (type === 'FlexibleTable') {
        cType = {
          type,
          inputs: [],
          defaultRowCount: 4
        };
      } else if (type === 'FixedTable') {
        cType = {
          type,
          rows: {
            header: '',
            labels: []
          },
          inputs: []
        };
      } else if (type === 'FileUpload') {
        cType = {
          type,
          name: '',
          description: '',
        };
      } else if (type === 'Signature') {
        cType = {
          type
        };
      } else if (type === 'ConsentPapers') {
        cType = {
          type,
          name: 'Consent Form',
          body: '',
        };
      } else if (type === 'DrawablePicture') {
        cType = {
          type,
          pictureUrl: defaultPictureUrl,
          options: []
        };
      }

      const complexWidget: ComplexWidget = {
        type: 'ComplexWidget',
        width: 12,
        id: findMaxId(form) + 1,
        typeData: cType,
        forOffice: false,
      };

      const section = form.children[fci];
      let questionIndex = 0;
      if (section.type === 'Section') {
        const question = last(section.children
          .filter(({ type }) => {
            return type === 'SimpleWidget' || type === 'ComplexWidget';
          }));
        questionIndex = getIndex(form.children, 'question', question?.id || 0);
      }
      const conds = reindexQuestions({
        conds: form.conditionals,
        questionIndex,
        amount: 1,
        type: 'consecutive'
      });

      const children: SectionChild[] = view(l, form) as SectionChild[];
      const newList = append(complexWidget, children);
      const newone = over(rlens, set(l, newList), forms) as FormsReducer;
      const newone1 = over(rlens, evolve({
        conditionals: () => conds,
      }), newone) as FormsReducer;
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_ADD_CHILD_AFTER: {
      const { type, id, sectionIndex, data } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      let maxId = findMaxId(form);
      const sectionWithProperIds = data?.children ? evolve({
        children: map((s: SectionChild) => {
          maxId = maxId + 1;
          return {
            ...s,
            id: maxId,
          };
        }),
      }, data) : data;
      const newFormChild = sectionWithProperIds ?
        {
          ...sectionWithProperIds,
          id: maxId + 1,
        }
        :
        createNewFormChild(maxId + 1, type);
      const fci = form.children.findIndex(fc => fc.id === id);
      let conds = form.conditionals;
      if (data?.children) {
        const aboveSection = reverse(slice(0, fci + 1, form.children)).find((o) => {
          if (o.type === 'Section') {
            return o.children[0];
          }
        });
        if (aboveSection.type === 'Section') {
          const lastQuestion = last(aboveSection.children);
          const questionIndex = getIndex(form.children, 'question', lastQuestion.id);
          conds = reindexQuestions({
            conds,
            questionIndex,
            amount: data.children.length,
            type: 'consecutive',
          });
        }
      }
      const newone = over(
        rlens,
        evolve({
          children: insert<FormChild>(fci + 1, newFormChild),
          conditionals: () => map((cond: Conditional) => {
            if (!sectionIndex) {
              return cond;
            }
            return evolve({
              target: map((t: Target) => {
                if (type === 'Section' && t.Section > sectionIndex) {
                  return {
                    Section: t.Section + 1,
                  };
                } else if (type === 'PageBreak' && t.Page > sectionIndex) {
                  return {
                    Page: t.Page + 1,
                  };
                }
                return t;
              }),
            }, cond);
          })(conds),
        }),
        forms) as FormsReducer;
      const newone1 = merge(newone, {
        maxIndex: forms.maxIndex + 1,
        newChild: `${action.data.type}-${newFormChild.id}`,
        busy: false,
      });
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_ADD_CHILD_BEFORE: {
      const { type, id, sectionIndex, data } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      let maxId = findMaxId(form);
      const sectionWithProperIds = data.children ? evolve({
        children: map((s: SectionChild) => {
          maxId = maxId + 1;
          return {
            ...s,
            id: maxId,
          };
        }),
      }, data) : data;
      const newFormChild = sectionWithProperIds ?
        {
          ...sectionWithProperIds,
          id: maxId + 1,
        }
        :
        createNewFormChild(maxId + 1, type);
      const fci = form.children.findIndex(fc => fc.id === id);
      let conds = form.conditionals;
      if (data?.children) {
        const belowSection = slice(0, fci + 1, form.children).find((o) => {
          if (o.type === 'Section') {
            return o.children[0];
          }
        });
        if (belowSection.type === 'Section') {
          const firstQuestion = belowSection.children[0];
          const questionIndex = getIndex(form.children, 'question', firstQuestion.id);
          conds = reindexQuestions({
            conds,
            questionIndex,
            amount: data.children.length,
            type: 'consecutive',
          });
        }
      }
      const newone = over(
        rlens,
        evolve({
          children: insert<FormChild>(fci, newFormChild),
          conditionals: () => map((cond: Conditional) => {
            if (!sectionIndex) {
              return cond;
            }
            return evolve({
              target: map((t: Target) => {
                if (type === 'Section' && t.Section > sectionIndex) {
                  return {
                    Section: t.Section + 1,
                  };
                } else if (type === 'PageBreak' && t.Page > sectionIndex) {
                  return {
                    Page: t.Page + 1,
                  };
                }
                return t;
              }),
            }, cond);
          })(conds),
        }),
        forms) as FormsReducer;
      const newone1 = merge(newone, {
        maxIndex: forms.maxIndex + 1,
        newChild: `${action.data.type}-${newFormChild.id}`,
        busy: false,
      });
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_SWAP_FORM_CHILD: {
      const { formChildId, amount } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const fci = form.children.findIndex(fc => fc.id === formChildId);
      const nextFci = fci + amount;
      const currentFc = form.children[fci];
      const nextFc = form.children[nextFci];
      let conds = form.conditionals;
      if (currentFc.type === 'Section' && nextFc.type === 'Section') {
        /* The number of questions the sections help us adjust the indices easily */
        const currentLength = currentFc.children.filter(({ type }) => type === 'SimpleWidget' || type === 'ComplexWidget').length;
        const nextLength = nextFc.children.filter(({ type }) => type === 'SimpleWidget' || type === 'ComplexWidget').length;
        /* The first question of the current sections gives us a reference
       in the conditional question indices so that we can just use
       numbers to figure out the new indices.

       In short, the algorthim can be summarized:
       - The questions in the section going _up_ are equal to their
         current question index _minus_ the length of the section above
         it.
       - The questions in the section going _down_ are equal to their
         current question index _plus_ the length of the section below
         it.
    */
        const startingQuestion = currentFc.children
          .find(({ type }) => {
            return type === 'SimpleWidget' || type === 'ComplexWidget';
          });
        const csIndex = getIndex(form.children, 'question', startingQuestion?.id || 0);
        const ceIndex = csIndex + currentLength - 1;
        const ceInc = amount > 0 ? nextLength : -1 * nextLength;
        const nsIndex = amount > 0 ? ceIndex + 1 : csIndex - nextLength;
        const neIndex = nsIndex + nextLength - 1;
        const neInc = amount > 0 ? -1 * currentLength : currentLength;
        const sectionIndex = getIndex(form.children, 'section', formChildId);
        const nextSectionIndex = sectionIndex + amount;

        conds = form.conditionals.map((cond: Conditional) => {
          const c: Conditional = evolve({
            questionIndex: (qi) => {
              console.log(qi, ' current: ', csIndex, ceIndex, neInc, ' next: ', nsIndex, neIndex, ceInc);
              if (qi >= csIndex && qi <= ceIndex) {
                return qi + ceInc;
              } else if (qi >= nsIndex && qi <= neIndex) {
                return qi + neInc;
              }
              return qi;
            },
            target: map((target: Target) => {
              if (target.Section) {
                if (target.Section === sectionIndex) {
                  return {
                    Section: nextSectionIndex,
                  };
                } else if (target.Section === nextSectionIndex) {
                  return {
                    Section: sectionIndex,
                  };
                }
                return target;
              }
              if (target.Question >= csIndex && target.Question <= ceIndex) {
                return {
                  Question: target.Question + ceInc,
                };
              } else if (target.Question >= nsIndex && target.Question <= neIndex) {
                return {
                  Question: target.Question + neInc,
                };
              }
              return target;
            }),
          }, cond);
          return c;
        });
      } else if (currentFc.type === 'PageBreak' && nextFc.type === 'PageBreak') {
        const pageIndex = getIndex(form.children, 'page', formChildId);
        const nextPageIndex = pageIndex + amount;
        conds = conds.map((cond) => {
          return evolve({
            target: map((t: Target) => {
              if (t.Page === pageIndex) {
                return {
                  Page: nextPageIndex,
                };
              } else if (t.Page === nextPageIndex) {
                return {
                  Page: pageIndex,
                };
              }
              return t;
            }),
          }, cond);
        });
      }
      const newone = over(
        rlens,
        evolve({
          children: pipe(
            update<FormChild>(fci, nextFc),
            update<FormChild>(nextFci, currentFc),
          ),
          conditionals: () => conds,
        }),
        forms) as FormsReducer;
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone
      ) as FormsReducer;
    }
    case at.FORMS_DELETE_FORM_CHILD: {
      const { formChildId, type, index } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const fci = form.children.findIndex(fc => fc.id === formChildId);
      if (type === 'Section') {
        const section = form.children[fci];
        if (section.type !== 'Section') {
          return set(rlens,
            evolve({ children: remove<FormChild>(fci, 1) }),
            forms) as FormsReducer;
        }
        const questions = section.children
          .filter(({ type }) => {
            return type === 'SimpleWidget' || type === 'ComplexWidget';
          });
        const firstIndex = getIndex(form.children, 'question', questions[0]?.id || 0);
        const lastIndex = firstIndex + questions.length;
        const conds = pipe(
          map((cond: Conditional) => {
            return {
              ...cond,
              target: cond.target.filter((t: Target) => {
                if (t.Section) {
                  return t.Section !== index;
                }
                return !(t.Question >= firstIndex && t.Question <= lastIndex);
              }).map((t: Target) => {
                if (t.Section && t.Section > index) {
                  return {
                    Section: t.Section - 1,
                  };
                }
                return t;
              }),
            };
          }),
          filter(({ target, questionIndex }: Conditional) => {
            if (questionIndex >= firstIndex && questionIndex <= lastIndex) {
              return false;
            }
            return !isEmpty(target);
          })
        )(form.conditionals);
        const indexed = reindexQuestions({
          conds,
          questionIndex: lastIndex,
          amount: firstIndex - lastIndex,
          type: 'consecutive',
        });
        const newone = over(
          rlens,
          evolve({
            children: remove<FormChild>(fci, 1),
            conditionals: () => indexed,
          }),
          forms) as FormsReducer;
        const newone1 = merge(newone, { maxIndex: forms.maxIndex - 1 });
        return over(ulens, evolve({
          history: append(newone.revision),
          currentIndex: inc,
        }),
        newone1
        ) as FormsReducer;
      }
      const newone = over(
        rlens,
        evolve({
          children: remove<FormChild>(fci, 1),
          conditionals: pipe(
            map((cond: Conditional) => {
              return {
                ...cond,
                target: cond.target.filter((t: Target) => {
                  return t[type as keyof Target] !== index;
                }),
              };
            }),
            filter(({ target }: Conditional) => {
              return !isEmpty(target);
            })
          ),
        }),
        forms) as FormsReducer;
      const newone1 = merge(newone, { maxIndex: forms.maxIndex - 1 });
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_UNDO: {
      const { currentIndex, history } = forms.editHistoryValue;
      return evolve({
        revision: () => history[currentIndex - 1],
        editHistoryValue: evolve({
          currentIndex: dec,
        }),
      }, forms) as FormsReducer;
    }
    case at.FORMS_REDO: {
      const { currentIndex, history } = forms.editHistoryValue;
      return evolve({
        revision: () => history[currentIndex + 1],
        editHistoryValue: evolve({
          currentIndex: inc,
        }),
      }, forms) as FormsReducer;
    }
    case at.FORMS_UPDATE_SIMPLE_WIDGET: {
      const widget = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const path = findSimpleWidgetPath(form, widget.id);
      if (!path) {
        return forms;
      }
      const [fci, sci] = path;
      const l = compose(
        lensProp<IntakeForm>('children'),
        lensIndex<FormChild>(fci),
        lensProp<Section>('children'),
        lensIndex<SectionChild>(sci)
      );
      const newone = over(rlens, set(l, widget), forms) as FormsReducer;
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone
      ) as FormsReducer;
    }
    case at.FORMS_ADD_SIMPLE_WIDGET_AFTER: {
      const { widgetId, questionIndex, data } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const path = findSimpleWidgetPath(form, widgetId);
      if (!path) {
        return forms;
      }
      const conds = reindexQuestions({
        conds: form.conditionals,
        questionIndex,
        amount: 1,
        type: 'consecutive'
      });

      const [fci, sci] = path;

      const l = compose(
        lensProp<IntakeForm>('children'),
        lensIndex<FormChild>(fci),
        lensProp<Section>('children')
      );

      const maxId = findMaxId(form);

      const widget: SimpleWidget = data ? {
        ...data,
        id: maxId + 1
      } : {
        id: maxId + 1,
        type: 'SimpleWidget',
        label: '',
        questionType: { type: 'ShortText' },
        required: false,
        forOffice: false,
        width: 12,
        isEdit: true,
      };

      console.log('new widget', data, widget);

      //Tyepscript is being dumb so I have to do this in 3 steps intead
      //of user over
      const children: SectionChild[] = view(l, form) as SectionChild[];
      const newList = insert(sci + 1, widget, children);
      const newone = over(rlens, set(l, newList), forms) as FormsReducer;
      const newone1 = over(rlens, evolve({
        conditionals: () => conds,
      }), newone) as FormsReducer;
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_ADD_SIMPLE_WIDGET_BEFORE: {
      const { widgetId, questionIndex, data } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const path = findSimpleWidgetPath(form, widgetId);
      if (!path) {
        return forms;
      }
      const conds = reindexQuestions({
        conds: form.conditionals,
        questionIndex,
        amount: 1,
        type: 'consecutive'
      });

      const [fci, sci] = path;

      const l = compose(
        lensProp<IntakeForm>('children'),
        lensIndex<FormChild>(fci),
        lensProp<Section>('children')
      );

      const maxId = findMaxId(form);

      const widget: SimpleWidget = data ? {
        ...data,
        id: maxId + 1
      } : {
        id: maxId + 1,
        type: 'SimpleWidget',
        label: '',
        questionType: { type: 'ShortText' },
        required: false,
        forOffice: false,
        width: 12,
        isEdit: true,
      };

      console.log('new widget', data, widget);

      //Tyepscript is being dumb so I have to do this in 3 steps intead
      //of user over
      const children: SectionChild[] = view(l, form) as SectionChild[];
      const newList = insert(sci, widget, children);
      const newone = over(rlens, set(l, newList), forms) as FormsReducer;
      const newone1 = over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone
      ) as FormsReducer;
      return merge(newone1, {
        conditionals: conds,
      });
    }
    case at.FORMS_DELETE_SECTION_CHILD: {
      const { id, questionIndex } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const path = findSimpleWidgetPath(form, id);
      if (!path) {
        return forms;
      }

      const [fci, sci] = path;

      const l = compose(
        lensProp<IntakeForm>('children'),
        lensIndex<FormChild>(fci),
        lensProp<Section>('children')
      );

      const conds = reindexQuestions({
        conds: form.conditionals.filter((cond) => {
          return cond.questionIndex !== questionIndex;
        }),
        questionIndex,
        amount: -1,
        type: 'consecutive',
      });

      //Tyepscript is being dumb so I have to do this in 3 steps
      //instead of using an over
      const children: SectionChild[] = view(l, form) as SectionChild[];
      const newList = remove(sci, 1, children);
      const newone = over(rlens, set(l, newList), forms) as FormsReducer;
      const newone1 = over(rlens, evolve({
        conditionals: () => conds,
      }), newone) as FormsReducer;
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_SWAP_SECTION_CHILD: {
      const { sectionId, amount, questionIndex } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const path = findSimpleWidgetPath(form, sectionId);
      if (!path) {
        return forms;
      }

      const [fci, sci] = path;

      const l = compose(
        lensProp<IntakeForm>('children'),
        lensIndex<FormChild>(fci),
        lensProp<Section>('children')
      );

      //Tyepscript is being dumb so I have to do this in 3 steps intead
      //of user over
      const children: SectionChild[] = view(l, form) as SectionChild[];
      const nextSci = sci + amount;
      const current = children[sci];
      const next = children[nextSci];
      const isQuestion = next.type === 'SimpleWidget' || next.type === 'ComplexWidget';
      const conds = reindexQuestions({
        conds: form.conditionals,
        questionIndex,
        amount,
        type: 'swap',
        isQuestion
      });
      const newList = pipe(
        update<SectionChild>(sci, next),
        update<SectionChild>(nextSci, current),
      )(children);
      const newone = over(rlens, set(l, newList), forms) as FormsReducer;
      const newone1 = over(rlens, evolve({
        conditionals: () => conds,
      }), newone) as FormsReducer;
      return over(ulens, evolve({
        history: append(newone.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_SET_CONDITIONAL: {
      const { cond, ind } = action.data;
      let newCond = cond;
      if (cond.revId === 0) {
        newCond = {
          ...newCond,
          revId: forms.revision.id,
        };
      }
      const newone1 = over(
        rlens,
        evolve({
          conditionals: (c) => ind === -1 ?
            append(newCond, c) :
            update(ind, newCond, c),
        }), forms) as FormsReducer;
      return over(ulens, evolve({
        history: append(forms.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_REMOVE_CONDITIONAL: {
      const newone1 = over(
        rlens,
        evolve({
          conditionals: remove(action.data, 1),
        }),
        forms
      ) as FormsReducer;
      return over(ulens, evolve({
        history: append(forms.revision),
        currentIndex: inc,
      }),
      newone1
      ) as FormsReducer;
    }
    case at.FORMS_PASTE_ENTIRE_FORM: {
      const { type, data } = action.data;
      const form = view(rlens, forms) as unknown as IntakeForm;
      const conds = type === 'top' ?
        reindexQuestions({
          conds: form.conditionals,
          questionIndex: 0,
          amount: 1,
          type: 'consecutive'
        }) : form.conditionals;
      let maxId = findMaxId(form);
      const childrenWithProperIds = data.map((f: FormChild) => {
        maxId = maxId + 1;
        if (f.type === 'Section') {
          return {
            ...f,
            id: maxId,
            children: f.children.map((c: SectionChild) => {
              maxId = maxId + 1;
              return {
                ...c,
                id: maxId,
              };
            }),
          };
        } else {
          return {
            ...f,
            id: maxId,
          };
        }
      });
      const newone = over(
        rlens,
        evolve({
          children: (c) => {
            return type === 'bottom' ?
              concat(c, childrenWithProperIds) :
              concat(childrenWithProperIds, c);
          },
          conditionals: () => conds,
        }),
        forms
      ) as FormsReducer;
      const newone1 = over(ulens, evolve({
        history: append(forms.revision),
        currentIndex: inc,
      }),
      newone
      ) as FormsReducer;
      const topNew = type === 'top' ? null : childrenWithProperIds[0];
      return merge(newone1, {
        busy: false,
        newChild: topNew ? `${topNew.type}-${topNew.id}` : null,
      }) as FormsReducer;
    }
    default:
      return forms;
  }
};
