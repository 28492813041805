import React, { ComponentProps } from 'react';

const TemplateTagIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} {...props} xmlns="http://www.w3.org/2000/svg" width="26.192" height="14.778" viewBox="0 0 26.192 14.778">
      <g id="Group_16633" data-name="Group 16633" transform="translate(-1017 -2306.04)" opacity="0.872">
        <path id="Path_7485" data-name="Path 7485" d="M13.249,8.175v2.275H10.958c0,.916,0,1.793,0,2.67a3.77,3.77,0,0,1-.045.7,2.3,2.3,0,0,1-1.252,1.6,2.585,2.585,0,0,1,1.3,2.593c-.025.844,0,1.688,0,2.569H13.24v2.348a22.469,22.469,0,0,1-2.832-.137,2.144,2.144,0,0,1-1.737-2.183c-.016-.857,0-1.714-.005-2.571a4.8,4.8,0,0,0-.019-.492.945.945,0,0,0-1.058-.967c-.339,0-.677,0-1.042,0v-2.3H7.5c.836,0,1.138-.291,1.15-1.138.012-.822.03-1.645,0-2.466a2.338,2.338,0,0,1,2.52-2.521c.563.019,1.128,0,1.691,0,.126,0,.252.011.386.017" transform="translate(1010.454 2297.889)" fill={color || '#4d4d4d'} />
        <path id="Path_7486" data-name="Path 7486" d="M23.186,8.26a20.452,20.452,0,0,1,2.836.028,2.1,2.1,0,0,1,1.725,2.145c.016.868,0,1.737.007,2.605a2.945,2.945,0,0,0,.053.559.813.813,0,0,0,.83.709c.408.014.816,0,1.245,0v2.3h-.946c-.817,0-1.175.349-1.183,1.176-.008.845,0,1.69,0,2.535a3.988,3.988,0,0,1-.047.806,2.252,2.252,0,0,1-2.03,1.768c-.827.047-1.659.01-2.5.01V20.634h2.29c0-.75,0-1.463,0-2.175a8.289,8.289,0,0,1,.037-1.23,2.3,2.3,0,0,1,1.251-1.765c-.1-.069-.177-.124-.257-.179a2.166,2.166,0,0,1-1.03-1.854c-.007-.845,0-1.69,0-2.536v-.4H23.186Z" transform="translate(1013.309 2297.861)" fill={color || '#4d4d4d'} />
        <path id="Path_7487" data-name="Path 7487" d="M17.471,19.569c-.127.154-.27.334-.419.508a3.592,3.592,0,0,1-2.859,1.314,1.222,1.222,0,0,1-.042-2.444,1.383,1.383,0,0,0,1.128-.569c.349-.447.7-.892,1.062-1.329a.289.289,0,0,0,.046-.343c-.176-.418-.346-.839-.5-1.265-.054-.145-.136-.175-.281-.19a2.532,2.532,0,0,1-.8-.167,1.164,1.164,0,0,1-.606-1.2,1.177,1.177,0,0,1,.983-1.039,2.56,2.56,0,0,1,2.769,1.219c.107.185.182.389.286.618.132-.163.252-.314.375-.464A3.678,3.678,0,0,1,21.521,12.8a1.226,1.226,0,0,1,.339,2.412,3.988,3.988,0,0,1-.522.063,1.164,1.164,0,0,0-.794.442c-.388.477-.766.962-1.156,1.438a.293.293,0,0,0-.041.345c.171.4.337.809.485,1.221a.284.284,0,0,0,.334.216,1.238,1.238,0,0,1,1.215.613,1.219,1.219,0,0,1-.974,1.834,2.566,2.566,0,0,1-2.315-.828,8.19,8.19,0,0,1-.621-.992" transform="translate(1012.226 2296.329)" fill={color || '#4d4d4d'} />
      </g>
    </svg>
  );
};

export default TemplateTagIcon;
