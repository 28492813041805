import React, { useState } from 'react';

import {
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Grid,
  CircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import * as R from 'ramda';
import {
  tzParseFormat,
} from '../../services/joda.js';

import { Lead, Message } from '../../routes/Leads/leads.reducer';
import { OtherMore } from '../../routes/Clients/components/client-dialog/components/more.component';
import InboxChat from '../../routes/Messages/Inbox/InboxChat/InboxChat.component';
import * as ClientDialogactions from '../../routes/Clients/components/client-dialog/client-dialog.actions';
import { TableContainer as Container, HeaderCell, BodyCell } from '../../components/CustomTable';
import { History } from 'history';


const TableContainer = withStyles({
  root: {
    '& tbody tr td': {
      fontSize: 12,
    },
  }
})(Container);

const displayError = (error: { message: string }) => (
  <div>
    {'Unable to display data: ' + error.message}
  </div>
);

const fixLength = (str: string) => R.cond([
  [R.gt(R.__, 50), () => str.slice(0, 51) + '...'],
  [R.T, R.always(str)]
])(str.length);

const messageItem = (message: Message, tz: string, select: (msg: Message) => void) => ([
  R.prop('emailData', message) &&
  <TableRow
    style={{ cursor: 'pointer' }}
    onClick={() => select(message)}
    key={message.msgId + 'email'}>
    <BodyCell>
      {message.client.firstName + ' ' + message.client.lastName}
    </BodyCell>
    <BodyCell>
      {fixLength(R.pathOr('', ['emailData', 'subject'])(message))}
    </BodyCell>
    <BodyCell>
      Email
    </BodyCell>
    <BodyCell>
      {tzParseFormat(message.created, tz, 'MM/dd/yyyy @ h:mm:ss a')}
    </BodyCell>
  </TableRow>,
  R.prop('smsData', message) &&
  <TableRow
    style={{ cursor: 'pointer' }}
    onClick={() => select(message)}
    key={message.msgId + 'sms'}>
    <BodyCell>
      {message.client.firstName + ' ' + message.client.lastName}
    </BodyCell>
    <BodyCell>

    </BodyCell>
    <BodyCell>
      SMS/MMS
    </BodyCell>
    <BodyCell>
      {tzParseFormat(message.created, tz, 'MM/dd/yyyy @ h:mm:ss a')}
    </BodyCell>
  </TableRow>,
  R.prop('pushData', message) &&
  <TableRow
    style={{ cursor: 'pointer' }}
    onClick={() => select(message)}
    key={message.msgId + 'push'}>
    <BodyCell>
      {message.client.firstName + ' ' + message.client.lastName}
    </BodyCell>
    <BodyCell>
      {fixLength(R.pathOr('', ['pushData', 'subject'])(message))}
    </BodyCell>
    <BodyCell>
      Push
    </BodyCell>
    <BodyCell>
      {tzParseFormat(message.created, tz, 'MM/dd/yyyy @ h:mm:ss a')}
    </BodyCell>
  </TableRow>
]);

type messageTableProps = {
  messages: Message[];
  tz: string;
  select: (msg: Message) => void;
  showTabs?: boolean;
}
const messageTable = ({ messages, tz, select, showTabs = true }: messageTableProps) => (
  <Grid marginX={showTabs ? 0 : -2.5}>
    <TableContainer>
      <TableHead>
        <TableRow>
          <HeaderCell>
            Client
          </HeaderCell>
          <HeaderCell>
            Subject
          </HeaderCell>
          <HeaderCell>
            Sent As
          </HeaderCell>
          <HeaderCell>
            Sent Time
          </HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {R.has('error')(messages) ? displayError(R.prop('error')(messages) as { message: string }) :
          R.map((message: Message) => messageItem(message, tz, select)
          )(messages)
        }
      </TableBody>
    </TableContainer>
  </Grid>
);

type getMessagesType = ({ page, clientId }: { page: number, clientId: number }) => void;
type viewMessageType = (msg: Message) => void;

type MessageTabProps = {
  features?: string[];
  messages: Message[];
  client: Lead;
  tz: string;
  actions: {
    viewMessage: typeof ClientDialogactions.viewMessage | viewMessageType,
    getMessages: typeof ClientDialogactions.getMessages | getMessagesType
  };
  messagePage: number;
  msgBusy?: boolean;
  messageTotalPages?: number;
  tab?: string;
  setTab?: (val: string) => void;
  history: History;
}

type State = {
  open?: boolean;
  emoji?: boolean;
  showOldFreqs?: boolean;
  changed?: boolean;
  state?: string;
  anchorEl?: string;
  menuOpen?: boolean;
  messagesTab: string;
}

const MessageTab = (props: MessageTabProps) => {
  const [state, setState] = useState<State>({
    open: false,
    emoji: false,
    showOldFreqs: true,
    changed: false,
    state: 'SHOW_APTS',
    anchorEl: null,
    menuOpen: false,
    messagesTab: 'Message Thread',
  });

  const update = (data: State) => {
    setState(s => R.merge(s, data));
  };

  const messageTabItems = [{
    name: 'Message Thread',
    features: [] as string[],
    component: () => (
      <div>
        <InboxChat
          clientId={props.client.id}
          heightMod={props.tab ? 360 : 270}
          fullPage={false}
          history={props.history}
        />
      </div>
    ),
  }, {
    name: 'Sent Message History',
    features: [],
    component: () => (
      <div style={{
        height: 405,
        overflow: 'hidden',
      }}>
        <div style={{
          margin: '10px', maxHeight: state.open ? '33vh' : '40vh',
          overflow: 'scroll',
        }}>
          {messageTable({
            messages: props.messages,
            tz: props.tz,
            select: props.actions.viewMessage,
            showTabs: !props.tab
          })}
          <div
            style={{
              display: 'flex',
            }}>
            <IconButton
              className='sked-test-client-dialog-messages-historynav-left'
              disabled={props.msgBusy || props.messagePage === 1}
              onClick={() => props.actions.getMessages({
                page: props.messagePage - 1,
                clientId: props.client.id,
              })}>
              <ArrowLeftIcon />
            </IconButton>
            <IconButton
              className='sked-test-client-dialog-messages-historynav-right'
              disabled={props.msgBusy || props.messages?.length < 50}
              style={{ marginLeft: 5 }}
              onClick={() => props.actions.getMessages({
                page: props.messagePage + 1,
                clientId: props.client.id
              })} >
              <ArrowRightIcon />
            </IconButton>
            {props.msgBusy && <IconButton disabled style={{ marginLeft: 5 }}>
              <CircularProgress size={20} />
            </IconButton>}
          </div>
        </div>
      </div>
    ),
  }];

  return (
    <OtherMore
      features={props.features}
      items={messageTabItems}
      tab={props.tab ? props.tab : state.messagesTab}
      showTab={!props.tab}
      setTab={(p: string) => {
        if (props.setTab) {
          props.setTab(p);
        } else {
          update({ messagesTab: p });
        }
      }}
    />
  );
};

export default MessageTab;
