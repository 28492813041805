import * as React from 'react';
import {
  Button, TextField, CircularProgress, IconButton, FormControlLabel,
  Checkbox,
} from '@mui/material';
import TrashIcon from '@mui/icons-material/Delete';
import Attachment from '@mui/icons-material/Attachment';
import AddIcon from '@mui/icons-material/Add';
import {
  adjust, assoc, isEmpty, prop, append, remove, last, isNil,
} from 'ramda';
import {
  ComplexWidget, ComplexWidgetDrawablePicture, PictureOptions, SectionChild, IndexAndConds
} from '../intake-types';
import { getFileSize } from '../../../components/Editor/editor.component';
import { usePromise } from '../../../services/promise.hook';
import axios from 'axios';
import { skedApi } from '../../../services/api.js';
import { Spacer } from '../../../components/PageHeader';
import {
  updateSimpleWidget, deleteSectionChild,
} from '../intake.context';
import { OrderButtons } from './SectionChildOrderButtons.component';
import { useDispatch } from 'react-redux';
import { PhotoshopPicker } from 'react-color';
import QuestionLabel from './QuestionLabel.component';

const bodyStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
} as React.CSSProperties;


interface DrawablePictureProps {
  id: number;
  data: ComplexWidget,
  index: number;
  maxIndex: number;
  required: boolean;
  forOffice: boolean;
  questionCond: IndexAndConds;
}

interface EditDrawablePictureProps {
  id: number;
  data: ComplexWidgetDrawablePicture;
  onSave: (
    pictureUrl: string, options: PictureOptions[], required: boolean
  ) => void;
  onCancel: () => void;
  required: boolean;
}

interface File extends Blob {
  readonly lastModified: number;
  readonly name: string;
}

interface Attachment {
  attachmentName: string;
  attachmentId: number,
  attachmentUrl: string,
  attachmentMimeType: string,
}

type OptionProps = 'label' | 'color';

const uploadFile = (file: File): Promise<Attachment> => {
  const config = {
    headers: {
      'Authorization': skedApi.defaults.headers.common.Authorization,
      'X-As-Office': skedApi.defaults.headers.common['X-As-Office'],
    },
  };
  const formData = new FormData();
  formData.append('attachment', file, file.name);
  return axios.post(
    `${process.env.API_URL}/attachment/upload`,
    formData,
    config
  ).then((res) => {
    return res.data.officeAttachments[0];
  });
};

export const EditDrawablePictureComponent = ({
  data, onSave, onCancel, required
}: EditDrawablePictureProps) => {
  const [opened, setOpened] = React.useState<number>(null);
  const [color, setColor] = React.useState<string>('white');
  const [options, setOptions] = React.useState<PictureOptions[]>(data.options);
  const [pictureUrl, setPictureUrl] = React.useState(data.pictureUrl);
  const [file, setFile] = React.useState<File>(null);
  const [localRequired, setLocalRequired] = React.useState<boolean>(required);
  const fileState = usePromise<File, Attachment>(uploadFile, null);
  const attachmentButtonRef = React.useRef(null);

  const fileData = prop('data', fileState);

  const {
    attachmentUrl,
    attachmentName,
  } = fileData && !isEmpty(fileData) ? fileData : { attachmentUrl: null, attachmentName: null };

  React.useEffect(() => {
    if (!pictureUrl) {
      setPictureUrl(fileData?.attachmentUrl);
    }
  }, [fileData]);

  const updateOption = (idx: number, prop: OptionProps) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptions(adjust(idx, assoc(prop, e.target.value), options));
  };

  const addOption = () => {
    const newOption = {
      label: '',
      color: '',
    };
    setOptions(append(newOption, options));
  };

  const handleDelete = (idx: number) => () => {
    setOptions(remove(idx, 1, options));
  };

  const handleSave = () => {
    onSave(pictureUrl, options, localRequired);
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalRequired(e.target.checked);
  };

  return (
    <div>
      {isNil(opened) ?
        null
        :
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}>
          <PhotoshopPicker
            header={'Color for ' + options[opened].label}
            color={color}
            onChangeComplete={(color: any) => setColor(color.hex)}
            onCancel={() => setOpened(null)}
            onAccept={() => {
              setOptions(adjust(opened, assoc('color', color), options));
              setColor('white');
              setOpened(null);
            }}
          />
        </div>
      }
      <FormControlLabel
        control={
          <Checkbox
            checked={localRequired || false}
            onChange={handleCheck}
            color="primary"
          />}
        label="Required" />
      <br />
      Options:
      <br />
      {options.map((option, idx) => {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              value={option.label}
              onChange={updateOption(idx, 'label')}
              label="Color Label" />
            &nbsp;
            <TextField
              style={{ width: '75px' }}
              InputLabelProps={{ shrink: true }}
              value={option.color}
              onChange={updateOption(idx, 'color')}
              label="Color"
              placeholder="#008BCF"
            />
            &nbsp;
            <div
              style={{
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
              }}
              onClick={() => setOpened(idx)}>
              <div style={{ width: '36px', height: '14px', borderRadius: '2px', background: option.color }} />
            </div>
            &nbsp;
            <IconButton
              aria-label="Delete option"
              onClick={handleDelete(idx)}
            >
              <TrashIcon />
            </IconButton>
          </div>
        );
      })}
      <Button
        color="primary"
        variant="contained"
        onClick={addOption}
        startIcon={<AddIcon />}
      >
        Add Option
      </Button>
      <br />
      <div>
        Picture:
        {fileState.loading ?
          <CircularProgress />
          :
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
          }}>
            <input
              accept="image/*"
              style={{
                display: 'none',
              }}
              id="contained-button-file"
              type="file"
              ref={attachmentButtonRef}
              onChange={(e) => {
                const {
                  files
                } = e.target;
                setFile(files[0]);
                setPictureUrl(null);
                fileState.invoke(files[0]);
                attachmentButtonRef.current.value = null;
              }}
            />
            {!file &&
              <label
                style={{ width: '120px' }}
                htmlFor="contained-button-file">
                <Button
                  variant='contained'
                  color="primary"
                  component="span"
                  aria-label="Upload Attachment"
                  startIcon={<Attachment />}>
                  Upload
                </Button>
              </label>}
            <div style={{
              marginTop: '10px',
            }}>
              {((fileData && !isEmpty(fileData)) || file || pictureUrl) &&
                <div
                  style={{
                    backgroundColor: 'lightgray',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px',
                    borderRadius: '5px',
                    marginBottom: '5px',
                  }}>
                  <div style={{
                    display: 'flex',
                  }}>
                    {attachmentUrl || pictureUrl ?
                      <a href={attachmentUrl || pictureUrl} target='_blank'>
                        {
                          file ?
                            file.name
                            :
                            attachmentName || (pictureUrl && last(pictureUrl.split('?')[0].split('/')))}
                      </a>
                      :
                      <p style={{
                        margin: 'unset',
                      }}>
                        {
                          file ?
                            file.name
                            :
                            attachmentName}
                      </p>}
                    &nbsp;
                    {file &&
                      <p style={{
                        margin: 'unset',
                      }}>{
                          '(' +
                          getFileSize(file.size, true)
                          +
                          ')'
                        }</p>}
                  </div>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setFile(null);
                      setPictureUrl(null);
                    }}
                  >
                    <TrashIcon />
                  </IconButton>
                </div>}
            </div>
          </div>}
      </div>
      <br />
      <br />
      <Button color="primary" variant="outlined" onClick={handleSave}>Save</Button>
      &nbsp;&nbsp;
      <Button color="primary" variant="outlined" onClick={onCancel}>Cancel</Button>
    </div>
  );
};

export const DrawablePictureComponent = ({
  id, data, required, index, maxIndex, forOffice, questionCond
}: DrawablePictureProps) => {
  const tdata = data.typeData as ComplexWidgetDrawablePicture;
  const dispatch = useDispatch();
  const [edit, setEdit] = React.useState(false);
  const handleSave = (
    pictureUrl: string, options: PictureOptions[], isRequired: boolean,
  ) => {
    setEdit(false);
    const updatedDrawablePicture: SectionChild = {
      id,
      width: 12,
      type: 'ComplexWidget',
      required: isRequired,
      forOffice,
      typeData: {
        type: 'DrawablePicture',
        pictureUrl,
        options,
      }
    };
    dispatch(updateSimpleWidget(updatedDrawablePicture));
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleDelete = () => {
    dispatch(deleteSectionChild(id, questionCond.index));
  };

  if (edit) {
    return (
      <EditDrawablePictureComponent
        id={id}
        data={tdata}
        onSave={handleSave}
        onCancel={handleCancel}
        required={required}
      />
    );
  }
  return (
    <div style={bodyStyle}>
      {!forOffice &&
        <div style={{ display: 'flex' }}>
          <QuestionLabel cond={questionCond} complex />
        </div>}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}>
        <div>Drawable Picture</div>
        <Spacer />

        <OrderButtons
          index={index}
          sectionMaxIndex={maxIndex}
          data={data}
          id={id}
          questionIndex={questionCond.index}
          handleDelete={handleDelete}
        />
      </div>
      <div>Required: {required ? 'yes' : 'no'}</div>
      <br />
      {tdata.pictureUrl && !isEmpty(tdata.pictureUrl) &&
        <img
          src={tdata.pictureUrl}
          width="100px"
          height="100px"
        />}
      {tdata.options.map((option, i) => {
        return (
          <div
            key={i}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '5px',
            }}>
            {option.label}
            &nbsp;
            <div
              style={{
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
              }}>
              <div style={{ width: '36px', height: '14px', borderRadius: '2px', background: option.color }} />
            </div>
          </div>
        );
      })}
      <Button
        style={{ marginTop: '10px' }}
        color="primary"
        onClick={() => setEdit(true)}
        variant="outlined">
        Edit
      </Button>
    </div>
  );
};
