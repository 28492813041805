import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { match as Match } from 'react-router-dom';
import * as R from 'ramda';
import {
  Grid, CircularProgress, TextField, IconButton, useMediaQuery,
  Popover, Button, Typography,
} from '@mui/material';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import DraftsIcon from '@mui/icons-material/Drafts';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import QuickReplyIcon from '../../../../icons/QuickReply.icon';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import ClientEditDialog from '../../../Clients/components/client-dialog/client-dialog.component';
import EditLeadDialog from '../../../Leads/components/EditLead/EditLead.component';
import ConvertLeadDialog from '../../../Leads/components/Convert/Convert.component';
import { caseType, stopDelay } from '../../../../services/utilities';
import {
  tzParseFormat, tzParse, ordinal, now, format,
} from '../../../../services/joda';
import { usePromise } from '../../../../services/promise.hook';
import * as rawActions from '../../routes/MessagesThread/messages-thread.actions';
import * as messageActions from '../../messages.actions';
import { useSelector } from '../../../../reducers';

import PaperClipIcon from '../../../../icons/PaperClip.icon';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import Avatar from '../../../../components/Avatar/Avatar.component';
import { getFileSize } from '../../../../components/Editor/editor.component';

import { Message } from '../../message-types';
import ChatItem from '../InboxChatItem/ChatItem.component';
import QuickReply from '../../components/quick-reply.component';
import { History } from 'history';

import { useStyles } from './inboxChat.styles';
import
WarningRectangle
  from '../../../../components/WarningRectangle/WarningRectangle.component';
import { Tooltip } from '../../../../components/components.component';
import {
  officePlaceholders, locationPlaceholders, apptPlaceholders,
} from '../../../../components/create-message.component';
import {
  clientPlaceholders, proPlaceholders
} from '../../../Settings/routes/QuickReplies/quick-replies.page';
import Placeholders, {
  Placeholder
} from '../../../../components/Placeholders/Placeholders.component';
import { Location } from '../../../../components/hours';
import {
  getLoc,
} from '../../../Settings/routes/Business/business.actions.js';
import {
  getPros,
} from '../../../../components/AutomationBasedMessage/automation-message.actions';
import {
  getAppts,
} from '../../../Clients/components/client-dialog/client-dialog.actions';
import {
  replacePlaceholders
} from '../../../Settings/routes/QuickReplies/quick-replies.actions';

const characterLimit = 160;
const unicodeCharacterLimit = 70;
export const uploadSizeLimit = 1000000;

const countCredits = (str: string) => {
  // eslint-disable-next-line no-control-regex
  const hasUnicode = /[^\u0000-\u00ff]/.test(str);
  const limit = hasUnicode ? unicodeCharacterLimit : characterLimit;
  const strLength = str ? str.length : 0;
  const segment = Math.ceil(strLength / limit);
  return {
    strLength,
    limit,
    segment
  };
};

function formatPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber || !phoneNumber.includes('+1')) {
    return phoneNumber;
  }
  const cleaned = phoneNumber?.replace('+1', '');
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
}

type MatchParams = {
  id?: string;
}

type InboxChatProps = {
  resizeNumber?: number,
  fullPage?: boolean;
  heightMod?: number;
  history: History;
  onClose?: () => void;
  clientId?: number;
  match?: Match<MatchParams>
}

const InboxChat = ({
  resizeNumber, fullPage = true, heightMod = 270, onClose,
  history, match, clientId,
}: InboxChatProps) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [isUnread, setIsUnread] = useState(false);
  const [showRead, setShowRead] = useState(false);
  const [onlyIcon, setOnlyIcon] = useState(false);
  const [divHeight, setDivHeight] = useState(500);
  const [textHeight, setTextHeight] = React.useState(0);
  const [attachments, setAttachments] = useState([]);
  const [prev, setPrev] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [leadToConvert, setLeadToConvert] = useState(null);
  const [prevLength, setPrevLength] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [usedQr, setUsedQr] = useState(null);
  const [messagesByDate, setMessagesByDate] = useState<{ [x: string]: Message[] }>(null);
  const [openQR, setOpenQR] = useState<HTMLButtonElement>(null);
  const [warningAnchor, setWarningAnchor] = useState<HTMLDivElement>(null);
  const textFieldRef = React.useRef(null);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const formRef = React.useRef(null);
  const showEmoji = useMediaQuery('(min-width:600px)');
  const getPhiState = usePromise(rawActions.getPhis, []);
  const locState = usePromise<void, Location[]>(getLoc, []);
  const proState = usePromise(getPros, []);
  const apptState = usePromise(getAppts, {
    data: [],
  });

  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);

  const {
    client, busy, state, isOptedOutTwoWay, createBusy, messages, tz,
    allLoaded, moreBusy, page, inbox, banners, conversationId, smsTab,
    isArchived, newlySentMessage, features, perPage, office,
  } = useSelector(state => ({
    client: state.messageThread.client,
    conversationId: state.messageThread.conversationId,
    busy: state.messageThread.busy,
    state: state.messageThread.state,
    createBusy: state.messageThread.createBusy,
    messages: state.messageThread.messages,
    allLoaded: state.messageThread.allLoaded,
    page: state.messageThread.page,
    moreBusy: state.messageThread.moreBusy,
    isArchived: state.messageThread.isArchived,
    newlySentMessage: state.messageThread.newlySentMessage,
    isOptedOutTwoWay: R.includes(
      'TwoWayMessages',
      R.pathOr([], ['messageThread', 'clientOptOut', 'optouts'], state)
    ),
    tz: R.pathOr('', ['login', 'office', 'timezone'])(state),
    office: state.login.office,
    query: state.messages.query,
    inboxFilter: state.messages.inboxFilter,
    unread: state.messages.unread,
    inbox: state.messages.inbox,
    banners: state.login.banners,
    contactType: state.messages.contactType,
    smsTab: state.messages.smsTab,
    features: state.login.features,
    perPage: state.messageThread.perPage,
  }));

  const hasQuickReply = React.useMemo(() => {
    return features.includes('SmsQuickReply');
  }, [features]);

  const urlId = R.pathOr(clientId || client.id, ['params', 'id'], match);

  const insertPlaceholder = (body: string, placeholder: string) => {
    const splitAt = (index: number, index2: number) => (x: string) => [x.slice(0, index), x.slice(index2)];
    const ctl = textFieldRef.current;
    const startPos = ctl.selectionStart;
    const endPos = ctl.selectionEnd;
    const splitBody = splitAt(startPos, endPos)(body);
    ctl.focus();
    const newBody = splitBody[0] + placeholder + splitBody[1];
    return newBody;
  };

  const handleEmojiSelect = (value: string) => {
    setText(insertPlaceholder(text, value));
    handleClose();
  };

  const insertPlaceholderValue = (v: string) => {
    const value = replacePlaceholders({
      body: v, locations: locState.data, office, client,
      appointments: apptState.data.data,
      professionals: proState.data, forreal: true,
    });
    setText(insertPlaceholder(text, value));
    setTimeout(() => {
      textFieldRef.current.focus();
    }, 500);
    if (value.includes('____')) {
      setWarningAnchor(formRef.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (ind: number) => () => {
    setAttachments(R.remove(ind, 1, attachments));
    setPrev(R.remove(ind, 1, prev));
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      files
    } = e.target;
    const size: number = R.reduce((a, c) => {
      return a + c.size;
    }, 0, files as unknown as Blob[]);
    if (size < uploadSizeLimit) {
      R.forEach((f) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
      }, files as unknown as Blob[]);
      const filesArray = R.map((f) => ({
        file: f,
        progress: 0,
      }))(files as unknown as File[]);
      setAttachments(attachments.concat(filesArray));
    } else {
      console.log('too big');
      alert('Error! MMS messages cannot contain attachments greater than 1.5MB!');
    }
  };

  const handleTextChange = (value: string) => {
    setTimeout(() => {
      onHeightChange();
    });
    setText(value);
  };

  /* odat = overwrite data, used to quick reply quick send */
  const handleSubmit = (odata?: {text: string, usedQr: number}) => {
    const itext = odata?.text || text;
    const iQr = odata?.usedQr || usedQr;

    if (busy) {
      return;
    }

    if (itext.trim().length === 0 && attachments.length === 0) {
      return;
    }

    actions.sendMessageQuick({
      body: itext, attachments, usedQr: iQr,
    }, client.id);
    setTimeout(() => {
      scrollRef.current.scrollTo(0, 0);
      dispatch(messageActions.readAllMessages(client.id));
      setText('');
      setAttachments([]);
      setPrev([]);
      setUsedQr(null);
      setWarningAnchor(null);
    }, 150);
  };

  const characterCount = (str: string) => {
    const { strLength, limit, segment } = countCredits(str);
    return `${strLength}/${limit} | Credits: ${segment}`;
  };

  const unreadMessage = () => {
    messageActions.handleReadMessage(conversationId, false).then(() => {
      messageActions.unArchiveMessage(conversationId).then(() => {
        dispatch(messageActions.refresh());
        dispatch(messageActions.handleCountReadMessage());
      });
      setIsUnread(true);
    });
  };

  const readMessage = async () => {
    messageActions.handleReadMessage(conversationId, true).then(() => {
      dispatch(messageActions.refresh());
      dispatch(messageActions.handleCountReadMessage());
      setIsUnread(false);
    });
  };

  const archiveChat = () => {
    messageActions.archiveMessage(conversationId).then(() => {
      dispatch(messageActions.refresh());
      actions.messageThreadPatch({ isArchived: true });
    });
  };

  const unachiveChat = () => {
    messageActions.unArchiveMessage(conversationId).then(() => {
      dispatch(messageActions.refresh());
      actions.messageThreadPatch({ isArchived: false });
    });
  };

  const getName = () => {
    const name = `${client?.firstName} ${client?.lastName}`;
    if (name.trim().length === 0) {
      return client.metadata.caseType;
    }
    return name;
  };

  const onHeightChange = () => {
    const height = formRef.current?.getBoundingClientRect()?.height;
    setTextHeight(height);
  };

  const updateHeight = () => {
    const windowHeight = window.innerHeight;
    setDivHeight(windowHeight);
    setTimeout(() => {
      onHeightChange();
    });
  };

  const onWidthChange = () => {
    const width = rootRef?.current?.getBoundingClientRect().width;
    if (width < 690) {
      setOnlyIcon(true);
    } else {
      setOnlyIcon(false);
    }
  };

  const getDate = (date: string) => {
    if (date && tz) {
      const day = tzParse(date, tz);
      const ord = ordinal(day.dayOfMonth());
      return tzParseFormat(date, tz, `EE, MMM  d'${ord}' YYYY`);
    }
    return '';
  };

  const mountMessages = () => {
    const messagesObj: { [x: string]: Message[] } = {};
    messages.forEach((message: Message) => {
      if (message.created) {
        const parsed = tzParse(message.created, tz).toString();
        const date = parsed.split('T');
        if (Object.prototype.hasOwnProperty.call(messagesObj, date[0])) {
          messagesObj[date[0]].push(message);
        } else {
          messagesObj[date[0]] = [message];
        }
      }
    });
    setMessagesByDate(messagesObj);
  };

  useEffect(() => {
    if (newlySentMessage) {
      console.log('this is the data from quick message send', newlySentMessage);
      actions.pollForStatus({
        msgId: newlySentMessage.msg.msgId,
        tmpId: newlySentMessage.tmpId,
      });
      setText('');
      setAttachments([]);
      setPrev([]);
    }
  }, [newlySentMessage]);

  useEffect(() => {
    if (messages.length !== prevLength) {
      getPhiState.invoke(messages);
      setPrevLength(messages.length);
    }
  }, [messages]);

  useEffect(() => {
    if (prev.length < attachments.length) {
      rawActions.uploadAttachments(attachments, setAttachments);
      setPrev(attachments);
    }
  }, [attachments]);

  useEffect(() => {
    function handleResize() {
      updateHeight();
    }
    if (fullPage) {
      updateHeight();
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [fullPage]);

  useEffect(() => {
    if (client?.id) {
      const chat = inbox.find((msg: Message) => msg.clientId === client.id);
      if (chat) {
        actions.messageThreadPatch({ isArchived: !!chat.archived });
      }
    }
    setText('');
    setAttachments([]);
    setPrev([]);
  }, [client]);

  useEffect(() => {
    if (fullPage) {
      onWidthChange();
    }
  }, [resizeNumber, fullPage]);

  useEffect(() => {
    getPhiState.invoke(messages);
    setShowRead(!!messages[0]?.from?.FromClient);
  }, [messages]);

  useEffect(() => {
    mountMessages();
  }, [messages]);

  useEffect(() => {
    stopDelay();
    return () => {
      console.log('unmounted inbox chat: ');
      stopDelay();
    };
  }, [client]);

  useEffect(() => {
    if (urlId) {
      actions.messageThreadPatch({ client: {} });
      actions.loadMessage({ clientId: Number(urlId), perPage });
    }
    setTimeout(() => {
      onHeightChange();
    });
    return () => {
      setWarningAnchor(null);
    };
  }, []);

  useEffect(() => {
    if (client?.id && state === 'VIEW') {
      proState.invoke({});
      apptState.invoke({
        page: 1, perPage: 1, query: {
          clientId: client.id,
          after: format(now('tz', tz), 'yyyy-MM-dd\'T\'HH:mm:ss'),
          status: ['Scheduled'],
        } });
      if (features.includes('Locations'))
        locState.invoke();
    }
  }, [client, features, state]);

  useEffect(() => {
    if (warningAnchor && text === '')
      setWarningAnchor(null);
    if (!text.includes('____')) {
      setWarningAnchor(null);
    }
  }, [text]);

  const missingPlaceholders = React.useMemo(() => {
    return Boolean(warningAnchor) && text.includes('____');
  }, [warningAnchor, text]);

  const placeholders = React.useMemo(() => {
    const filterByFeatures = (placeholders: Placeholder[]) => {
      return placeholders.filter(({ feature }) => {
        if (feature) {
          return features.includes(feature);
        }
        return true;
      });
    };

    return [
      { title: 'Next Appointment', placeholders: filterByFeatures(apptPlaceholders), },
      { title: 'Client', placeholders: filterByFeatures(clientPlaceholders), },
      features.includes('Locations') &&
        { title: 'Location', placeholders: filterByFeatures(locationPlaceholders), },
      { title: 'Office', placeholders: filterByFeatures(officePlaceholders), },
      { title: 'Professional', placeholders: filterByFeatures(proPlaceholders), },
    ];
  }, [features]);

  const openQuickReply = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpenQR(e.currentTarget);
  };

  const closeQuickReply = () => {
    setOpenQR(null);
  };

  const acceptQuickReply = (body: string, id: number, fire: boolean, warning: boolean) => {
    setText(body);
    setOpenQR(null);
    setUsedQr(id);
    console.log(warning);
    setWarningAnchor(warning ? formRef.current : null);
    if (fire) {
      handleSubmit({ text: body, usedQr: id });
    }
    setTimeout(() => {
      textFieldRef.current.focus();
    }, 500);
  };

  return (
    <>
      <Grid className={classes.root} ref={rootRef}>
        {fullPage && (
          <Header
            leftIcons={[
              (!!client.firstName || !!client.lastName) ?
                <Avatar name={getName()} className={classes.avatar} isLead={client?.isLead} /> : <div />,
              !!client.firstName &&
            <Button onClick={() => client?.isLead ? setSelectedLead(client) : actions.selectClient(client)} color='inherit' className={classes.titleButton}>
              <Typography className={classes.title} variant='h4'>
                {`${client.firstName || ''} ${client.lastName || ''} ${client && caseType(client)}`} <span>{formatPhoneNumber(client.phone)}</span>
              </Typography>
            </Button>
            ]}
            rightIcons={[
              <Grid marginX="5px">{busy && <CircularProgress size={18} />}</Grid>,
              (showRead && !!client.firstName) ?
                <Grid marginRight="5px">
                  {isUnread ?
                    <HeaderButton onlyIcon={onlyIcon} title='Mark Read' Icon={DraftsIcon} onClick={readMessage} />
                    :
                    <HeaderButton onlyIcon={onlyIcon} title='Mark Unread' Icon={DraftsIcon} onClick={unreadMessage} />
                  }
                </Grid> : <div />,
              !!client.firstName &&
              <>
                {isArchived ? (
                  <HeaderButton onlyIcon={onlyIcon} title='Unarchive Chat' Icon={ArchiveIcon} onClick={unachiveChat} active type='archiveChat' iconStyle={{ color: '#FFF' }} />
                ) : (
                  <HeaderButton onlyIcon={onlyIcon} title='Archive Chat' Icon={ArchiveIcon} onClick={archiveChat} active type='archiveChat' iconStyle={{ color: '#FFF' }} />
                )}
              </>,
              !!onClose &&
            <HeaderButton marginLeft={5} marginRight={-10} title='Close' onlyIcon Icon={CloseIcon} onClick={onClose} />
            ]}
            onlyIconsWidth={resizeNumber ? undefined : 560}
          />
        )}

        <Grid
          className={classes.content}
          ref={scrollRef}
          style={{ height: fullPage ? `calc(${divHeight - textHeight - (banners.length * 33) - (attachments.length * 40)}px - 64px - 54px - ${warningAnchor ? 35 : 0}px)` : `calc(${heightMod - attachments.length * 40}px - ${warningAnchor ? 35 : 0}px)` }}
          onScroll={(e) => {
            const bounds = e.currentTarget.getBoundingClientRect();
            const scrollSpace = e.currentTarget.scrollHeight + e.currentTarget.scrollTop - bounds.height;
            if (scrollSpace <= 10 && !allLoaded && !moreBusy) {
              actions.loadMoreMessages({
                clientId: client.id,
                page: page + 1
              });
            }
          }}
        >
          <div className={classes.loader}>
            {busy && <CircularProgress />}
          </div>
          {!busy && client.id && !!client?.firstName &&
         (!client.allowSMS || isOptedOutTwoWay) &&
         <div className={classes.systemMessage}>
           {client?.firstName} disabled SMS notifications. 😢
         </div>}
          {!busy && messages.length === 0 && client.allowSMS &&
          <div className={classes.systemMessage}>
            Start a conversation with {client.firstName} 💬
          </div>}
          {(!busy && messagesByDate) && (
            Object.entries(messagesByDate).map((item) => (
              <>
                {item[1].map((message: Message) => (
                  <ChatItem
                    key={message.msgId}
                    message={message}
                    tz={tz}
                    phis={getPhiState.data}
                  />
                ))}
                <span className={classes.date}>
                  {getDate(item[1][0].created)}
                </span>
              </>
            )
            ))}
          <div
            className={classes.loader}
            style={{ paddingTop: allLoaded ? '5px' : '55px' }}>
            {!!messages.length && moreBusy && <CircularProgress />}
          </div>
        </Grid>
        <Grid display="flex" alignItems="center" flexDirection="column">
          {attachments.map((f, ind) => {
            const {
              file,
              progress,
              attachment,
            } = f;
            const url = R.propOr(false, 'attachmentUrl')(attachment);
            return (
              <div
                key={ind}
                style={{
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '5px',
                  borderRadius: '5px',
                  marginBottom: '5px',
                  width: '95%',
                }}>
                <div style={{
                  display: 'flex',
                }}>
                  <div className={classes.fileText}>
                    {url ?
                      <a href={url as string} target='_blank'>
                        {file ? file.name : attachment.attachmentName}</a>
                      :
                      <p style={{
                        margin: 'unset',
                      }}>
                        {file ? file.name : attachment.attachmentName} </p>}
                  </div>
                &nbsp;
                  {file && <p className={classes.fileSize}>{ '(' + getFileSize(file.size, true) + ')' }</p>}
                </div>
                {progress !== 100 ?
                  <div style={{
                    width: '100px',
                    height: '10px',
                    backgroundColor: '#b3b3b3',
                  }}>
                    <div style={{
                      width: progress + 'px',
                      height: '100%',
                      backgroundColor: '#008BCF',
                    }}></div>
                  </div> :
                  <CloseIcon className={classes.fileClose} onClick={handleDelete(ind)} />
                }
              </div>
            );
          })}
        </Grid>
        <Grid className={classes.formContainer}>
          <WarningRectangle
            open={Boolean(warningAnchor)}
            type={'warning'}
            onClose={() => setWarningAnchor(null)}
            title={'Client does not have a future appointment. Some placeholders weren\'t inserted.'}
            className={classes.warning}
          />
          <form
            ref={formRef}
            noValidate
            autoComplete="off"
            className={classes.form}
            style={{
              marginRight: fullPage ? 10 : 0,
              marginLeft: fullPage ? 10 : 0,
              borderTopLeftRadius: warningAnchor ? 'unset' :
                undefined,
              borderTopRightRadius: warningAnchor ? 'unset' :
                undefined,
            }}
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <div className={classes.inputArea}>
              <TextField
                inputRef={textFieldRef}
                placeholder={client.firstName && (!client.allowSMS || isOptedOutTwoWay) ? (client.firstName + ' disabled SMS notifications. 😢') : ''}
                value={text}
                multiline
                maxRows={4}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && !e.altKey && !e.ctrlKey) {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
                onChange={(event) => handleTextChange(event.target.value)}
                disabled={createBusy || !client.allowSMS || isOptedOutTwoWay}
                id="sms-chat-input"
                variant="outlined"
                className={classes.input}
              />
            </div>
            <Grid display="flex" alignItems="center" marginTop={0.5}>
              {missingPlaceholders ?
                <Tooltip arrow title='Remove the missing placeholders to send. "____"'>
                  <span>
                    <Button
                      disabled={true}
                      aria-label='send'
                      className={
                        `${classes.sendButton} sked-test-sms-inbox-send-button`
                      }
                      style={{ height: 29 }}
                      type='submit'>
                Send
                    </Button>
                  </span>
                </Tooltip> :
                <Button
                  disabled={
                    createBusy || (attachments.length === 0 && text.length === 0)
                  }
                  aria-label='send'
                  className={
                    `${classes.sendButton} sked-test-sms-inbox-send-button`
                  }
                  type='submit'>
                Send
                </Button>}
              {showEmoji && (
                <>
                  <IconButton
                    type='button'
                    disabled={!client.allowSMS || isOptedOutTwoWay}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    onMouseDown={(event) => event.preventDefault()}
                    className='sked-test-sms-inbox-emoji-icon'
                  >
                    <InsertEmoticonIcon />
                  </IconButton>
                  <input
                    accept="image/*,audio/*,video/*,.pdf,.docx,.doc,.vcf"
                    style={{ display: 'none' }}
                    id="chat-attachment"
                    multiple
                    type="file"
                    onChange={handleFileUpload}
                  />
                  <label htmlFor="chat-attachment">
                    <IconButton
                      component="span"
                      aria-label="Upload Attachment"
                      disabled={!client.allowSMS || isOptedOutTwoWay}
                      className='sked-test-sms-inbox-attachment-icon'
                    >
                      <PaperClipIcon />
                    </IconButton>
                  </label>
                </>
              )}
              {hasQuickReply &&
                <>
                  <Placeholders
                    placeholders={placeholders}
                    insertFunction={insertPlaceholderValue}
                    isHTML={false}
                    isSMS={true}
                  />
                  <div>
                    <IconButton
                      aria-label="SMS Quick Reply"
                      disabled={!client.allowSMS}
                      className='sked-test-sms-inbox-quick-reply-icon'
                      onClick={openQuickReply}
                      style={{ padding: '15px 8px' }}
                    >
                      <QuickReplyIcon className={classes.quickreply} />
                    </IconButton>
                  </div>
                </>}
              <Typography className={classes.credits}>
                {characterCount(text)}
              </Typography>
            </Grid>
          </form>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}>
            <Picker native={true} title="Emoji Picker" onSelect={(event) => handleEmojiSelect((event as unknown as { native: string }).native)} />
          </Popover>
        </Grid>
        {fullPage && (
          <ClientEditDialog
            open={state === 'CLIENT_SELECT'}
            onClose={actions.backToMessage}
            back={actions.back}
            from={'MESSAGE'}
            history={history}
            gotoClient={actions.back} />
        )}
        {fullPage && (
          <>
            <EditLeadDialog
              open={!!selectedLead}
              close={() => setSelectedLead(null)}
              lead={selectedLead}
              convertClick={() => {
                setLeadToConvert(selectedLead);
                setSelectedLead(null);
              }}
              onUpdate={() => {
                setTimeout(() => {
                  dispatch(messageActions.refresh());
                  actions.loadMessage({
                    clientId: client?.id,
                    unread: smsTab === 'unread',
                    archived: smsTab === 'archived',
                  });
                }, 800);
              }}
            />
            <ConvertLeadDialog
              open={!!leadToConvert}
              lead={leadToConvert}
              close={() => setLeadToConvert(null)}
              onConvert={() => {
                setLeadToConvert(null);
                setTimeout(() => {
                  dispatch(messageActions.refresh());
                  actions.loadMessage({
                    clientId: client?.id,
                    unread: smsTab === 'unread',
                    archived: smsTab === 'archived',
                  });
                }, 800);
              }}
            />
          </>
        )}
      </Grid>
      <QuickReply
        open={Boolean(openQR)}
        onClose={closeQuickReply}
        onClick={acceptQuickReply}
        anchorEl={openQR}
        history={history}
        client={client}
        pageWidth={rootRef?.current?.clientWidth}
      />
    </>
  );
};

export default InboxChat;
