import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
} from '@mui/material';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  Layout,
} from '@stripe/stripe-js';

interface CheckoutFormProps {
  setSubmitting: (b: boolean) => void;
  pushError: (b: boolean) => void;
  submit: boolean;
}

const CheckoutForm = ({
  setSubmitting, submit, pushError,
}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  useEffect(() => {
    if (submit) {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);
      setSubmitting(true);

      stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${process.env.APP_URL}/window.location.hash`,
        },
      }).then(({ error }) => {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (!error) {
          pushError(false);
        } else if (error.type === 'card_error' || error.type === 'validation_error') {
          setMessage(error.message);
          setError(true);
          pushError(true);
        } else {
          setMessage('An unexpected error occurred.');
          setError(true);
          pushError(true);
        }

        setIsLoading(false);
        setSubmitting(false);

      });

    }
  }, [submit]);

  const paymentElementOptions = {
    layout: 'tabs' as Layout,
    paymentMethodOrder: ['us_bank_account', 'card'],
  };

  return (
    <form id="payment-form" style={{ marginBottom: isLoading ? 40 : 25 }}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {isLoading && <CircularProgress />}
      {/* Show any error or success messages */}
      {message &&
        <div id="payment-message" style={{
          color: error ? 'red' : 'unset',
        }}>
          {message}
        </div>}
    </form>
  );
};

export default CheckoutForm;
