import api from '../../../../services/api.js';
import { Client } from '../../../Clients/clients.types';
import { Office } from '../../../Login/login.reducer';
import { Location } from '../../../../components/hours';
import {
  Appointment, Professional
} from '../../../Appointments/appointments.types';
import { tzParseFormat } from '../../../../services/joda.js';

export interface QuickReply {
  id: number;
  name: string;
  body: string;
  used: number;
  lastUsed: string;
  updated: string;
  created: string;
}



const defaults: QuickReply[] = [{
  id: null,
  name: 'Thank you for letting us know!',
  body: 'Thank you for letting us know!',
  used: 0,
  lastUsed: null,
  updated: null,
  created: null,
}, {
  id: null,
  name: 'Perfect! See you then!',
  body: 'Perfect! See you then!',
  used: 0,
  lastUsed: null,
  updated: null,
  created: null,
}, {
  id: null,
  name: 'You are welcome!',
  body: 'You are welcome!',
  used: 0,
  lastUsed: null,
  updated: null,
  created: null,
}, {
  id: null,
  name: 'You\'re on the books',
  body: 'You\'re on the books',
  used: 0,
  lastUsed: null,
  updated: null,
  created: null,
}, {
  id: null,
  name: 'Appointment Reminder',
  body: 'Hey {{client.firstName}} your next appointment is at {{appt.time}} on {{appt.date}}.',
  used: 0,
  lastUsed: null,
  updated: null,
  created: null,
}];

export const sortOptions = [
  {
    value: {
      value: 'Updated',
      field: 'Updated',
      direction: 'Desc'
    },
    label: 'Last Edited Date (Newest First)'
  },
  {
    value: {
      value: 'Updated',
      field: 'Updated',
      direction: 'Asc'
    },
    label: 'Last Edited Date (Oldest First)'
  },
  {
    value: {
      value: 'LastUsed',
      field: 'LastUsed',
      direction: 'Desc'
    },
    label: 'Last Used (Most Recent First)'
  },
  {
    value: {
      value: 'LastUsed',
      field: 'LastUsed',
      direction: 'Asc'
    },
    label: 'Last Used (Least Recent First)'
  },
  {
    value: {
      value: 'NameQr',
      field: 'NameQr',
      direction: 'Asc'
    },
    label: 'Name: A-Z'
  },
  {
    value: {
      value: 'NameQr',
      field: 'NameQr',
      direction: 'Desc'
    },
    label: 'Name: Z-A'
  },
  {
    value: {
      value: 'Times',
      field: 'Times',
      direction: 'Desc'
    },
    label: 'Times Used (Most First)'
  },
  {
    value: {
      value: 'Times',
      field: 'Times',
      direction: 'Asc'
    },
    label: 'Times Used (Least First)'
  },
];

export interface QuickReplyQueryResponse {
  data: QuickReply[];
  perPage: number;
  totalCount: number;
  totalPages: number;
}

export interface QuickReplyQuery {
  name: string;
  page?: number;
  perPage?: number;
  sort?: string;
  both?: boolean;
}

export const emptyReply: QuickReply = {
  id: 0,
  name: '',
  body: '',
  used: 0,
  lastUsed: null,
  updated: null,
  created: null,
};

export const getReplies = ({
  name, page = 1, perPage = 1000, sort = 'Last Edited Date (Newest First)',
  both = true,
}: QuickReplyQuery): Promise<QuickReplyQueryResponse> => {
  console.log(sort);
  const sortBy = sortOptions
    .find(({ label }) => label === sort) ||
    sortOptions[0];
  return api.post('quickReply/query', {
    page,
    perPage,
    query: {
      name,
      sortBy: [sortBy.value],
      both,
    },
  });
};

export const saveReply = (reply: QuickReply) => {
  if (reply.id === 0) {
    return api.post('quickReply', reply);
  }
  return api.put(`quickReply/${reply.id}`, reply);
};

export const deleteReply = (reply: QuickReply) => {
  return api.delete(`quickReply/${reply.id}`, { data: {} });
};

export interface ReplaceProps {
  body: string;
  office: Office;
  locations?: Location[];
  professionals?: Professional[];
  appointments?: Appointment[];
  client?: Client;
  html?: boolean;
  forreal?: boolean;
}

export const replacePlaceholders = ({
  body, office, locations = [], professionals = [], appointments = [],
  client, html = false, forreal = false,
}: ReplaceProps): string => {
  const appt = appointments[0]; // next appt
  const pro = professionals
    .find(({ id }) => appt?.appointmentType.professionalId === id);
  const location = locations.find(({ id }) => id === pro?.locationId);
  const btag = html ? '<b>' : '';
  const ebtag = html ? '</b>' : '';
  const defValue = forreal && '____';
  return body
    .replace(/{{client.firstName}}/g, `${btag}${client?.firstName || defValue || 'John'}${ebtag}`)
    .replace(/{{client.lastName}}/g, `${btag}${client?.lastName || defValue || 'Doe'}${ebtag}`)
    .replace(/{{office.name}}/g, `${btag}${office.name}${ebtag}`)
    .replace(/{{office.email}}/g, `${btag}${office.email}${ebtag}`)
    .replace(/{{office.phone}}/g, `${btag}${office.phone}${ebtag}`)
    .replace(/{{office.shortCode}}/g, `${btag}${office.shortId}${ebtag}`)
    .replace(/{{location.name}}/g, `${btag}${location?.name || defValue || 'location name'}${ebtag}`)
    .replace(/{{location.phone}/g, `${btag}${location?.phone || defValue || 'location phone'}${ebtag}`)
    .replace(/{{location.email}/g, `${btag}${location?.email || defValue || 'location email'}${ebtag}`)
    .replace(/{{location.address.street}}/g, `${btag}${location?.address.street || defValue || 'location street'}${ebtag}`)
    .replace(/{{location.address.city}}/g, `${btag}${location?.address.city || defValue || 'location city'}${ebtag}`)
    .replace(/{{location.address.state}}/g, `${btag}${location?.address.state || defValue || 'location state'}${ebtag}`)
    .replace(/{{location.address.zipcode}}/g, `${btag}${location?.address.zipcode || defValue || 'location zipcode'}${ebtag}`)
    .replace(/{{pro.firstName}}/g, `${btag}${pro?.firstName || defValue || 'John'}${ebtag}`)
    .replace(/{{pro.lastName}}/g, `${btag}${pro?.lastName || defValue || 'Doe'}${ebtag}`)
    .replace(/{{appt.date}}/g, `${btag}${appt?.time ? tzParseFormat(appt.time, office.timezone, 'MMM dd, yyyy') : defValue || 'next appt date'}${ebtag}`)
    .replace(/{{appt.time}}/g, `${btag}${appt?.time ? tzParseFormat(appt.time, office.timezone, 'h:mm a') : defValue || 'next appt time'}${ebtag}`)
    .replace(/{{appt.type}}/g, `${btag}${appt?.appointmentType.name || defValue || 'next appt type'}${ebtag}`);
};

export const useQuickReply = (qId: number, mId: number): Promise<void> => {
  return api.post(`quickReply/used/${qId}/${mId}`);
};

export const generateDefaults = (): Promise<any[]> => {
  return Promise.all(defaults.map((b) =>
    api.post('quickReply', b)));
};
