import {
  IntakeForm, SectionChild, FormChild, ComplexWidgetType, SimpleWidgetType,
  IndexAndConds, ComplexWidget, SimpleWidget
} from './intake-types';
import { Conditional, Target, } from './ServerTypes';
import * as at from './intake-forms.actionTypes';
import { remoteAction } from '../../services/actionService.js';
import { saveFormRevision } from './intake.service';
import {
  prepend, flatten, evolve, map, isEmpty, pipe, filter
} from 'ramda';

export const emptyIntakeForm: IntakeForm = {
  id: 1,
  name: '',
  description: '',
  sendNotification: false,
  children: [],
  logoUrl: null,
  logoAlignment: null,
  info: null,
  formId: 0,
  conditionals: [],
};

export interface IntakeFormEditHistory {
  current: IntakeForm,
  currentIndex: number,
  history: IntakeForm[],
}

export const findMaxId = (form: IntakeForm): number => {
  let id = 0;
  for (const [_fci, fc] of (form?.children || []).entries()) {
    id = fc.id > id ? fc.id : id;
    if (fc.type === 'Section' || fc.type === 'PrivateSection') {
      for (const [_sci, sc] of fc.children.entries()) {
        id = sc.id > id ? sc.id : id;
      }
    }
  }
  return id;
};

type ReindexType = 'swap' | 'consecutive';

interface ReindexQuestionsProps {
  conds: Conditional[];
  questionIndex: number;
  amount: number;
  type: ReindexType;
  isQuestion?: boolean;
}

export const reindexQuestions = ({
  conds, questionIndex, amount, type, isQuestion,
}: ReindexQuestionsProps): Conditional[] => {
  if (!questionIndex) {
    return conds;
  }
  const nextQuestionIndex = questionIndex + amount;
  if (type === 'swap') {
    return conds.map((cond: Conditional) => {
      if (cond.questionIndex === questionIndex) {
        return {
          ...cond,
          questionIndex: questionIndex + amount,
        };
      } else if (cond.questionIndex === nextQuestionIndex && isQuestion) {
        return {
          ...cond,
          questionIndex,
        };
      }
      const c: Conditional = evolve({
        target: map((target: Target) => {
          if (target.Question === questionIndex) {
            return {
              Question: target.Question + amount,
            };
          } else if (target.Question === nextQuestionIndex && isQuestion) {
            return {
              Question: questionIndex,
            };
          }
          return target;
        }),
      }, cond);
      return c;
    });
  }
  if (type === 'consecutive') {
    if (amount < 0) {
      return conds.map((cond: Conditional) => {
        return evolve({
          questionIndex: (i) => {
            if (i > questionIndex) {
              return i + amount;
            }
            return i;
          },
          target: pipe(
            filter((target: Target) => {
              return target.Question !== questionIndex;
            }),
            map((target: Target) => {
              if (target.Question > questionIndex) {
                return {
                  Question: target.Question + amount,
                };
              }
              return target;
            })),
        }, cond);
      }).filter(({ target }) => {
        return !isEmpty(target);
      });
    }
    return conds.map((cond: Conditional) => {
      return evolve({
        questionIndex: (i) => {
          if (i > questionIndex) {
            return i + amount;
          }
          return i;
        },
        target: map((target: Target) => {
          if (target.Question > questionIndex) {
            return {
              Question: target.Question + amount,
            };
          }
          return target;
        }),
      }, cond);
    });
  }
};

export const findSimpleWidgetPath = (form: IntakeForm, swid: number): number[] => {
  // Iterate through section
  // Iterate through containers
  // iterate through questions and find by id
  // This is some ugly code, but I couldn't think of
  // another way to do it
  let path;
  for (const [fci, fc] of form.children.entries()) {
    if (path) {
      break;
    }
    if (fc.type === 'Section' || fc.type === 'PrivateSection') {
      const idx = fc.children.findIndex(sc => sc.id === swid);
      if (idx >= 0) {
        path = [fci, idx];
      }
    }
  }
  return path;
};

export const updateSimpleWidget = (widget: SectionChild) => ({
  type: at.FORMS_UPDATE_SIMPLE_WIDGET,
  data: widget,
});

export const deleteSectionChild = (
  id: number, questionIndex: number,
) => ({
  type: at.FORMS_DELETE_SECTION_CHILD,
  data: { id, questionIndex, },
});

export const appendSectionChild = (
  sectionId: number,
  type: SectionChild['type'],
  questionType: SimpleWidgetType['type'],
) => ({
  type: at.FORMS_APPEND_SECTION_CHILD,
  data: {
    sectionId,
    type,
    questionType,
  },
});

export const appendSectionChildWithQuestion = (
  sectionId: number, questionIndex: number, data: SimpleWidget
) => ({
  type: at.FORMS_APPEND_SECTION_CHILD_WITH_QUESTION,
  data: {
    sectionId,
    questionIndex,
    data,
  },
});

export const swapSectionChild = (
  sectionId: number, amount: number, questionIndex: number,
) => ({
  type: at.FORMS_SWAP_SECTION_CHILD,
  data: { sectionId, amount, questionIndex },
});

export const appendComplexWidget = (
  sectionId: number, type: ComplexWidgetType['type']
) => ({
  type: at.FORMS_APPEND_COMPLEX_WIDGET,
  data: {
    sectionId,
    type,
  },
});

export const addSimpleWidgetAfter = (
  widgetId: number, questionIndex: number, data?: SimpleWidget
) => ({
  type: at.FORMS_ADD_SIMPLE_WIDGET_AFTER,
  data: { widgetId, questionIndex, data },
});

export const addSimpleWidgetBefore = (
  widgetId: number, questionIndex: number, data?: SimpleWidget
) => ({
  type: at.FORMS_ADD_SIMPLE_WIDGET_BEFORE,
  data: { widgetId, questionIndex, data },
});


export const deleteFormChild = (
  formChildId: number, type: 'Section' | 'Page', index: number,
) => ({
  type: at.FORMS_DELETE_FORM_CHILD,
  data: { formChildId, type, index },
});

export const swapFormChild = (
  formChildId: number, amount: number
) => ({
  type: at.FORMS_SWAP_FORM_CHILD,
  data: {
    formChildId, amount,
  }
});

export const editRevision = (data: any) => ({
  type: at.FORMS_EDIT_REVISION,
  data,
});
export const editRevisionNoUndo = (data: any) => ({
  type: at.FORMS_EDIT_REVISION_WITHOUT_UNDO,
  data,
});

export const editFormChild = (child: FormChild) => ({
  type: at.FORMS_EDIT_FORM_CHILD,
  data: child,
});

export const createNewFormChild = (id: number, type: FormChild['type']): FormChild => {
  if (type === 'Header') {
    return {
      id,
      width: 12,
      type,
      text: '',
      size: 1,
      isEdit: true,
    };
  }
  if (type === 'Instruction') {
    return {
      id,
      width: 12,
      type,
      text: '',
      isEdit: true,
    };
  }
  if (type === 'Section') {
    return {
      id,
      type,
      children: [],
      name: '',
      description: '',
      isEdit: true,
    };
  }
  if (type === 'PrivateSection') {
    return {
      id,
      type,
      children: [],
      name: '',
      description: '',
      isEdit: true,
    };
  }
  if (type === 'PageBreak') {
    return {
      id,
      width: 12,
      type
    };
  }
};

export const appendFormChild = (type: FormChild['type']) => ({
  type: at.FORMS_APPEND_FORM_CHILD,
  data: { type },
});

export const addFormChildAfter = (
  type: FormChild['type'], id: number, sectionIndex?: number, data?: FormChild
) => ({
  type: at.FORMS_ADD_CHILD_AFTER,
  data: {
    type, id, data, sectionIndex,
  },
});

export const addFormChildBefore = (
  type: FormChild['type'], id: number, sectionIndex?: number, data?: FormChild
) => ({
  type: at.FORMS_ADD_CHILD_BEFORE,
  data: {
    type, id, data, sectionIndex,
  },
});

export const save = (id: number, revision: IntakeForm) => {
  return remoteAction({
    type: at.FORMS_REMOTE_PUT_SAVE,
    action: () => saveFormRevision(id, revision),
  });
};

export const getIndexAndConds = (
  conditionals: Conditional[],
  t: 'page' | 'section' | 'question',
  id: number,
  index: number,
): IndexAndConds => {
  const conds: Conditional[] = [];
  if (t === 'page' || t === 'section') {
    (conditionals || []).forEach((cond) => {
      const isTarget = cond.target.find((tar) => {
        const prop = (t.slice(0, 1).toUpperCase() + t.slice(1)) as unknown as keyof Target;
        return tar[prop] === index;
      });
      if (isTarget) {
        conds.push(cond);
      }
    });
  } else {
    (conditionals || []).forEach((cond) => {
      const isShooter = cond.questionIndex === index;
      if (isShooter) {
        conds.push(cond);
        return;
      }
      const isTarget = cond.target.find(({ Question }) => {
        return Question === index;
      });
      if (isTarget) {
        conds.push(cond);
      }
    });
  }
  return {
    index,
    conditionals: conds,
  };
};

export const getIndex = (
  children: FormChild[], t: 'page' | 'section' | 'question', id: number
): number => {
  let ind = t === 'page' ? 1 : 0;
  children.every((formChild: FormChild) => {
    if (formChild.type === 'Section' && (t === 'section' || t === 'question')) {
      if (t === 'section') {
        ind = ind + 1;
        return id !== formChild.id;
      } else {
        let found = false;
        formChild.children.every((child) => {
          if (child.type === 'SimpleWidget' || child.type === 'ComplexWidget') {
            ind = ind + 1;
          }
          if (id === child.id) {
            found = true;
            return false;
          }
          return true;
        });
        return !found;
      }
    } else if (formChild.type === 'PageBreak' && t === 'page') {
      ind = ind + 1;
      return id !== formChild.id;
    }
    return true;
  });
  return ind;
};

export const inSection = (
  children: FormChild[], sectId: number, questionIndexs: number[]
): boolean => {
  let qind = 0;
  let found = false;
  children.every((formChild: FormChild) => {
    if (formChild.type === 'Section') {
      formChild.children.every((child) => {
        if (child.type === 'SimpleWidget' || child.type === 'ComplexWidget') {
          qind = qind + 1;
        }
        if (sectId === formChild.id && questionIndexs.includes(qind)) {
          found = true;
          return false;
        }
        return true;
      });
      return !found;
    }
    if (formChild.id === sectId && !found) {
      return false;
    }
    return true;
  });
  return found;
};


export const undo = () => ({
  type: at.FORMS_UNDO,
});

export const redo = () => ({
  type: at.FORMS_REDO,
});

export const getAllQuestions = (form: IntakeForm): (SimpleWidget | ComplexWidget)[] => {
  let ind = 0;
  const questions = (form.children || []).filter(({ type }) => type === 'Section')
    .map((formChild) => {
      if (formChild.type === 'Section') {
        return formChild.children
          .filter(({ type }) => type === 'SimpleWidget' ||
                                  type === 'ComplexWidget')
          .map((sectionChild) => {
            if (sectionChild.type === 'SimpleWidget' ||
                   sectionChild.type === 'ComplexWidget') {
              ind = ind + 1;
              const label = sectionChild.type === 'SimpleWidget' ?
                sectionChild.label : sectionChild.typeData.type;
              return {
                ...sectionChild,
                label: `#${ind} ${label}`,
              };
            }
          });
      }
    });
  const zero: SimpleWidget = {
    id: 0,
    type: 'SimpleWidget' as const,
    label: 'Pick a question',
    questionType: {
      type: 'ShortText',
    } as SimpleWidgetType,
    required: false,
    forOffice: false,
    width: 12,
    isEdit: true,
  };
  return prepend(zero, flatten(questions));
};

export const setConditional = (cond: Conditional, ind: number) => ({
  type: at.FORMS_SET_CONDITIONAL,
  data: { cond, ind },
});

export const removeConditional = (ind: number) => ({
  type: at.FORMS_REMOVE_CONDITIONAL,
  data: ind,
});
