import * as R from 'ramda';
import * as at from '../../../../actionTypes';

const init = {
  busy: false,
  plan: {},
  state: 'INFO',
  snackbar: false,
  msgPlan: null,
  usage: [],
  earliestYear: 2020,
  upcoming: {},
  showLimit: false,
  showCredits: false,
  customerId: null,
  sparkStripeUrl: null,
  skedStripeUrl: null,
  paymentAction: false,
};

export default function reducer(subscription = init, action) {
  switch (action.type) {
    case at.BILLING_PATCH: {
      return R.merge(subscription, action.data);
    }
    case at.BILLING_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(subscription, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(subscription, action.data);
      } else if (action.state === 'ERROR') {
        return R.merge(subscription, {
          busy: false,
        });
      }
      return subscription;
    default:
      return subscription;
  }
}
