import * as React from 'react';
import {
  IntakeAnswer, Answer, AnswerDocumentUpload, File,
} from './answer-types';
import {
  mergeRight, type, path, keys, isEmpty,
} from 'ramda';
import { convertToServerAnswerList, saveAnswers } from './answer.service';
import { OfficeNote, SubmissionResponse } from './intake.service';
import { Conditional, Value } from './ServerTypes';
import {
  IntakeForm, SectionChild, ComplexWidget, FormChild,
  SimpleWidget, Section, PageBreak,
} from './intake-types';
import { LocalDate } from '@js-joda/core';
import { parse } from '../../services/joda';

const emptyArray: any = [];
export const AnswerContext = React.createContext(emptyArray);

export const useAnswerContext = () => {
  return React.useContext<AnswerContext>(AnswerContext);
};

export function useIntakeContextAnswer<T extends IntakeAnswer>(id: number, defaultAnswer: T): [T['value'], (v: T['value']) => void] {
  const ctx = useAnswerContext();
  const answer = (ctx.getIntakeValue(id) as T) || defaultAnswer;
  const value = answer.value;
  const setValue = (v: T['value']) => {
    ctx.setValue(id, {
      id,
      type: defaultAnswer.type,
      value: v
    } as T);
  };
  return [value, setValue];
}

interface AppContextProviderProps {
  children: React.ReactNode;
  setIsUpdated: (v: boolean) => void;
}

const setUploadData = (
  setValue: (updateGuys: IntakeValuesMap) => void,
  getValue: (id: number) => IntakeAnswer,
  answers: Answer[]
) => {
  let updateGuys: IntakeValuesMap = {};
  answers.forEach(({ answer, questionId }) => {
    if (answer.DocumentUpload) {
      const question = getValue(questionId);
      if (type(question.value) === 'Array') {
        updateGuys = {
          ...updateGuys,
          [questionId]: {
            id: questionId,
            type: 'DocumentUpload',
            value: answer.DocumentUpload.documents.map((ans: AnswerDocumentUpload, idx): File => {
              const { phiId } = ans;
              const value = path<File>(['value', idx], question);
              if (value) {
                return ({
                  lastModified: value.lastModified,
                  name: value.name,
                  size: value.size,
                  type: value.type,
                  arrayBuffer: value.arrayBuffer,
                  slice: value.slice,
                  stream: value.stream,
                  text: value.text,
                  url: value.url,
                  phiId,
                });
              }
            }),
          },
        };
      }
      if (type(question.value) === 'Object' && question.type === 'Draw') {
        const phiId = answer.DocumentUpload.documents[0].phiId;
        updateGuys = {
          ...updateGuys,
          [questionId]: {
            id: questionId,
            type: 'Draw',
            value: {
              ...question.value,
              phiId,
            },
          },
        };
      }
    }
  });
  setValue(updateGuys);
};


const compareAnswer = (questionValue: Value, answer: IntakeAnswer, condDate: string) => {
  const ctype = keys(questionValue)[0];
  switch (ctype) {
    case 'TextFilter': {
      const compare = keys(questionValue.TextFilter)[0];
      switch (compare) {
        case 'EqualTo':
          return questionValue.TextFilter.EqualTo === answer.value;
        case 'NotEqualTo':
          return questionValue.TextFilter.NotEqualTo !== answer.value;
        case 'Contains':
          if (typeof answer.value === 'string')
            return answer.value.toLowerCase().indexOf(questionValue.TextFilter.Contains.toLowerCase()) > -1;
          return false;
        case 'IsOneOf': {
          if (typeof answer.value === 'string')
            return questionValue.TextFilter.IsOneOf.find((s) => (answer.value as string).toLowerCase().indexOf(s.toLowerCase()) > -1);
          return false;
        }
        case 'IsEmpty': {
          return isEmpty(answer.value);
        }
        case 'IsNotEmpty': {
          return !isEmpty(answer.value);
        }
      }
      break;
    }
    case 'DateFilter': {
      const compare = keys(questionValue.DateFilter)[0];
      const date = condDate ? parse(condDate, 'd') : LocalDate.now();
      if (typeof date !== 'object') {
        return false;
      }
      switch (compare) {
        case 'EqualTo':
          return questionValue.DateFilter.EqualTo === answer.value;
        case 'NotEqualTo':
          return questionValue.DateFilter.NotEqualTo !== answer.value;
        case 'IsBefore': {
          if (typeof answer.value === 'string' && answer.value) {
            const {
              years, months, days
            } = questionValue.DateFilter.IsBefore;
            const c = parse(answer.value, 'd');
            if (typeof c !== 'object') {
              return false;
            }
            const b = date.plusYears(years).plusMonths(months).plusDays(days);
            return c.isBefore(b);
          }
          return false;
        }
        case 'IsAfter': {
          if (typeof answer.value === 'string' && answer.value) {
            const {
              years, months, days
            } = questionValue.DateFilter.IsAfter;
            const c = parse(answer.value, 'd');
            if (typeof c !== 'object') {
              return false;
            }
            const a = date.plusYears(years).plusMonths(months).plusDays(days);
            return c.isAfter(a);
          }
          return false;
        }
        case 'IsBetween': {
          if (typeof answer.value === 'string' && answer.value) {
            // TODO: figure this out
            const b0 = questionValue.DateFilter.IsBetween[0];
            const b1 = questionValue.DateFilter.IsBetween[1];
            const c = parse(answer.value, 'd');
            const b = date.plusYears(b0.years).plusMonths(b0.months).plusDays(b0.days);
            const a = date.plusYears(b1.years).plusMonths(b1.months).plusDays(b1.days);
            return c.isBefore(b) && c.isAfter(a);
          }
          return false;
        }
        case 'IsEmpty': {
          return isEmpty(answer.value);
        }
        case 'IsNotEmpty': {
          return !isEmpty(answer.value);
        }
      }
      break;
    }
    case 'MultiFilter': {
      const compare = keys(questionValue.MultiFilter)[0];
      switch (compare) {
        case 'HasAnyOf': {
          let gottem = false;
          if (answer.type === 'Checkbox') {
            answer.value.every((a: string) => {
              if (questionValue.MultiFilter.HasAnyOf.find((s) => a === s)) {
                gottem = true;
                return false;
              }
              return true;
            });
          }
          return gottem;
        }
        case 'HasAllOf': {
          if (answer.type === 'Checkbox') {
            const gottem = questionValue.MultiFilter.HasAllOf.every((a: string) => {
              return answer.value.find((s) => a === s);
            });
            return gottem;
          }
          return false;
        }
        case 'HasNoneOf': {
          if (answer.type === 'Checkbox') {
            const gottem = answer.value.every((a: string) => {
              return !questionValue.MultiFilter.HasNoneOf.find((s) => a === s);
            });
            return gottem;
          }
          return false;
        }
        case 'EqualTo':
          return questionValue.MultiFilter.EqualTo === answer.value;
        case 'IsEmpty': {
          return isEmpty(answer.value);
        }
        case 'IsNotEmpty': {
          return !isEmpty(answer.value);
        }
      }
      break;
    }
    case 'BoolFilter':
      return questionValue.BoolFilter === answer.value;
    case 'NumberFilter': {
      const compare = keys(questionValue.NumberFilter)[0];
      switch (compare) {
        case 'EqualTo':
          return questionValue.NumberFilter.EqualTo === answer.value;
        case 'NotEqualTo':
          return questionValue.NumberFilter.EqualTo !== answer.value;
        case 'LessThan':
          return questionValue.NumberFilter.LessThan > answer.value;
        case 'GreaterThan':
          return questionValue.NumberFilter.GreaterThan < answer.value;
        case 'LessThanOrEqualTo':
          return questionValue.NumberFilter.LessThanOrEqualTo >= answer.value;
        case 'GreaterThanOrEqualTo':
          return questionValue.NumberFilter.GreaterThanOrEqualTo <= answer.value;
        case 'IsBetween': {
          const sorted = (questionValue.NumberFilter.IsBetween as number[]).sort();
          return answer.value > sorted[0] && answer.value < sorted[1];
        }
      }
      break;
    }
  }
};

let renderedList: {[id: number]: boolean} = {};

/* Check to see if the element is visible for each question. When a
   section isn't shown, the best way to see if it's visible is with
   the `clientWidth` property. It will be 0 if missing.

   Since we don't know how many questions are conditionally rendered
   for each section, page, or question using a while loop formidable.

   If the question is shown, double check to make sure that the value
   of the question meets the condition.
*/
const recursivelyCheckConditions = (
  form: IntakeForm, cond: Conditional, questions: Question[]
): boolean => {
  let recursiveShown = true;
  let done = true;
  let curQuestionIndex = cond?.questionIndex;
  if (curQuestionIndex) {
    const quest = questions && curQuestionIndex && questions[curQuestionIndex - 1];
    const isShown = renderedList[quest?.id];
    recursiveShown = recursiveShown && isShown;
    while (done) {
      const next = form?.conditionals
        .find(({ target }) =>
          target
            .find(({ Question }) => Question === curQuestionIndex));
      if (!next) {
        done = false;
      } else {
        const quest = questions && next?.questionIndex && questions[next?.questionIndex - 1];
        const isShown = renderedList[quest?.id];
        recursiveShown = recursiveShown && isShown;
        curQuestionIndex = next?.questionIndex;
      }
    }
    return recursiveShown;
  }
  return true;
};

type Question = ComplexWidget | SimpleWidget;
interface IntakeValuesMap { [key: number]: IntakeAnswer }

interface AnswerContext {
  wipeRenderData: () => void;
  setValue: (id: number, value: IntakeAnswer) => void;
  getValue: (id: number) => any;
  getIntakeValue: (id: number) => IntakeAnswer;
  getMetadata: (id: number) => any;
  setMetadata: (id: number, metadata: any) => void;
  setAll: (a: IntakeAnswer[], form: IntakeForm, subres: SubmissionResponse) => void;
  setIsUpdated: (v: boolean) => void;
  autoSave: (immediately?: boolean) => void;
  setSubId: (id: number) => void;
  getBlock: () => boolean;
  setNotes: (a: OfficeNote[]) => void;
  getNote: (id: number) => OfficeNote;
  checkCondition: (t: 'Page' | 'Section' | 'Question', id: number) => boolean;
  notes?: OfficeNote[];
}

let tempBuffer: IntakeValuesMap = {};
let timeout: any = null;
export const AnswerContextProvider = ({ children, setIsUpdated }: AppContextProviderProps) => {
  const [formValues, setValue] = React.useState<IntakeValuesMap>({});
  const [subId, setSubId] = React.useState<number>(0);
  const [tempUploads, setTempUploads] = React.useState<IntakeValuesMap>({});
  // console.log(formValues);
  const [block, setBlock] = React.useState<boolean>(false);
  const [notes, setNotes] = React.useState<OfficeNote[]>([]);
  const [form, setForm] = React.useState<IntakeForm>(null);
  const [pages, setPages] = React.useState<PageBreak[]>(null);
  const [sections, setSections] = React.useState<Section[]>(null);
  const [questions, setQuestions] = React.useState<Question[]>(null);
  const [condDate, setCondDate] = React.useState<string>(null);
  const defaultValue: AnswerContext = {
    wipeRenderData: () => {
      renderedList = {};
    },
    setValue: (id: number, value: IntakeAnswer): void => {
      tempBuffer = { ...tempBuffer, [id]: mergeRight(value, { metadata: formValues[id]?.metadata }) };
      console.log('TEMP:', id, tempBuffer);
      setBlock(true);
      setValue({
        ...formValues,
        ...tempUploads,
        ...tempBuffer,
      });
      setTempUploads({});
    },
    getValue: (id: number): any => {
      return formValues[id]?.value;
    },
    getIntakeValue: (id: number): any => {
      return formValues[id];
    },
    getMetadata: (id: number): any => {
      return formValues[id]?.metadata;
    },
    setMetadata: (id: number, metadata: any): void => {
      const newValues = {
        ...formValues,
        [id]: {
          ...formValues[id],
          metadata,
        }
      };
      tempBuffer = {
        ...tempBuffer, [id]: {
          ...formValues[id],
          metadata,
        }
      };
      setValue(newValues);
    },
    setAll: (answers: IntakeAnswer[], form: IntakeForm, subres: SubmissionResponse) => {
      const aMap = answers.reduce<IntakeValuesMap>((acc, a) => {
        return {
          ...acc,
          [a.id]: a
        };
      }, {});
      setForm(form);
      setCondDate((subres.submitted || subres.updated || '').split('T')[0] || null);
      const p: PageBreak[] = [], s: Section[] = [], q: Question[] = [];
      p.push(null);
      form.children.forEach((pg: FormChild) => {
        if (pg.type === 'PageBreak') {
          p.push(pg);
        }
        if (pg.type === 'Section') {
          s.push(pg);
          pg.children.forEach((sc: SectionChild) => {
            if (sc.type === 'SimpleWidget' || sc.type === 'ComplexWidget') {
              q.push(sc);
            }
          });
        }
      });
      setPages(p);
      setSections(s);
      setQuestions(q);
      setTimeout(() => {
        setValue(aMap);
      }, 200);
    },
    setIsUpdated,
    autoSave: (immediately?: boolean): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        tempBuffer = {};
        const answers = Object.values(formValues);
        // console.log(answers);
        convertToServerAnswerList(answers, subId)
          .then((serverAnswers) => {
            setBlock(false);
            setUploadData(
              (uploadGuys: IntakeValuesMap) => {
                setTempUploads(uploadGuys);
              },
              (id: number) => formValues[id],
              serverAnswers
            );
            return saveAnswers({
              answers: serverAnswers,
              subId,
            });
          });
      }, immediately ? 1 : 500);
    },
    setSubId: (id: number) => {
      setSubId(id);
    },
    getBlock: () => block,
    setNotes: (notes: OfficeNote[]) => setNotes(notes),
    getNote: (id: number) => {
      const note = notes.find(({ questionId }) => questionId === id);
      if (note) {
        return note;
      }
      return null;
    },
    checkCondition: (t: 'Section' | 'Question' | 'Page', id: number) => {
      switch (t) {
        case 'Page': {
          let index = 0;
          pages?.every((page, idx) => {
            index = idx + 1;
            return id !== page?.id;
          });
          const cond = form?.conditionals
            .find(({ target }) =>
              target
                .find(({ Page }) => Page === index));
          const question = questions && questions[(cond?.questionIndex || 1) - 1];
          const answer = formValues[(question?.id || 0)];
          if (answer && answer.value && cond && cond.value) {
            const shown = compareAnswer(cond.value, answer, condDate);
            const recursiveShown = recursivelyCheckConditions(form, cond, questions);
            return shown && recursiveShown;
          }
          return recursivelyCheckConditions(form, cond, questions);
        }
        case 'Section': {
          let index = 0;
          sections?.every((sect, idx) => {
            index = idx + 1;
            return id !== sect.id;
          });
          const cond = form?.conditionals
            .find(({ target }) =>
              target
                .find(({ Section }) => Section === index));
          const question = questions && questions[(cond?.questionIndex || 1) - 1];
          const answer = formValues[(question?.id || 0)];
          if (answer && answer.value && cond && cond.value) {
            const shown = compareAnswer(cond.value, answer, condDate);
            const recursiveShown = recursivelyCheckConditions(form, cond, questions);
            return shown && recursiveShown;
          }
          return recursivelyCheckConditions(form, cond, questions);
        }
        case 'Question': {
          let index = 0;
          questions?.every((quest, idx) => {
            index = idx;
            return id !== quest.id;
          });
          const cond = form?.conditionals
            .find(({ target }) =>
              target
                .find(({ Question }) => Question === index + 1));
          const question = questions && questions[(cond?.questionIndex || 1) - 1];
          const answer = formValues[(question?.id || 0)];
          let gonnashow = true;
          if (answer && answer.value !== undefined && cond && cond.value) {
            const shown = compareAnswer(cond.value, answer, condDate);
            const recursiveShown = recursivelyCheckConditions(form, cond, questions);
            gonnashow = shown && recursiveShown;
          }
          renderedList = {
            ...renderedList,
            [id]: gonnashow,
          };
          return gonnashow;
        }
      }
    },
    notes,
  };

  return (
    <AnswerContext.Provider value={defaultValue}>
      {children}
    </AnswerContext.Provider>
  );
};
