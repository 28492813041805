import * as React from 'react';
import {
  Typography, Paper, Grid, GridSize,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ContainerComponent } from './AnswerSimpleWidgetContainer.component';
import { RadioGridComponent } from './AnswerRadioGrid.component';
import { TableComponent } from './AnswerTable.component';
import { FileUploadComponent } from './AnswerFileUpload.component';
import { SignatureComponent } from './AnswerSignature.component';
import { DrawablePictureComponent } from './AnswerDrawablePicture.component';
import { ConsentPapersComponent } from './AnswerConsentPapers.component';
import {
  FormChild, Instruction, Header, Section, SectionChild, ComplexWidget
} from '../intake-types';
import { OfficeNote, updateOfficeNote } from '../intake.service';
import { usePromise } from './../../../services/promise.hook';
import { isNil, isEmpty, last, equals } from 'ramda';
import { useAnswerContext } from '../answers.context';

interface ComplexWidgetProps {
  data: ComplexWidget;
  printMode: boolean;
  isPrivate: boolean;
}

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#008BCF',
    },
    background: {
      default: 'rgb(35, 48, 68)',
      paper: '#fff'
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '13px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 'unset',
          padding: '0px 16px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: '16px',
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)',
          minWidth: '40px',
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '25px',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '16px'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          '&.Mui-disabled': {
            color: 'unset',
          },
          pageBreakInside: 'avoid',
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          pageBreakInside: 'avoid',
        },
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: 0.5,
          '&.Mui-disabled': {
            color: 'unset',
          },
          pageBreakInside: 'avoid',
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          pageBreakInside: 'avoid',
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        marginNormal: {
          marginTop: 0,
          marginBottom: 0,
        },
        root: {
          '&.Mui-disabled': {
            color: 'unset',
          },
          pageBreakInside: 'avoid',
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: 'unset',
          },
          pageBreakInside: 'avoid',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '14px',
        },
        body2: {
          fontSize: '12px',
        },
        h4: {
          fontSize: '22px',
        },
        h6: {
          fontWeight: 'unset',
          fontSize: '22px',
        },
        caption: {
          fontSize: '12px',
        },
        subtitle1: {
          fontSize: '12px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'primary',
          minWidth: '88px',
          '&.Mui-disabled': {
            color: 'unset',
          }
        },
        outlinedSizeSmall: {
          fontSize: '12px'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid lightgray',
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        dividers: {
          padding: '0px 16px 24px 16px',
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        root: {
          width: '256px',
          pageBreakInside: 'avoid',
        },
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'primary'
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#008BCF',
          },
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            color: '#008BCF',
          },
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          float: 'none',
        },
        item: {
          display: 'block',
          pageBreakInside: 'avoid',
        },
      }
    },
  },
  // props: {
  //   MuiCheckbox: {
  //     color: 'primary',
  //   },
  //   MuiButton: {
  //     color: 'primary',
  //   },
  //   MuiRadio: {
  //     color: 'primary',
  //   }
  // }
});

const ComplexWidgetComponent = ({ data, printMode, isPrivate }: ComplexWidgetProps) => {
  if (data.typeData.type === 'RadioGrid') {
    return (
      <RadioGridComponent
        id={data.id}
        data={data.typeData}
        required={data.required}
        isPrivate={isPrivate}
      />
    );
  }
  if (data.typeData.type === 'FixedTable') {
    return (
      <TableComponent
        id={data.id}
        data={data.typeData}
        required={data.required}
        isPrivate={isPrivate}
      />
    );
  }
  if (data.typeData.type === 'FlexibleTable') {
    return (
      <TableComponent
        id={data.id}
        data={data.typeData}
        required={data.required}
        isPrivate={isPrivate}
      />
    );
  }
  if (data.typeData.type === 'FileUpload') {
    return (
      <FileUploadComponent
        id={data.id}
        data={data.typeData}
        required={data.required}
        isPrivate={isPrivate}
      />
    );
  }
  if (data.typeData.type === 'Signature') {
    return (
      <SignatureComponent
        id={data.id}
        data={data.typeData}
        required={data.required}
        isPrivate={isPrivate}
      />
    );
  }
  if (data.typeData.type === 'DrawablePicture') {
    return <DrawablePictureComponent
      id={data.id}
      data={data.typeData}
      required={data.required}
      printMode={printMode}
      isPrivate={isPrivate}
    />;
  }
  if (data.typeData.type === 'ConsentPapers') {
    return (
      <ConsentPapersComponent
        id={data.id}
        data={data.typeData}
        required={data.required}
        isPrivate={isPrivate}
      />
    );
  }
  return <div>{data.type} not done yet</div>;
};

interface SectionChildProps {
  data: SectionChild;
  printMode: boolean;
  isPrivate?: boolean;
}
const SectionChildComponent = ({ data, printMode, isPrivate }: SectionChildProps) => {
  if (data.type === 'Header') {
    return <HeaderComponent data={data} printMode={printMode} />;
  }
  if (data.type === 'SimpleWidget') {
    return (
      <ContainerComponent
        data={data}
        isPrivate={isPrivate}
      />
    );
  }
  if (data.type === 'ComplexWidget') {
    return (
      <ComplexWidgetComponent
        data={data}
        printMode={printMode}
        isPrivate={isPrivate}
      />
    );
  }
  return <div>{data.type} not done yet</div>;
};


interface SectionProps {
  data: Section;
  printMode: boolean;
  subId: number;
  isPrivate?: boolean;
}

let noteTimeout: any = null;
const SectionComponent = ({ data, printMode, subId, isPrivate }: SectionProps) => {
  const ctx = useAnswerContext();
  const show = ctx.checkCondition('Section', data.id);
  const {
    officeNote,
    questionId,
  } = React.useMemo(() => {
    const qs = data.children.filter(({ type }) => type === 'ComplexWidget' || type === 'SimpleWidget');
    const lastQuestion = last(qs);
    if (!lastQuestion) {
      return {};
    }
    if (lastQuestion.type === 'ComplexWidget' || lastQuestion.type === 'SimpleWidget') {
      const n = ctx.getNote(lastQuestion.id);
      if (n) {
        return n;
      }
      return {
        questionId: lastQuestion.id,
        officeNote: '',
      };
    }
  }, [data.children, ctx.notes?.length]);
  const [note, setNote] = React.useState(officeNote);
  const [open, setOpen] = React.useState(Boolean(officeNote));
  const [firstTime, setFirstTime] = React.useState(true);
  const officeNoteState = usePromise<OfficeNote, void>(updateOfficeNote, null);
  const handleUpdate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setNote(value);
    if (noteTimeout) {
      clearTimeout(noteTimeout);
    }
    noteTimeout = setTimeout(() => {
      officeNoteState.invoke({
        questionId,
        subId,
        officeNote: value,
      }).then(() => {
        ctx.setIsUpdated(true);
      });
    }, 500);
  };
  React.useEffect(() => {
    if ((note === '' || isNil(note)) && firstTime &&
        !isNil(officeNote) && !isEmpty(officeNote)) {
      setNote(officeNote);
      setFirstTime(false);
      setOpen(true);
    }
  }, [officeNote]);

  if (printMode) {
    return (
      <ThemeProvider theme={muiTheme}>
        <div style={{
          width: '100%',
        }}>
          {data.name &&
            <div dangerouslySetInnerHTML={{
              __html: data.name,
            }} />}
          {data.description &&
            <div dangerouslySetInnerHTML={{
              __html: data.description,
            }} />}
          <Grid container spacing={1}>
            {data.children.map((child) =>
              <Grid
                key={child.id}
                item
                xs={12}
                sm={child.width as GridSize}
                style={{
                  flexGrow: 0,
                  maxWidth: (child.width / 12 * 100) + '%',
                  flexBasis: (child.width / 12 * 100) + '%',
                  display: show ? 'block' : 'none',
                }}
              >
                <div id={String(child.id)}>
                  <SectionChildComponent data={child} printMode={true}/>
                </div>
              </Grid>)}
          </Grid>
          {note && !isNil(officeNote) &&
            <TextField
              style={{
                width: '100%',
                marginBottom: '20px',
                color: 'red',
                backgroundColor: '#dedcdc',
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: 'black',
                }
              }}
              InputProps={{
                style: {
                  color: 'red',
                }
              }}
              value={note}
              multiline
              minRows={4}
              variant="outlined"
              onChange={handleUpdate}
              type="text"
              label="Office Notes"
              placeholder='Write something interesting here...'
            />}
        </div>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={muiTheme}>
      <div>
        <Paper style={{
          marginBottom: '20px',
          padding: '10px',
          backgroundColor: isPrivate ? '#008bcf66' : '#fff',
          display: show ? 'block' : 'none',
        }}>
          {data.name &&
          <div dangerouslySetInnerHTML={{ __html: data.name }} />}
          {data.description &&
          <div dangerouslySetInnerHTML={{ __html: data.description }} />}
          <Grid container spacing={1} style={{
            marginBottom: '15px',
          }}>
            {data.children.map((child) => {
              const show = ctx.checkCondition('Question', child.id);
              return (
                <Grid
                  key={child.id}
                  item
                  xs={12}
                  sm={child.width as GridSize}
                  style={{ display: show ? 'block' : 'none' }}>
                  <div id={String(child.id)}>
                    <SectionChildComponent
                      data={child}
                      printMode={false}
                      isPrivate={isPrivate}
                    />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
        {!open &&
        <a
          style={{
            alignItems: 'center',
            marginBottom: '20px',
            width: 'fit-content',
            display: show ? 'flex' : 'none',
          }}
          onClick={() => {
            setOpen(true);
            setNote('');
          }}>
          <AddIcon style={{ fontSize: '16px' }}/>
          Add Notes
        </a>}
        {!isNil(note) && open &&
        <TextField
          style={{
            width: '100%',
            marginBottom: '20px',
            color: 'red',
            backgroundColor: '#dedcdc',
            display: show ? 'inline-flex' : 'none',
          }}
          InputLabelProps={{
            shrink: true,
            style: {
              color: 'black',
            }
          }}
          InputProps={{
            style: {
              color: 'red',
            }
          }}
          value={note || ''}
          multiline
          minRows={4}
          variant="outlined"
          onChange={handleUpdate}
          type="text"
          label="Office Notes"
          placeholder='Write something interesting here...'
        />}
      </div>
    </ThemeProvider>
  );
};

interface InstructionProps {
  data: Instruction,
  printMode: boolean;
}
const InstructionComponent = ({ data }: InstructionProps) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: data.text }} />
  );
};

interface HeaderProps {
  data: Header;
  printMode: boolean;
}
const HeaderComponent = ({ data }: HeaderProps) => {
  if (data.size === 1) {
    return (
      <div dangerouslySetInnerHTML={{ __html: data.text }} />
    );
  }
  if (data.size === 2) {
    return (<div>
      <Typography variant="subtitle1" component="h2" gutterBottom>
        {data.text}
      </Typography>
    </div>);
  }
  return (<div>
    <Typography variant="h6" component="h2" gutterBottom>
      {data.text}
    </Typography>
  </div>);
};

interface FormChildProps {
  child: FormChild;
  printMode: boolean;
  subId: number;
}

export const AnswerFormChildInner = ({ child, printMode, subId }: FormChildProps) => {
  if (child.type === 'Header') {
    return <HeaderComponent data={child} printMode={printMode} />;
  }
  if (child.type === 'Instruction') {
    return <InstructionComponent data={child} printMode={printMode} />;
  }
  if (child.type === 'Section') {
    return <SectionComponent data={child} printMode={printMode} subId={subId} />;
  }
  if (child.type === 'PrivateSection') {
    return <SectionComponent data={child} printMode={printMode} subId={subId} isPrivate />;
  }
  return <div />;
};

export const AnswerFormChild = React.memo(AnswerFormChildInner, (prev, next) => {
  return equals(prev.child, next.child);
});
