import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Snackbar,
  CircularProgress,
  Tooltip,
  Button,
  TableHead,
  TableBody,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PopupTemplate } from '../../services/Popup.js';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  merge, map, pipe, sortBy,
  prop, isEmpty, append, includes,
  without, uniqBy, pathOr, mapObjIndexed,
  values, reduce, and, propOr,
} from 'ramda';
import { usePromise } from '../../services/promise.hook';
import { caseType } from '../../services/utilities.js';
import { tzParseFormat, } from '../../services/joda.js';
import api from '../../services/api.js';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import { getSettings, getInitial } from './waiting.actions.js';
import { SettingsPopup } from './components/settings.component.jsx';
import Header from '../../components/PageHeader/PageHeader.component';
import SelectProfessional from '../../components/SelectProfessional/SelectProfessional.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';
import FilterText from '../../components/FilterText/FilterText.component';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import { TableContainer, TableRow, HeaderCell, BodyCell } from '../../components/CustomTable';
import GearIcon from '../../icons/Settings.icon';
import { getFrontDeskEvents } from './waiting.service';
import { useSocket } from '../../services/websocket.hook.js';

const ehrList = ['Platinum', 'ChiroTouch', 'Genesis', 'ChiroHD'];

const filterOptions = [
  { value: 'all', label: 'All' },
  { value: 'ehr', label: 'EHR Only' },
  { value: 'sked', label: 'SKED Only' },
];

const nonce = () => null;
const ContactInfo = ({ client }) => {
  const data = [
    { title: 'Birthday', value: client.birthday },
    { title: 'Phone', value: client.phone },
    { title: 'Email', value: client.email },
  ].filter(({ value }) => !!value);
  return (
    <React.Fragment>
      {data.map(({ title, value }) => (
        <div key={value}>
          {title}: {value}
        </div>))}
    </React.Fragment>
  );
};

const ClientAptsTableFunctional = ({
  listId,
  updateSnack,
  initial,
  eventSource,
  tz,
  search,
  sourceFilter,
  professionalId,
  socket,
  headerHeight,
}) => {
  const [state, setState] = useState({
    status: 'INIT',
    selected: [],
    clientApts: []
  });
  const update = data => setState(s => merge(s, data));

  const { selected, clientApts } = state;

  const filteredClientApts = clientApts.filter((item) => {
    const { client, from, appointmentType } = item;
    const lowerCaseClient = {
      lastName: propOr('', 'lastName', client).toLowerCase(),
      firstName: propOr('', 'firstName', client).toLowerCase(),
      phone: propOr('', 'phone', client).toLowerCase(),
    };
    const query = mapObjIndexed((s) => {
      if (s) {
        return s.toLowerCase().trim();
      }
      return undefined;
    }, search);
    const containsName = pipe(
      mapObjIndexed((s, key) => {
        if (s) {
          return lowerCaseClient[key].split(s).length > 1;
        }
        return true;
      }),
      values,
      reduce(and, true),
    )(query);
    const hasProFilter = professionalId === 0 || professionalId === appointmentType.professionalId;
    const hasSourceFilter = sourceFilter === 'all' || sourceFilter === from;

    return containsName && hasSourceFilter && hasProFilter;
  });

  React.useEffect(() => {
    const filter = localStorage.getItem('sked-waiting-room-filter');
    update({ filter });
  }, []);

  React.useEffect(() => {
    update({ clientApts: initial, status: 'WAITING' });
  }, [state.status, initial]);

  React.useEffect(() => {
    const eventListener = (event) => {
      const {
        appointment,
        arrivedAt,
        client,
        appointmentType,
        source,
      } = event.data ? JSON.parse(event.data) : event;
      const from = includes(source, ehrList) ? 'ehr' : 'sked';
      const clientApt = merge(appointment, { arrivedAt, client, appointmentType, from });
      const newList = pipe(
        append(clientApt),
        uniqBy(prop('id'))
      )(state.clientApts);
      update({ clientApts: newList });
    };
    const handleSocketUpdate = (event) => {
      const parsed = JSON.parse(event.data);
      if (parsed.tag === 'WaitingRoom') {
        if (parsed.contents.tag.toLowerCase() === listId) {
          eventListener(parsed.contents.contents);
        } else {
          const apptId = pathOr(0, ['contents', 'contents', 'appointment', 'id'], parsed);
          update({
            clientApts: clientApts.filter(({ id }) => id !== apptId),
          });
        }
      }
    };
    if (eventSource) {
      eventSource.addEventListener(listId, eventListener);
      return () => {
        eventSource.removeEventListener(listId, eventListener);
      };
    }
    if (!socket) {
      return () => null;
    }
    socket.addEventListener('message', handleSocketUpdate);
    return () => {
      socket.removeEventListener('message', handleSocketUpdate);
    };
  }, [eventSource, clientApts, socket]);

  const notify = (ids) => {
    const requests = ids.map((id) => api.post('/waitingroom/notify', { apptId: id }));
    Promise.all(requests).then(() => {
      updateSnack('Successfully notified clients!');
      if (listId === 'checkedin') {
        const withoutNotified = state.clientApts.filter(({ id }) => {
          return !includes(id, ids);
        });
        update({
          clientApts: withoutNotified,
          selected: [],
        });
      } else {
        update({ selected: [] });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const arrive = (ids) => {
    const requests = ids.map((id) => api.post('/waitingroom/arrived', { apptId: id }));
    Promise.all(requests).then(() => {
      updateSnack('Successfully marked as arrived clients!');
      const withoutNotified = state.clientApts.filter(({ id }) => {
        return !includes(id, ids);
      });
      update({
        clientApts: withoutNotified,
        selected: [],
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <TableContainer
      style={{ marginTop: 0 }}
      maxHeight={`calc(100vh - ${headerHeight || 0}px - 50px)`}
    >
      <TableHead>
        <TableRow
          hasSelect={listId !== 'arrived'}
          noSelectHover
          checked={filteredClientApts?.length && selected.length === filteredClientApts?.length}
          someChecked={selected.length > 0 && selected.length < filteredClientApts?.length}
          setChecked={(checked) => {
            if (checked) {
              update({ selected: filteredClientApts.map(({ id }) => id) });
            } else {
              update({ selected: [] });
            }
          }}
        >
          <HeaderCell style={{ position: 'relative' }}>
            {!isEmpty(selected) && (listId === 'checkedin' || listId === 'notified') ? (
              <div style={{ position: 'absolute ', top: '2px', background: '#F5F5F5' }}>
                <Button variant="contained" size="small" onClick={() => notify(selected)}>
                Notify
                </Button>
              &nbsp;&nbsp;
                <Button variant="contained" size="small" onClick={() => arrive(selected)}>
                Arrive
                </Button>
              </div>
            ) : 'Name'}
          </HeaderCell>
          <HeaderCell>Appointment Time</HeaderCell>
          <HeaderCell>Appointment Type</HeaderCell>
          <HeaderCell>Arrival Time</HeaderCell>
          <HeaderCell>Source</HeaderCell>
          <HeaderCell></HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredClientApts.map(({ id, time, appointmentType, client, arrivedAt, from }) => (
          <TableRow
            key={id}
            hasSelect={listId !== 'arrived'}
            checked={includes(id, selected)}
            setChecked={(checked) => {
              if (checked) {
                update({ selected: append(id, selected) });
              } else {
                update({ selected: without([id], selected) });
              }
            }}
          >
            <BodyCell>
              <Tooltip title={<ContactInfo client={client} />}>
                <span>{client.firstName} {client.lastName} {caseType(client)}</span>
              </Tooltip>
            </BodyCell>
            <BodyCell>{tzParseFormat(time, tz, 'h:mm a')}</BodyCell>
            <BodyCell>{appointmentType.internalName + ' - ' + appointmentType.name}</BodyCell>
            <BodyCell>{tzParseFormat(arrivedAt, tz, 'h:mm a')}</BodyCell>
            <BodyCell>{from.toUpperCase()}</BodyCell>
            <BodyCell>
              {(listId === 'checkedin' || listId === 'notified') && <React.Fragment>
                <Button style={{ margin: '3px' }} variant="contained" size="small" onClick={() => notify([id])}>
                  Notify
                </Button>
                <Button style={{ margin: '3px' }} variant="contained" size="small" onClick={() => arrive([id])}>
                  Arrive
                </Button>
              </React.Fragment>}
            </BodyCell>
          </TableRow>
        ))}
      </TableBody>
    </TableContainer >
  );

};

const useStyles = makeStyles(() => ({
  newRoot: {
    margin: '20px'
  },
  listControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
}));

const WaitingRoomFunctional = ({
  doNothing,
  getSettings,
  settings,
  office,
  tz,
  isEnabled,
  busy,
  hasTimeBased,
  headerHeight,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    status: null,
    checkedIn: [],
    notified: [],
    arrived: [],
    settingsOpen: false,
    professionalId: 0,
    searchText: '',
    search: {
      lastName: '',
      firstName: '',
      phone: '',
    },
    sourceFilter: 'all',
    snackbar: {
      isOpen: false,
      memo: '',
    },
    tab: 'in',
    events: null
  });
  const update = data => setState(s => merge(s, data));

  const initialState = usePromise(getInitial, {});

  const proState = usePromise(() => api.get('/professional'), []);

  const handleClickOpen = () => {
    update({ settingsOpen: true });
  };
  const handleClose = () => {
    update({ settingsOpen: false });
  };

  const initData = () => {
    initialState.invoke().then(data => {
      const create = pipe(
        map(({
          appointment,
          arrivedAt,
          client,
          appointmentType,
          source,
        }) => {
          const from = includes(source, ehrList) ? 'ehr' : 'sked';
          return merge(appointment, { arrivedAt, client, appointmentType, from });
        }),
        sortBy(prop('arrivedAt')),
        uniqBy(prop('id'))
      );
      const checkedIn = create(data.checkedIn);
      const notified = create(data.notified);
      const arrived = create(data.arrived);
      update({ checkedIn, notified, arrived });
    });
  };

  const socket = useSocket();

  useEffect(() => {
    doNothing({ page: 'waiting' });
    getSettings();
    proState.invoke();
    window.document.title = 'Virtual Check-in';
  }, [office.id]);

  useEffect(() => {
    if (!settings.geofenceSetting || settings.geofenceSetting === 'Off') {
      return;
    }
    initData();
    const events = getFrontDeskEvents();
    update({ events });
    return () => null;
  }, [office.id, settings.geofenceSetting]);


  const {
    checkedIn,
    notified,
    arrived,
    snackbar,
    tab,
    events
  } = state;

  if (!isEnabled) {
    return (
      <FeatureDisabled title="Virtual Check-in">
        <p>
          Patients can check-in when they are within 200 yards of the office and your front desk is notified!
        </p>
      </FeatureDisabled>
    );
  }

  if (settings.geofenceSetting !== 'CheckIn') {
    return (
      <>
        <Header
          title='Virtual Check-in'
          pageId='waiting'
          rightIcons={[
            <HeaderButton onClick={handleClickOpen} Icon={GearIcon} onlyIcon />
          ]}
        />
        <div className={classes.newRoot}>
          {state.settingsOpen &&
            <SettingsPopup
              settings={settings}
              open={state.settingsOpen}
              handleClose={handleClose}
              hasTimeBased={hasTimeBased}
            />}

          {busy && <CircularProgress />}
          {!busy && settings.geofenceSetting === 'Barcode' && <>
            <p>
              Your virtual check in is set up with "Quick Barcode Notification".  This setting will give your clients a notification when they get to your office to open the barcode quickly
            </p>
            <p>If you want patients to check-in with the app when they get with in 200 yards of your office, <a onClick={handleClickOpen}>click here</a> to set that up.</p>
          </>}
          {!busy && settings.geofenceSetting === 'Off' && <>
            <p>
              Patients can check-in when they are within 200 yards of the office and your front desk is notified!
            </p>

            <p> <a onClick={handleClickOpen}>Click here</a> to set up virtual check-in.</p>

          </>}
        </div>
      </>
    );
  }

  return (
    <>
      <Header
        title='Virtual Check-in'
        pageId='waiting'
        leftIcons={[
          <HeaderTabs
            tabs={[
              { value: 'in', label: 'Checked-In' },
              { value: 'notified', label: 'Notified' },
              { value: 'arrived', label: 'Arrived' }
            ]}
            value={tab}
            setTab={(val) => update({ tab: val })}
          />
        ]}
        rightIcons={[
          <FilterText
            headerTitle='Search'
            placeholder='LastName FirstName'
            value={state.searchText}
            onChange={(str) => {
              const splitUp = str.split(' ');
              const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
              const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
              const phone = splitUp[2] ? splitUp[2].trim() : undefined;
              update({
                searchText: str,
                search: {
                  lastName,
                  firstName,
                  phone,
                },
              });
            }}
          />,
          <SelectProfessional
            allProfessionals={proState.data}
            selectedProId={state.professionalId}
            setPropId={(id) => update({ professionalId: id })}
            noActionButton
            showNameAndDisplayName
            title='Professional'
            addAll
          />,
          <FilterSelect
            title='Source'
            noActionButton
            noneText='All'
            type='filter'
            Icon={FilterAltIcon}
            items={filterOptions.map(item => item.label)}
            selected={filterOptions.find(item => item.value === state.sourceFilter).label}
            setSelected={value => {
              const sourceFilter = filterOptions.find(item => item.label === value).value;
              update({ sourceFilter });
            }}
          />,
          <HeaderButton onClick={handleClickOpen} Icon={GearIcon} onlyIcon />
        ]}
        onlyIconsWidth={848}
        breakPoints={[
          {
            width: 700,
            mobileItems: [2, 3, 4]
          },
          {
            width: 616,
            mobileItems: [0]
          }
        ]}
      />
      <div className="waiting" style={{ paddingTop: 0 }}>
        {state.settingsOpen &&
          <SettingsPopup
            settings={settings}
            open={state.settingsOpen}
            handleClose={handleClose}
            hasTimeBased={hasTimeBased}
          />}
        <PopupTemplate />
        <Snackbar
          open={snackbar.isOpen}
          message={snackbar.memo}
          autoHideDuration={4000}
          onClose={() => update({ snackbar: { isOpen: false, memo: '' } })} />
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <div style={{ marginLeft: '-20px', marginRight: '-20px', marginTop: 0 }}>
            <div hidden={tab !== 'in'}>
              <ClientAptsTableFunctional
                search={state.search}
                professionalId={state.professionalId}
                sourceFilter={state.sourceFilter}
                listId='checkedin'
                eventSource={events}
                socket={socket}
                initial={checkedIn}
                tz={tz}
                headerHeight={headerHeight}
                updateSnack={(memo) => update({ snackbar: { isOpen: true, memo } })}
              />
            </div>
            <div hidden={tab !== 'notified'}>
              <ClientAptsTableFunctional
                listId='notified'
                search={state.search}
                professionalId={state.professionalId}
                sourceFilter={state.sourceFilter}
                eventSource={events}
                socket={socket}
                initial={notified}
                tz={tz}
                headerHeight={headerHeight}
                updateSnack={(memo) => update({ snackbar: { isOpen: true, memo } })}
              />
            </div>
            <div hidden={tab !== 'arrived'}>
              <ClientAptsTableFunctional
                listId='arrived'
                search={state.search}
                professionalId={state.professionalId}
                sourceFilter={state.sourceFilter}
                eventSource={events}
                socket={socket}
                initial={arrived}
                tz={tz}
                headerHeight={headerHeight}
                updateSnack={nonce}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state.waiting,
  tz: state.login.office.timezone,
  office: state.login.office,
  headerHeight: state.login.headerHeight,
  isEnabled: includes('VWRoom', pathOr([], ['login', 'features'], state)),
  hasTimeBased: includes('TimeBasedCheckIn', pathOr([], ['login', 'features'], state)),
});

const mapDispatchToProps = dispatch => ({
  /*
    Due to some issues with `react-router` and `redux`, you have to do
    something with with redux inside of `componentDidMount` for react-router
    to mark the page as "active." This will cause a bug in the nav bar where
    it won't highlight the active page.
   */
  doNothing: (d) => dispatch({
    type: 'PAGE_DO_NOTHING',
    data: d
  }),
  getSettings: () => dispatch(getSettings())
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitingRoomFunctional);
