import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const PasteIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 4.18524C11.9997 4.57635 11.9996 4.96746 11.9996 5.35857C11.9996 5.65745 11.9996 5.95633 11.9996 6.25522C11.9997 7.23406 11.9997 8.21291 11.9996 9.19175C11.9995 9.8497 11.7754 10.1759 11.3282 10.1698C10.8837 10.1638 10.6714 9.84327 10.6714 9.17712L10.6713 2.42481C10.6713 1.45821 10.566 1.35449 9.58434 1.35448C9.18444 1.35448 8.78454 1.35449 8.38464 1.35449C7.55684 1.35451 6.72905 1.35453 5.90125 1.35445C5.70512 1.35443 5.50899 1.35475 5.31286 1.35507C4.81164 1.3559 4.31043 1.35673 3.80927 1.35184C3.438 1.34823 3.15691 1.086 3.13416 0.745131C3.1123 0.417512 3.34635 0.128857 3.69672 0.0586955C3.78269 0.0414782 3.87203 0.0340282 3.95982 0.0339758C4.42121 0.0337 4.8826 0.0332995 5.34399 0.0328989C7.08264 0.0313895 8.82129 0.0298802 10.5599 0.0350476C11.4755 0.037769 11.9978 0.577217 11.9991 1.49589C12.0004 2.39234 12.0001 3.28879 11.9998 4.18524ZM9.47049 8.25082L9.4707 7.07086C9.47094 6.0296 9.47118 4.98834 9.47023 3.94707C9.46945 3.09612 8.94617 2.55991 8.09781 2.55821C5.85913 2.55371 3.62042 2.55394 1.38174 2.55804C0.515196 2.55962 0.000712604 3.09056 0.00047521 3.96766C-0.000294523 6.81718 -0.00014793 9.6667 0.000364626 12.5162C0.000530083 13.4323 0.514486 13.9524 1.44658 13.9559C3.23358 13.9625 5.02063 13.9617 6.80764 13.9557C6.95406 13.9552 7.10069 13.9587 7.24734 13.9621C7.62102 13.9709 7.99489 13.9797 8.36624 13.9234C9.03545 13.8221 9.4677 13.3199 9.46932 12.643C9.47177 11.6158 9.47131 10.5886 9.47085 9.56133C9.47066 9.12449 9.47046 8.68766 9.47049 8.25082Z" fill={color || '#4F4F55'}/>
    </svg>
  );
};

export default PasteIcon;
