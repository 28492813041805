import React, { useState, useCallback } from 'react';
import {
  IconButton, ListItemText, ListItemIcon, MenuItem,
  Popper, MenuList, Grow, Paper, ClickAwayListener,
} from '@mui/material';
import { makeStyles, ThemeProvider, } from '@mui/styles';
import UpIcon from '@mui/icons-material/ExpandLess';
import DownIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '../../../icons/Delete.icon';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '../../../icons/Copy.icon';
import CutIcon from '../../../icons/Cut.icon';
import PasteIcon from '../../../icons/Paste.icon';
import {
  swapSectionChild, swapFormChild, addSimpleWidgetAfter,
  addFormChildAfter, addSimpleWidgetBefore, getIndex,
  appendSectionChildWithQuestion,
} from '../intake.context';
import { useDispatch } from 'react-redux';
import {
  SimpleWidget, ComplexWidgetType, ComplexWidget, FormChild,
} from '../intake-types';
import {
  successSnackbar
} from '../../../components/Snackbar/snackbar.actions';
import { popperMenuTheme } from '../../../components/components.component';
import { useSelector } from '../../../reducers';
import { last, dropWhile, reverse, isEmpty, tail, } from 'ramda';
import {
  capitalize
} from '../../../services/utilities.js';

const useStyles = makeStyles(() => ({
  icon: {
    height: 17,
    width: 20,
    marginLeft: 2,
  },
  delete: {
    fontSize: 18,
    marginLeft: 3,
  },
}));


/*
  `questionIndex` is the index of that question for conditionals.
*/

interface OrderButtonType {
  index: number;
  sectionMaxIndex?: number;
  data: SimpleWidget | FormChild | ComplexWidgetType | ComplexWidget;
  id: number;
  questionIndex?: number;
  type?: string;
  handleDelete: () => void;
  handleEdit?: () => void;
}

export const OrderButtons = ({
  index, id, data, questionIndex, type = 'Question',
  handleDelete, handleEdit, sectionMaxIndex
}: OrderButtonType) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const ref = React.useRef(null);

  const mainMaxIndex = useSelector((state) => state.forms.maxIndex);
  const children = useSelector((state) => state.forms.revision.children);

  const [anchorEl, setAnchorEl] = useState(null);
  const [hasPaste, setHasPaste] = useState(null);
  const [hasCopy, setHasCopy] = useState(true);
  const [sectionQuestion, setSectionQuestion] = useState(false);

  const maxIndex = React.useMemo(() => {
    return sectionMaxIndex || mainMaxIndex;
  }, [mainMaxIndex, sectionMaxIndex]);

  const openMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const dispType = React.useMemo(() => {
    if (data.type === 'SimpleWidget' &&
        data.questionType.type === 'SectionInstruction') {
      return 'instruction';
    }
    return type.toLowerCase();
  }, [data, type]);

  const cut = useCallback(() => {
    localStorage.setItem('sked-forms-copy', JSON.stringify({
      id: type,
      data,
    }));
    handleDelete();
    setAnchorEl(null);
    dispatch(successSnackbar(`${capitalize(dispType)} cut`));
  }, [dispType, type, data]);

  const copy = useCallback(() => {
    localStorage.setItem('sked-forms-copy', JSON.stringify({
      id: type,
      data,
    }));
    setAnchorEl(null);
    console.log(data);
    dispatch(successSnackbar(`${capitalize(dispType)} copied`));
  }, [type, data, dispType]);

  const paste = useCallback(() => {
    const ls = JSON.parse(localStorage.getItem('sked-forms-copy'));
    if (type === 'Question') {
      dispatch(addSimpleWidgetAfter(id, questionIndex, ls.data));
    } else {
      dispatch(addFormChildAfter(ls.id, id, questionIndex, ls.data));
    }
    setAnchorEl(null);
    let ty = ls.id;
    if (ls.data.questionType.type === 'SectionInstruction') {
      ty = 'Instruction';
    }
    dispatch(successSnackbar(`${ty} pasted`));
  }, [questionIndex, id]);

  const pasteQuestion = useCallback((direction: 'top' | 'bottom') => () => {
    const ls = JSON.parse(localStorage.getItem('sked-forms-copy'));
    if (data.type === 'Section' || data.type === 'PrivateSection') {
      let child = direction === 'bottom' ? last(data.children) : data.children[0];
      console.log(child);
      if (child) {
        const questionIndex = getIndex(children, 'question', child.id);
        if (direction === 'bottom') {
          dispatch(addSimpleWidgetAfter(child.id, questionIndex, ls.data));
        } else {
          dispatch(addSimpleWidgetBefore(child.id, questionIndex, ls.data));
        }
      } else {
        const remaining = dropWhile(({ id }: FormChild) => id !== data.id, reverse(children));
        const next = tail(remaining).find((fc: FormChild) => {
          if (fc.type === 'Section' || fc.type === 'PrivateSection') {
            if (!isEmpty(fc.children)) {
              return fc;
            }
          }
        });
        if (next.type === 'Section' || next.type === 'PrivateSection') {
          child = last(next?.children || []);
        }
        let questionIndex;
        if (child) {
          questionIndex = getIndex(children, 'question', child.id);
        } else {
          questionIndex = 0;
        }
        dispatch(appendSectionChildWithQuestion(data.id, questionIndex, ls.data));
      }
    }
    setAnchorEl(null);
    let ty = ls.id;
    if (ls.data.questionType.type === 'SectionInstruction') {
      ty = 'Instruction';
    }
    dispatch(successSnackbar(`${ty} pasted`));
  }, [children]);

  const swapUp = useCallback(() => {
    if (type === 'Question') {
      dispatch(swapSectionChild(id, -1, questionIndex));
    } else {
      dispatch(swapFormChild(id, -1));
    }
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 300);
  }, [type, questionIndex, id]);

  const swapDown = useCallback(() => {
    if (type === 'Question') {
      dispatch(swapSectionChild(id, 1, questionIndex));
    } else {
      dispatch(swapFormChild(id, 1));
    }
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 300);
  }, [type, questionIndex, id]);

  const height = React.useMemo(() => {
    if (type === 'Question') {
      return index === 0 ? 82 : 'auto';
    }
    return 'auto';
  }, [index, type]);

  React.useEffect(() => {
    const ls = JSON.parse(localStorage.getItem('sked-forms-copy'));
    if (type === 'Question')
      setHasPaste(ls?.id === type);
    else {
      setHasPaste(ls?.id !== 'Revision');
      setSectionQuestion(ls?.id === 'Question' && type === 'Section');
    }
    if (type === 'Page Break')
      setHasCopy(false);
  }, [anchorEl]);

  const edit = useCallback(() => {
    handleEdit();
    setAnchorEl(null);
  }, [handleEdit]);

  return (
    <div
      key={id}
      ref={ref}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height,
      }}>
      {handleEdit &&
        <IconButton
          className={`sked-test-form-builder-question${questionIndex}-edit-button`}
          onClick={edit}>
          <EditIcon />
        </IconButton>}
      <IconButton
        className={`sked-test-form-builder-question${questionIndex}-menu-button`}
        onClick={openMenu}>
        <MenuIcon />
      </IconButton>
      <ThemeProvider theme={popperMenuTheme()}>
        <Popper
          key={`menu-${id}`}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          role={undefined}
          placement="top-start"
          transition
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'right bottom',
              }}
            >
              <Paper sx={{ borderRadius: 2, border: '1px solid #C5C5C6' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={Boolean(anchorEl)}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    sx={{
                      padding: 0,
                    }}
                  >
                    <MenuItem onClick={handleDelete}>
                      <ListItemIcon>
                        <DeleteIcon className={classes.icon}/>
                      </ListItemIcon>
                      <ListItemText
                        primary='Delete'
                      />
                    </MenuItem>
                    <MenuItem
                      disabled={index === 0}
                      style={{ opacity: index === 0 ? 0.5 : 'unset' }}
                      onClick={swapUp}>
                      <ListItemIcon>
                        <UpIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary='Move up'
                      />
                    </MenuItem>
                    <MenuItem
                      disabled={index === maxIndex}
                      style={{ opacity: index === maxIndex ? 0.5 : 'unset' }}
                      onClick={swapDown}>
                      <ListItemIcon>
                        <DownIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary='Move down'
                      />
                    </MenuItem>
                    {hasCopy && [
                      <MenuItem onClick={cut}>
                        <ListItemIcon>
                          <CutIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Cut ${dispType}`}
                        />
                      </MenuItem>,
                      <MenuItem onClick={copy}>
                        <ListItemIcon>
                          <CopyIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Copy ${dispType}`}
                        />
                      </MenuItem>
                    ]}
                    {sectionQuestion && [
                      <MenuItem
                        onClick={pasteQuestion('top')}
                        disabled={!hasPaste}
                        style={{ opacity: !hasPaste ? 0.5 : 'unset' }}
                      >
                        <ListItemIcon>
                          <PasteIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary='Paste at the top'
                        />
                      </MenuItem>,
                      <MenuItem
                        onClick={pasteQuestion('bottom')}
                        disabled={!hasPaste}
                        style={{ opacity: !hasPaste ? 0.5 : 'unset' }}
                      >
                        <ListItemIcon>
                          <PasteIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary='Paste at the bottom'
                        />
                      </MenuItem>]}
                    {!sectionQuestion &&
                      <MenuItem
                        onClick={paste}
                        disabled={!hasPaste}
                        style={{ opacity: !hasPaste ? 0.5 : 'unset' }}
                      >
                        <ListItemIcon>
                          <PasteIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary='Paste below'
                        />
                      </MenuItem>}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ThemeProvider>
    </div>
  );
};
