import React, { ComponentProps } from 'react';

export const WarningIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} {...props} xmlns="http://www.w3.org/2000/svg" width="17.275" height="16.279" viewBox="0 0 17.275 16.279">
      <g id="Group_16403" data-name="Group 16403" transform="translate(0 -14.759)">
        <g id="Group_11472" data-name="Group 11472" transform="translate(0 14.759)">
          <path id="Path_8613" data-name="Path 8613" d="M16.916,27.216,10.814,16a2.531,2.531,0,0,0-4.352,0L.359,27.216a2.529,2.529,0,0,0,2.176,3.823H14.74A2.53,2.53,0,0,0,16.916,27.216Zm-8.279,1.8A1.012,1.012,0,1,1,9.65,28,1.014,1.014,0,0,1,8.638,29.014ZM9.65,24.965a1.012,1.012,0,1,1-2.024,0V19.9a1.012,1.012,0,1,1,2.024,0Z" transform="translate(0 -14.759)" fill={color || '#f0da74'}/>
        </g>
      </g>
    </svg>
  );
};

export default WarningIcon;
