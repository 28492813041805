import * as React from 'react';
import { ComplexWidget, ComplexWidgetType, IndexAndConds, } from '../intake-types';
import { Spacer } from '../../../components/PageHeader';
import {
  deleteSectionChild
} from '../intake.context';
import { OrderButtons } from './SectionChildOrderButtons.component';
import { useDispatch } from 'react-redux';
import QuestionLabel from './QuestionLabel.component';

interface SignatureProps {
  data: ComplexWidget | ComplexWidgetType,
  required: boolean,
  id: number
  index?: number;
  maxIndex?: number;
  hasClose?: boolean;
  forOffice: boolean;
  questionCond: IndexAndConds;
}

export const SignatureComponent = ({
  id, data, index, maxIndex, /*hasClose = true,*/ forOffice, questionCond,
}: SignatureProps) => {
  const dispatch = useDispatch();

  /* TODO: hasClose used to do something here and I don't remember
     what it did...
  */
  const handleDelete = () => {
    dispatch(deleteSectionChild(id, questionCond.index));
  };

  console.log('a sig', data);

  return (
    <div style={{
      display: 'flex',
      marginBottom: 10,
    }}>
      <div>
        {!forOffice &&
          <QuestionLabel cond={questionCond} complex />}
        <div><strong>Signature Widget</strong></div>
      </div>
      <Spacer />
      <OrderButtons
        index={index}
        sectionMaxIndex={maxIndex}
        data={data}
        id={id}
        handleDelete={handleDelete}
      />
    </div>
  );
};
