import React, { ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  backgroundColor?: string;
}

const WarningIcon = ({ color, backgroundColor, className, ...props }: Props) => {
  return (
    <svg className={className} {...props} width="26" height="26" viewBox="0 0 26 26">
      <defs>
        <clipPath id="clip-path-warning-with-background">
          <rect id="Rectangle_12041" data-name="Rectangle 12041" width="15.819" height="14.919" fill={color || '#fcc713'}/>
        </clipPath>
      </defs>
      <g id="Yellow_Warning_With_Background" data-name="Yellow Warning With Background" transform="translate(-2221 -824)">
        <rect id="Rectangle_16675" data-name="Rectangle 16675" width="26" height="26" rx="6" transform="translate(2221 824)" fill={backgroundColor || '#fff8e0'} opacity="0.827"/>
        <g id="Group_18246" data-name="Group 18246" transform="translate(2226.09 829.539)">
          <g id="Group_15798" data-name="Group 15798" transform="translate(0 0)" clip-path="url(#clip-path-warning-with-background)">
            <path id="Path_8963" data-name="Path 8963" d="M7.912,14.919c-1.761,0-3.522,0-5.282,0A3.134,3.134,0,0,1,1.3,14.67,2.272,2.272,0,0,1,.161,11.81c.266-.613.6-1.2.916-1.787Q2.223,7.892,3.384,5.769,4.567,3.6,5.756,1.428A2.593,2.593,0,0,1,6.962.208a2.281,2.281,0,0,1,2.923.926c.3.48.554.989.825,1.487q2.109,3.869,4.213,7.74c.256.471.522.939.73,1.431a2.265,2.265,0,0,1-1.19,2.9,2.789,2.789,0,0,1-1.13.221c-1.807.006-3.615,0-5.422,0h0m.929-7.9h.006c0-.686,0-1.373,0-2.059a2.468,2.468,0,0,0-.04-.476.917.917,0,0,0-1.771-.061,2.206,2.206,0,0,0-.062.55c0,1.367,0,2.735,0,4.1,0,.1,0,.207.009.309a.894.894,0,0,0,.552.812.912.912,0,0,0,.963-.125.9.9,0,0,0,.343-.746c.006-.769,0-1.538,0-2.306M7.9,13.059a.91.91,0,0,0,.94-.919.929.929,0,1,0-.94.919" transform="translate(0 0)" fill={color || '#fcc713'}/>
          </g>
        </g>
      </g>
    </svg>

  );
};

export default WarningIcon;
