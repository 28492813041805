import { merge, pick, cond, equals, always, T, identity, pathOr } from 'ramda';
import api from '../../services/api.js';
import * as at from '../../actionTypes';
import { popup } from '../../services/Popup.js';
import { remoteAction } from '../../services/actionService.js';

export const regPatch = ({ type, data }) => ({
  type,
  data,
});

const regMerge = (data) => ({
  type: at.REG_PATCH,
  data,
});

const register = (data, isSpark) => (dispatch) =>
  dispatch(remoteAction({
    type: at.REG_REGISTER,
    action: () => api.post(`/register/office/${isSpark ? 'spark' : 'sked'}`, data)
  })).catch((error) => {
    console.log('made it to the error');
    const errorMessage = pathOr('Something went wrong! Please reach out to our support team and they will assist you.', ['response', 'data'], error);
    popup('Error!', errorMessage);
    return error;
  });

export const submitForm = ({
  step3Data, setupIntentId, isSpark
}) => (dispatch, getStore) => {
  dispatch(regMerge(step3Data));
  const reg = getStore().registration;
  const office = merge(pick([
    'email',
    'stripeToken',
    'notificationEmail',
    'password',
    'officeName',
    'timezone',
    'phone',
    'address',
    'cardName',
    'plan',
    'referrer',
    'policy',
    'valuables',
  ], reg), {
    billingAddress: reg.isBillingAddressDifferent ? reg.billingAddress : null,
    promo: reg.hasPromo ? reg.promo : null,
    doctorContact: {
      name: reg.doctorName,
      phone: reg.doctorPhone
    },
    ehrSystem: cond([
      [equals('Platinum'), always('PlatinumApi')],
      [equals('None/Other'), always('None')],
      [T, identity]
    ])(reg.ehrSystem),
    setupIntentId,
  });
  return dispatch(register(office, isSpark));
};

export const createIntent = ({ isSpark }) => {
  const t = isSpark ? 'spark' : 'sked';
  return api.post(`register/office/${t}/create-intent`);
};
