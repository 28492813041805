import React, { useEffect, useState, useMemo } from 'react';
import { useTitle } from '../../services/useTitle';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import { History } from 'history';
import AddIcon from '@mui/icons-material/Add';
import {
  CircularProgress, Grid,
} from '@mui/material';
import {
  MessageTable
} from '../../routes/RecurringMessage/components/messages-table.component.jsx';
import {
  Automation
} from '../../routes/Automations/automation-types';
import {
  MessageQueryType, getMessagesByPath, MessageQueryOuput, Message, toggleMessage,
  deleteMessage, MessageWithAutomation,
} from '../../components/AutomationBasedMessage/automation-message.actions';
import Container from '../../components/Container/Container.component';
import { usePromise } from '../../services/promise.hook';
import {
  adjust, assoc, cond, equals, head, tail, last, dropLast, includes, always,
} from 'ramda';
import { PopupTemplate } from '../../services/Popup.js';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';
import {
  Folders, currentPath
} from '../../components/Folders/folders.component';
import { useSelector } from '../../reducers';
import { getFeatureForTemplate } from '../../services/utilities.js';

interface AutomationBasedMessageListProps {
  history: History;
  path: string;
  composeUrl: string; // must end with a '/'
  title: string;
}

interface MessageWithSelect extends MessageWithAutomation {
  selected?: boolean;
}

const tabs = [{
  label: 'Messages',
  value: 'recurring',
  className: 'sked-test-birthday-messages'
}, {
  label: 'My Templates',
  value: 'templates',
  className: 'sked-test-birthday-templates'
}, {
  label: 'SKED Templates',
  value: 'sked-templates',
  className: 'sked-test-birthday-sked-templates'
}];

interface Search {
  recurring: string;
  templates: string;
  'sked-templates': string;
}

const AutomationBasedMessageList = ({
  history, path, composeUrl, title,
}: AutomationBasedMessageListProps) => {
  useTitle(title);
  const [messages, setMessages] = useState<MessageWithSelect[]>([]);
  const [perPage] = useState(100);
  const [page] = useState(1);
  const [folderHeight, setFolderHeight] = React.useState(0);
  const [tab, setTab] = useState<keyof Search>('recurring');

  const {
    timezone, headerHeight, features,
  } = useSelector((state) => ({
    timezone: state.login.office.timezone,
    features: state.login.features,
    headerHeight: state.login.headerHeight,
  }));

  const hasFeature = useMemo(() => {
    const feature = cond([
      [includes('smart_reply'), always('SmartReply')],
      [includes('time_based'), always('AutomationBasedMessages')],
    ])(path);
    return includes(feature, features);
  }, [features, path]);

  const forFeature = useMemo(() => {
    return getFeatureForTemplate(features);
  }, [features]);

  const messageState = usePromise<MessageQueryType, MessageQueryOuput>(
    getMessagesByPath, null
  );
  const updateState = usePromise<MessageWithAutomation, (Message | Automation)[]>(
    toggleMessage,
  null
  );
  const deleteState = usePromise<{ msgId: number, path: string }, void>(
    deleteMessage,
    null
  );

  useEffect(() => {
    window.document.title = title;
  }, [title]);

  useEffect(() => {
    const npath = tab === 'sked-templates' ?
      `tmp.${path}`
      :
      path;
    messageState.invoke({
      perPage,
      page,
      path: npath,
      isTemplate: tab === 'templates',
      isSkedTemplate: tab === 'sked-templates',
      forFeature,
    }).then(({ data }) => {
      setMessages(data);
    });
  }, [tab, path]);

  const filterByFolder = (folder: string[]) => {
    if (folder) {
      const strFolder = folder.join('.');
      return messageState.invoke({
        perPage,
        page,
        path: strFolder,
        isTemplate: tab === 'templates',
        isSkedTemplate: tab === 'sked-templates',
        forFeature,
      }).then(({ data }) => {
        setMessages(data);
      });
    }
    const npath = tab === 'sked-templates' ?
      `tmp.${path}`
      :
      path;
    return messageState.invoke({
      perPage,
      page,
      path: npath,
      isTemplate: tab === 'templates',
      isSkedTemplate: tab === 'sked-templates',
      forFeature,
    }).then(({ data }) => {
      setMessages(data);
    });
  };

  const folderPath = useMemo(() => {
    return cond([
      [equals('recurring'), () => `${path}.fdr`],
      [equals('templates'), () => {
        const sp = path.split('.');
        return `${sp[0]}.${sp[1]}.tmp.${sp[2]}`;
      }],
      [equals('sked-templates'), () => {
        const sp = path.split('.');
        return `tmp.${sp[0]}.${sp[2]}`;
      }],
    ])(tab);
  }, [tab, path]);

  const editUrl: string = useMemo(() => {
    if (tab === 'sked-templates') {
      return `${composeUrl}/edit/message/0?fromSkedTemplate=`;
    }
    const t = tab === 'templates' ? 'template/' : 'message/';
    return `${composeUrl}/edit/${t}`;
  }, [composeUrl, tab]);

  const compose = () => {
    history.push(`${editUrl}0`);
  };

  const selectAll = ({ value }: { value: boolean }) => {
    setMessages(messages.map((msg) => ({
      ...msg,
      selected: value,
    })));
  };

  const selectMessage = ({
    index, value
  }: { index: number, value: boolean }) => {
    setMessages(adjust(index, assoc('selected', value), messages));
  };

  const copyMessage = (message: Message) => {
    if (tab === 'sked-templates')
      history.push(`${editUrl}${message.id}`);
    else
      history.push(`${editUrl}0?copying=${message.id}`);
  };

  const toggle = async (message: MessageWithAutomation) => {
    const real = await updateState.invoke(message);
    setMessages(messages.map((msg) => {
      if (msg.id === head(real).id) {
        return {
          ...head(real),
          selected: msg.selected,
          messageType: {
            Automation: tail(real) as Automation[],
          },
        } as MessageWithSelect;
      } else {
        return msg;
      }
    }));
  };

  const localDeleteMessage = async (message: Message) => {
    await deleteState.invoke({
      msgId: message.id,
      path,
    }).then(() => {
      setMessages(messages.filter(({ id }) => {
        return id !== message.id;
      }));
    });
  };

  const deleteSelected = ({ messages }: { messages: MessageWithSelect[] }) => {
    const reqs = messages
      .filter(({ selected }) => selected)
      .map(({ id }) => {
        deleteState.invoke({ msgId: id, path });
      });
    Promise.all(reqs).then(() => {
      setMessages(messages.filter(({ selected }) => !selected));
    });
  };

  const messagesPatch = (prop: string, value: any) => {
    console.log(prop, value);
  };

  const doSearch = (data: any) => {
    const npath = (last(data.path) === 'fdr' ? dropLast(1, data.path) : data.path).join('.');
    messageState.invoke({
      perPage,
      page,
      path: npath,
      isTemplate: tab === 'templates',
      isSkedTemplate: tab === 'sked-templates',
      forFeature,
    }).then(({ data }) => {
      setMessages(data);
    });
  };

  const busy = useMemo(() => {
    return updateState.loading || deleteState.loading || messageState.loading;
  }, [updateState.loading, deleteState.loading, messageState.loading]);

  const deselect = useMemo(() => {
    return !currentPath[folderPath];
  }, [messageState.loading, folderPath]);

  const heightDiff = headerHeight + folderHeight + 45;

  if (!hasFeature) {
    return (
      <>
        <Header title={title} />
        <div style={{
          padding: '20px',
          paddingBottom: '60px',
        }}>
          <div style={{ width: '600px' }}>
            <p>
              With the power of the gum-gum fruit, Luffy and friends begin their adventure towards becoming the king of the pirates!
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <PopupTemplate />
      <Header
        title={title}
        pageId={title.replace(/ /g, '-')?.toLowerCase()}
        leftIcons={[
          <HeaderTabs
            value={tab}
            setTab={(value: keyof Search) => setTab(value)}
            tabs={tabs}
          />,
        ]}
        rightIcons={[
          <Grid>
            {busy && <CircularProgress size={18} />}
          </Grid>,
          tab !== 'sked-templates' &&
          <HeaderButton
            title='New Message'
            className='sked-test-time-based-compose'
            Icon={AddIcon}
            onClick={compose} />,
        ]}
        onlyIconsWidth={tab === 'templates' ? 940 : 848}
        breakPoints={tab === 'templates' ? [
          {
            width: 796,
            mobileItems: [2, 4]
          },
          {
            width: 752,
            mobileItems: [0]
          },
        ] : [
          {
            width: 752,
            mobileItems: [0]
          },
        ]}
      />
      <div>
        <Container getHeight={setFolderHeight} paddingX={2}>
          <Folders
            template={tab === 'sked-templates'}
            path={folderPath.split('.')}
            onClick={filterByFolder}
            deselect={deselect}
          />
        </Container>
        <MessageTable
          pageName={tab}
          messageType='automation'
          busy={messageState.loading}
          messages={messages}
          url={`${composeUrl}/view-message/`}
          tz={timezone}
          selectAll={selectAll}
          selectMessage={selectMessage}
          copyMessage={copyMessage}
          deleteMessage={localDeleteMessage}
          deleteSelected={deleteSelected}
          toggle={toggle}
          page={page}
          query={''}
          filter={{
            value: 'IsEnabledAsc',
            field: 'IsEnabled',
            direction: 'Desc'
          }}
          totalPages={messageState.data?.totalPages || 0}
          totalCount={messageState.data?.totalCount || 0}
          messagesPatch={messagesPatch}
          hideSearch
          search={doSearch}
          error=''
          path={folderPath.split('.')}
          noFolder
          heightDiff={heightDiff}
        />
      </div>
    </>
  );
};

export default AutomationBasedMessageList;
