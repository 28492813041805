import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const PaperAirplaneIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} width="16.682" height="15.884" viewBox="0 0 16.682 15.884">
      <defs>
        <clipPath id="paper-airplane-clip-path">
          <rect id="Rectangle_16651" data-name="Rectangle 16651" width="16.682" height="15.884" transform="translate(0 0)" fill={color || '#37aefd'}/>
        </clipPath>
      </defs>
      <g id="Group_18217" data-name="Group 18217" transform="translate(0 0)">
        <g id="Group_18212" data-name="Group 18212" transform="translate(0 0)" clipPath="url(#paper-airplane-clip-path)">
          <path id="Path_10525" data-name="Path 10525" d="M6.81,11.332c1.167-1.4,2.275-2.737,3.381-4.071,1.089-1.314,2.175-2.629,3.17-4.036q-1.77,1.506-3.538,3.013C8.236,7.6,6.66,8.966,5.055,10.3a.987.987,0,0,1-.792.149C3.144,10.108,2.035,9.73.943,9.311A4.7,4.7,0,0,1,0,8.656,4.256,4.256,0,0,1,.809,7.91Q7.548,4.358,14.307.844c.481-.251.951-.53,1.447-.745a.879.879,0,0,1,.7-.041.821.821,0,0,1,.218.674c-.4,2.75-.824,5.494-1.236,8.241q-.295,1.964-.563,3.932c-.13.947-.315,1.071-1.227.76-2.236-.761-4.472-1.525-6.841-2.334" transform="translate(0 0)" fill={ color || '#37aefd'}/>
          <path id="Path_10526" data-name="Path 10526" d="M18.3,36.58l2.812.958c-.643.871-1.233,1.7-1.865,2.5a.686.686,0,0,1-.6.211.693.693,0,0,1-.335-.535c-.033-1.019-.015-2.039-.015-3.13" transform="translate(-12.192 -24.375)" fill={color || '#37aefd'}/>
        </g>
      </g>
    </svg>
  );
};

export default PaperAirplaneIcon;
