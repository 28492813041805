import * as React from 'react';
import {
  TableContainer, Table, TableRow,
  TableHead, TableBody, TableCell, Button, TextField,
  Typography, Tooltip, FormControlLabel, Checkbox,
} from '@mui/material';
import { Spacer } from '../../../components/PageHeader';
import AddIcon from '@mui/icons-material/Add';
import { append, remove, update } from 'ramda';
import {
  ComplexWidget, ComplexWidgetFixedTable, SimpleWidget, SectionChild,
  IndexAndConds
} from '../intake-types';
import {
  updateSimpleWidget, deleteSectionChild,
} from '../intake.context';
import { SimpleWidgetComponent } from './SimpleWidgetContainer.component';
import { OrderButtons } from './SectionChildOrderButtons.component';
import { useDispatch } from 'react-redux';
import QuestionLabel from './QuestionLabel.component';

interface FixedTableEditProps {
  id: number;
  data: ComplexWidgetFixedTable,
  onSave: (
    rows: string, header: string, columns: SimpleWidget[], required: boolean, forOffice: boolean,
  ) => void,
  onCancel: () => void,
  required: boolean;
  forOffice: boolean;
}

interface FixedTableProps {
  id: number;
  data: ComplexWidget,
  index: number;
  maxIndex: number;
  required: boolean;
  forOffice: boolean;
  questionCond: IndexAndConds;
}

export const FixedTableEditComponent = ({
  data, onSave, onCancel, required, forOffice, id,
}: FixedTableEditProps) => {
  const [rows, setRows] = React.useState<string>(data.rows.labels.join('\n'));
  const [header, setHeader] = React.useState<string>(data.rows.header);
  const [columns, setColumns] = React.useState<SimpleWidget[]>(data.inputs);
  const [localRequired, setLocalRequired] = React.useState<boolean>(required);
  const [localForOffice, setLocalForOffice] = React.useState<boolean>(forOffice);

  const handleSave = () => {
    onSave(rows, header, columns, localRequired, localForOffice);
  };

  const addColumn = () => {
    const newWidget: SimpleWidget = {
      id: columns.length + 1,
      type: 'SimpleWidget',
      label: '',
      questionType: { type: 'ShortText' },
      required: false,
      forOffice,
      width: 12,
    };
    setColumns(append(newWidget, columns));
  };

  const handleDelete = (idx: number) => {
    setColumns(remove(idx, 1, columns));
  };

  const handleUpdate = (idx: number) => (newWidg: SimpleWidget) => {
    setColumns(update(idx, newWidg, columns));
  };

  const handleCheck = (t: 'private' | 'required') => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (t === 'required') {
      setLocalRequired(e.target.checked);
    } else {
      setLocalForOffice(e.target.checked);
    }
  };

  console.log(data, columns);

  return (
    <div>
      <Typography variant="body1" component="p" gutterBottom>
        Create a table with a set number of rows and columns. Use the rows as questions, and the columns will be the answers to those questions.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={localRequired || false}
            onChange={handleCheck('required')}
            color="primary"
          />}
        label="Required" />
      <FormControlLabel
        control={
          <Checkbox
            checked={localForOffice || false}
            onChange={handleCheck('private')}
            color="primary"
          /> }
        label="Private" />
      <TextField
        style={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
        value={header}
        onChange={e => setHeader(e.target.value)}
        type="text"
        label="Header Name" />
      <br />
      <br />
      <TextField
        style={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
        multiline
        minRows={4}
        variant="outlined"
        value={rows}
        onChange={e => setRows(e.target.value)}
        helperText="One choice per line"
        type="text"
        label="Rows" />
      <div>Columns</div>
      {columns.map((col, idx) => {
        return (
          <SimpleWidgetComponent
            key={`fixed-${id}-${idx}`}
            data={col}
            hideWidth
            hideRequired
            hideSave
            isEdit
            handleDelete={() => handleDelete(idx)}
            onUpdate={handleUpdate(idx)}
            noTemp
          />
        );
      })}
      <Button
        color="primary"
        variant="contained"
        onClick={addColumn}
        startIcon={<AddIcon />}
      >
        Add Column
      </Button>
      <br />
      <br />
      <Button color="primary" variant="outlined" onClick={handleSave}>Save</Button>
      &nbsp;&nbsp;
      <Button color="primary" variant="outlined" onClick={onCancel}>Cancel</Button>
    </div>
  );
};

export const FixedTableComponent = ({
  id, data, index, maxIndex, required, forOffice, questionCond
}: FixedTableProps) => {
  const tdata = data.typeData as ComplexWidgetFixedTable;
  const dispatch = useDispatch();
  const [edit, setEdit] = React.useState(false);
  const handleSave = (
    rows: string, header: string, columns: SimpleWidget[], isRequired: boolean, forOffice: boolean
  ) => {
    setEdit(false);
    const updatedFixedTable: SectionChild = {
      id,
      width: 12,
      type: 'ComplexWidget',
      required: isRequired,
      forOffice,
      typeData: {
        type: 'FixedTable',
        rows: {
          header,
          labels: rows.split('\n'),
        },
        inputs: columns,
      }
    };
    dispatch(updateSimpleWidget(updatedFixedTable));

  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleDelete = () => {
    dispatch(deleteSectionChild(id, questionCond.index));
  };

  if (edit) {
    return (
      <FixedTableEditComponent
        id={id}
        data={tdata}
        onSave={handleSave}
        onCancel={handleCancel}
        required={required}
        forOffice={forOffice}
      />
    );
  }
  return (
    <div>
      {!forOffice &&
          <QuestionLabel cond={questionCond} complex />}
      <div style={{ display: 'flex' }}>
        <div>Fixed Table</div>
        <Spacer />
        <OrderButtons
          index={index}
          sectionMaxIndex={maxIndex}
          data={data}
          id={id}
          questionIndex={questionCond.index}
          handleDelete={handleDelete}
        />
      </div>
      <div>Required: {required ? 'yes' : 'no'}</div>
      <h3>{tdata.rows.header}</h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              {tdata.inputs.map((i) => {
                return (
                  <TableCell key={i.id} align="center">
                    {i.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tdata.rows.labels.map((label) => {
              return (
                <TableRow>
                  <TableCell>{label}</TableCell>
                  {tdata.inputs.map((i) => {
                    if (i.questionType.type === 'Radio' ||
                      i.questionType.type === 'Dropdown' ||
                      i.questionType.type === 'Checkbox') {
                      const options = i.questionType.options.map((o) => {
                        return [
                          <br />,
                          o
                        ];
                      });
                      return (
                        <TableCell align="center">
                          <Tooltip arrow title={
                            <>
                              Options:
                              {options}
                            </>
                          }>
                            <div>
                              {i.questionType.type} {i.required ? '(required)' : ''}
                            </div>
                          </Tooltip>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell align="center">
                        {i.questionType.type}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        color="primary"
        onClick={() => setEdit(true)}
        variant="outlined"
        style={{
          marginTop: '10px',
        }}>
        Edit
      </Button>
    </div>
  );
};
