import { AnyAction } from 'redux';
import * as R from 'ramda';

import * as actionTypes from './snackbar.actionTypes';

export type Severity = 'error' | 'info' | 'success' | 'warning';

type AlertsReducer = {
  id: number;
  message: string;
  timer?: number;
  severity: Severity;
}

export default function reducer(state: AlertsReducer[] = [], action: AnyAction): AlertsReducer[] {
  switch (action.type) {
    case actionTypes.ADD_SNACKBAR:
      return [...state, { ...action.data }];
    case actionTypes.CLOSE_SNACKBAR: {
      const index = R.findIndex(R.propEq('id', action.id))(state);
      return [...R.remove(index, 1, state)];
    }
    default:
      return state;
  }
}
