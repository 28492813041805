import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { History } from 'history';

import {
  IconButton,
  TextField,
  Tabs,
  Tab,
  Badge,
  Typography,
  Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import * as R from 'ramda';
import api from '../../../../services/api';

import Appointments from '../../../Appointments/Appointments.component';
import { Appointment } from '../../../Appointments/appointments.types';
import MessageTab from '../../../../components/MessageTab/MessageTab.component';
import AptsTable, { Professional } from '../../../../components/Appointments/AppointmentTable.component';
import SimpleNotes from '../../../../components/SimpleNotes/simple-notes.component';
import { Lead, Message } from '../../leads.reducer';
import * as leadActions from '../../leads.actions';
import MessageDialog from '../../../Messages/components/messageDialog.component.jsx';
import Modal from '../../../../components/Modal/Modal.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import ModalTabs from '../../../../components/ModalTabs/ModalTabs.component';
import { useSelector } from '../../../../reducers';

import EditFields from './EditFields.component';

import { useStyles } from './editLead.styles';

const WhateverTab = withStyles({
  root: {
    minWidth: 'unset',
  },
})(Tab);

type EditLeadDialogProps = {
  open: boolean;
  close: () => void;
  lead: Lead;
  convertClick: () => void;
  onUpdate?: () => void;
  history?: History;
}

const EditLeadDialog = ({ open, close, lead, convertClick, onUpdate, history }: EditLeadDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [leadEdit, setLeadEdit] = useState<Lead>(null);
  const [seeMore, setSeeMore] = useState(false);
  const [tab, setTab] = useState('msgs');
  const [messageTab, setMessageTab] = React.useState('Message Thread');
  const [notesTab, setNotesTab] = React.useState('Notes');
  const [smallModal, setSmallModal] = React.useState(false);
  const [tags, setTags] = useState<{ tagId: number, tag: string }[]>([]);
  const {
    tz,
    features,
    messages,
    messagePage,
    messageTotalPages,
    selectedMessage,
    optoutList,
    professionals,
    appointments,
    selectedAppointment,
    aptBusy,
    msgBusy,
    selectedLead,
  } = useSelector(state => ({
    tz: state.login.office.timezone,
    features: R.pathOr([], ['login', 'features'])(state),
    messages: state.leads.messages.data,
    messagePage: state.leads.messages.page,
    messageTotalPages: state.leads.messages.totalPages,
    selectedMessage: state.leads.selectedMessage,
    optoutList: state.leads.optoutList,
    appointments: state.leads.appointments,
    selectedAppointment: state.leads.selectedAppointment,
    aptBusy: state.leads.aptBusy,
    msgBusy: state.leads.msgBusy,
    professionals: R.sortBy(R.prop('isHidden'), state.professionals.professionals),
    selectedLead: state.leads.leads.find(item => item.id === lead?.id)
  }));

  const hasAutomation = R.includes('Automations', features);

  const handleEdit = (key: string, value: string | boolean) => {
    if (leadEdit?.id) {
      setLeadEdit({ ...leadEdit, [key]: value });
    }
  };

  const handleBirthday = (birthday: string) => {
    if (birthday) {
      const [year, month, day] = birthday.split('-');
      return `${month}/${day}/${year}`;
    }
  };

  const handleSetEdit = () => {
    setLeadEdit({ ...lead, birthday: handleBirthday(lead.birthday), blockSMS: selectedLead?.blockSMS });
    setSeeMore(true);
  };

  const handleClose = () => {
    setLeadEdit(null);
    setSeeMore(false);
    setTab('msgs');
    close();
  };

  const handleConvert = () => {
    convertClick();
    handleClose();
  };

  const updateAllowEmail = () => {
    dispatch(leadActions.updateEmailAndSms({ ...lead, allowEmail: !lead.allowEmail }));
  };

  const updateAllowSms = () => {
    dispatch(leadActions.updateEmailAndSms({ ...lead, allowSMS: !lead.allowSMS }));
  };

  const handleSave = () => {
    const removeBirthday = lead.birthday && !leadEdit.birthday;
    dispatch(leadActions.updateLead(leadEdit, removeBirthday));
    onUpdate?.();
    handleClose();
  };

  const handleGetMessages = ({ page, clientId }: { page: number, clientId: number }) => {
    dispatch(leadActions.getLeadMessages(clientId, page));
  };

  const handleSelectMessage = (message: Message = {} as Message) => {
    dispatch(leadActions.selectMessage(message));
  };

  const viewApt = (apt: Appointment) => {
    dispatch(leadActions.viewApt(apt));
  };

  const closeSelectedAppointment = () => {
    dispatch(leadActions.closeSelectedAppointment());
  };

  const getAppointments = () => {
    dispatch(leadActions.getAppointments(lead.id));
  };

  const confirmConvert = () => {
    dispatch(leadActions.convertLead(lead.id));
    handleClose();
  };

  const addTag = async (tag: { tagId: number, tag: string }) => {
    try {
      await api.post('client/tag', { clients: [lead?.id], tagIds: [tag.tagId] });
      const newTags = R.prepend(tag, tags);
      setTags(newTags);
    } catch (error) {
      console.error(error);
    }
  };
  const deleteTag = async (tag: { tagId: number, tag: string }) => {
    try {
      await api.delete('client/tag/client', { data: { clients: [lead?.id], tagIds: [tag.tagId] } });
      const newTags = R.without([tag], tags);
      setTags(newTags);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (lead?.id && open) {
      dispatch(leadActions.getOptout(lead.id));
      dispatch(leadActions.getLeadMessages(lead.id));
      dispatch(leadActions.getAppointments(lead.id));
    }
  }, [lead?.id, open]);

  useEffect(() => {
    if (!optoutList.length) {
      dispatch(leadActions.getOptoutList());
    }
  }, [optoutList]);

  useEffect(() => {
    const getTags = async () => {
      if (hasAutomation) {
        const autoData = {
          page: 1,
          perPage: 100,
          query: {
            clientId: lead?.id,
          },
        };
        const response = await api.post('list/client/clients/query', autoData);
        type List = { list: { id: number; name: string } };
        const autosAsTags = response.data.map(({ list }: List) => ({
          tagId: list.id,
          tag: list.name,
        }));
        setTags(autosAsTags);
      } else {
        const response = await api.get(`client/tag/client/${lead?.id}`);
        setTags(response);
      }
    };
    if (lead?.id) {
      getTags();
    }
  }, [lead?.id, hasAutomation]);

  useEffect(() => {
    const getBlockNumber = async () => {
      const response = await api.get('office/get-blocked-numbers');
      if (R.includes(lead.phone, response)) {
        dispatch(leadActions.updateBlockedNumber(lead?.id, true));
      }
    };
    if (lead?.id) {
      getBlockNumber();
    }
  }, [lead?.id]);


  React.useEffect(() => {
    if (tab === 'generalinfo' && !smallModal) {
      setTab('apts');
    }
  }, [smallModal, tab]);

  if (!lead) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title='view lead'
      className='sked-test-view-lead-modal'
      getWidth={(value) => setSmallModal(value < 760)}
      titleComponent={
        smallModal ? 
          <Typography variant='h4' fontWeight={400}>{`${lead?.firstName} ${lead?.lastName}`}</Typography>
          :
          <div className={classes.titleContainer}>
            {leadEdit?.id ? (
              <div className={classes.row}>
                <TextField
                  label='First Name'
                  value={leadEdit.firstName}
                  required
                  className={classes.input}
                  placeholder="First Name"
                  onChange={(event) => handleEdit('firstName', event.target.value)}
                />
                &nbsp; &nbsp;
                <TextField
                  label='Last Name'
                  value={leadEdit.lastName}
                  className={classes.input}
                  required
                  placeholder="First Name"
                  onChange={(event) => handleEdit('lastName', event.target.value)}
                />
                <IconButton
                  title='save'
                  color='default'
                  size='medium'
                  onClick={handleSave}
                  className='sked-test-view-lead-modal-edit-button-save'
                >
                  <SaveIcon className={classes.icon} />
                </IconButton>
                <IconButton
                  title='Cancel'
                  size='medium'
                  onClick={() => setLeadEdit(null)}
                  className='sked-test-view-lead-modal-edit-button-cancel'
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>
              </div>
            ) : (
              <div className={classes.row}>
                <Typography variant='h4' fontWeight={400}>{`${lead?.firstName} ${lead?.lastName}`}</Typography>
                <IconButton
                  title='Edit'
                  color='default'
                  size='medium'
                  onClick={handleSetEdit}
                  className='sked-test-view-lead-modal-button-edit'
                >
                  <EditIcon className={classes.icon} />
                </IconButton>
              </div>
            )}
          </div>
      }
      buttons={[
        <HeaderButton
          title='Convert'
          color='primary'
          onClick={handleConvert}
          className='sked-test-view-lead-modal-button-convert'
        />,
      ]}
    >
      <div className={classes.relative}>
        {smallModal && (
          <Grid marginBottom={1}>
            <ModalTabs
              tabs={[
                { label: 'General info', value: 'generalinfo' },
                R.includes('SimpleNotes', features) ? {
                  label: 'Notes', value: 'notes', subTabs: {
                    tabs: [
                      { label: 'Notes', value: 'Notes' },
                      { label: 'Archived Notes', value: 'Archived Notes' },
                    ],
                    value: notesTab,
                    setTab: setNotesTab,
                  }
                } : null,
                { label: 'Appointment Request', value: 'apts' },
                {
                  label: 'Messages', value: 'msgs', subTabs: {
                    tabs: [
                      { label: 'Message Thread', value: 'Message Thread' },
                      { label: 'Sent Message History', value: 'Sent Message History' },
                    ],
                    value: messageTab,
                    setTab: setMessageTab,
                  }
                },
              ]}
              value={tab}
              setTab={setTab}
            />
          </Grid>
        )}
        {((seeMore && !smallModal && leadEdit?.id) || (smallModal && tab === 'generalinfo' && leadEdit?.id)) && (
          <EditFields
            handleEdit={handleEdit}
            lead={{ ...leadEdit, allowEmail: lead.allowEmail, allowSMS: lead.allowSMS, blockSMS: lead.blockSMS }}
            setSeeMore={setSeeMore}
            tags={tags}
            updateAllowEmail={updateAllowEmail}
            updateAllowSms={updateAllowSms}
            addTag={addTag}
            deleteTag={deleteTag}
            smallModal={smallModal}
            setLeadEdit={setLeadEdit}
            handleSave={handleSave}
          />
        )}
        {((seeMore && !leadEdit?.id && !smallModal) || (smallModal && tab === 'generalinfo' && !leadEdit?.id)) && (
          <EditFields
            handleEdit={handleEdit}
            lead={lead}
            disabled
            handleSetEdit={handleSetEdit}
            setSeeMore={setSeeMore}
            updateAllowEmail={updateAllowEmail}
            updateAllowSms={updateAllowSms}
            tags={tags}
            addTag={addTag}
            deleteTag={deleteTag}
            smallModal={smallModal}
          />
        )}
        {!seeMore && !smallModal && (
          <div className={classes.leadInfo}>
            <div>
              <span style={{ marginRight: lead?.phone ? 20 : 0 }}>{`${lead?.phone || ''}`}</span>
              <span>{`${lead?.email || ''}`}</span>
            </div>
            <div onClick={() => setSeeMore(true)}>
              <u>Show more...</u>
            </div>
          </div>
        )}
        {!smallModal && (
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, val) => setTab(val)}
            value={tab}
            className={classes.tabs}
          >
            {R.includes('SimpleNotes', features) &&
              <WhateverTab label="Notes" className="tab" value='notes' />
            }
            <WhateverTab label="Messages" className="tab" value='msgs' />
            <WhateverTab label={<Badge className={classes.badge} badgeContent={appointments.length} color="info">Appointment Request</Badge>} className="tab" value='apts' />
          </Tabs>
        )}

        <div hidden={tab !== 'notes'}>
          <SimpleNotes
            client={lead}
            tz={tz}
            open={tab === 'notes'}
            tab={smallModal ? notesTab : undefined}
            setTab={smallModal ? setNotesTab : undefined}
          />
        </div>
        <div hidden={tab !== 'msgs'}>
          <MessageTab
            features={features}
            messages={messages}
            msgBusy={msgBusy}
            client={lead}
            tz={tz}
            actions={{
              viewMessage: handleSelectMessage,
              getMessages: handleGetMessages
            }}
            messagePage={messagePage}
            messageTotalPages={messageTotalPages}
            tab={smallModal ? messageTab : undefined}
            setTab={smallModal ? setMessageTab : undefined}
            history={history}
          />
        </div>
        <div hidden={tab !== 'apts'}>
          <div className="scroll-content" style={{ minHeight: '23vh', marginTop: smallModal ? 10 : 0 }}>
            <AptsTable
              apts={appointments}
              tz={tz}
              professionals={professionals as Professional[]}
              viewApt={viewApt}
              isLead
              loading={aptBusy}
              confirmLead={confirmConvert}
            />
          </div>
        </div>
        <MessageDialog
          open={!!selectedMessage.msgId}
          message={selectedMessage}
          onClose={handleSelectMessage}
          tz={tz}
          openedFrom='CLIENT'
          from={selectedMessage.from}
          back={handleSelectMessage}
          gotoClient={handleSelectMessage}
          history={history}
        />
      </div>
      <Appointments
        open={!!selectedAppointment}
        appointment={selectedAppointment}
        tz={tz}
        openedFrom='CLIENT'
        from='LEADS'
        busy={aptBusy}
        onClose={closeSelectedAppointment}
        back={closeSelectedAppointment}
        cancel={() => getAppointments()}
        resked={() => getAppointments()}
        gotoClient={closeSelectedAppointment}
        isLead
        history={history}
      />
    </Modal>
  );
};

export default EditLeadDialog;
