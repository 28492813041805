import * as at from '../../../../actionTypes';
import {
  now,
  formatStandard,
  startOf,
  endOf,
} from '../../../../services/joda.js';
import {
  ZonedDateTime,
  LocalTime,
  LocalDate,
  ZoneId,
} from '@js-joda/core';
import * as R from 'ramda';
import api from '../../../../services/api.js';
import { etd, delay } from '../../../../services/utilities.js';
import { remoteAction } from '../../../../services/actionService.js';
import { popup } from '../../../../services/Popup.js';

export const clientPatch = (data) => ({
  type: at.CLIENT_PATCH,
  data,
});

export const clientClientPatch = (id, prop, value) => (dispatch) => {
  return api.put(`client/${id}`, { [prop]: value })
    .then(() => {
      return api.get(`client/optout/${id}`).then((data) => {
        dispatch({
          type: at.CLIENTS_UPDATE_CURRENT,
          data: {
            id,
            [prop]: value
          },
        });
        return dispatch({
          type: at.CLIENT_CLIENT_PATCH,
          data: {
            id,
            optout: data,
          },
        });
      });
    }).catch((error) => {
      popup('Error!', 'Failed to update client.');
      console.error(error);
    });
};

export const updateOptout = (id, service, value) => (dispatch) => {
  return api.put(`client/optout/${id}`, [{ service, optout: !value }])
    .then(() => {
      return api.get(`client/optout/${id}`).then((data) => {
        dispatch({
          type: at.CLIENTS_UPDATE_CURRENT,
          data: {
            id,
            optout: data,
          },
        });
        return dispatch({
          type: at.CLIENT_CLIENT_PATCH,
          data: {
            id,
            optout: data,
          },
        });
      });
    }).catch((error) => {
      popup('Error!', 'Failed to update client.');
      console.error(error);
    });
};

export const viewApt = (appointment) => remoteAction({
  type: at.CLIENT_REMOTE_APT_GET,
  action: () => api.get(`appointmentType/${appointment.appointmentTypeId}`)
    .then((data) => {
      const aptWithType = R.assoc('type', data.name)(appointment);
      return api.get(`appointment/${appointment.id}/log`)
        .then((data) => {
          return {
            appointment: R.assoc('aptData', R.reverse(R.sortBy(R.prop('changedAt'))(data)), aptWithType),
          };
        });
    })
    .catch((error) => {
      console.error(error);
    }),
});

export const viewMessage = (message) => ({
  type: at.CLIENT_PATCH,
  data: {
    busy: false,
    state: 'VIEW_MESSAGE',
    selectedMessage: message,
  }
});

export const backToClient = () => (dispatch) => {
  return dispatch({
    type: at.CLIENT_PATCH,
    data: {
      state: 'CLIENT',
    }
  });
};

export const getMessages = ({
  page = 1,
  clientId,
}) => remoteAction({
  type: at.CLIENT_REMOTE_MESSAGES_GET,
  action: () => api.post('sentmsg/query', {
    page,
    perPage: 50,
    query: {
      toOrFromClient: clientId,
      isToOrFromClient: [],
    }
  })
    .then((messages) => {
      return {
        messages: messages.data,
        messageTotalPages: messages.totalPages,
        page,
      };
    }),
});

export const getAppts = (data) => {
  return api.post('appointment/query', data);
};

export const getAppointments = (
  clientId,
  after = now('tz'),
  before = now('tz').plusMonths(1),
  page = 1,
  filter = 'All',
  msg = null
) => {
  let data = {};
  if (filter === 'All')
    data = {
      page,
      perPage: 100,
      query: {
        clientId,
        after: formatStandard(startOf(after, 'day'), false),
        before: formatStandard(endOf(before, 'day'), false),
      }
    };
  else
    data = {
      page,
      perPage: 100,
      query: {
        clientId,
        after: formatStandard(startOf(after, 'day'), false),
        before: formatStandard(endOf(before, 'day'), false),
        status: [
          filter
        ]
      }
    };
  return remoteAction({
    type: at.CLIENT_REMOTE_APTS_GET,
    action: () =>
      getAppts(data)
        .then(({ data }) => {
          return {
            appointments: R.sortBy(R.prop('time'))(data),
            msg,
          };
        }),
  });
};

export const getCurrentClient = (
  client,
  after = now('tz'),
  before = now('tz').plusMonths(1),
  page = 1,
  filter = 'All'
) => (dispatch, getStore) => {
  const hasAutomations = R.pipe(
    R.pathOr(null, ['login', 'features']),
    R.includes('Automations')
  )(getStore());
  const autoData = {
    page: 1,
    perPage: 100,
    query: {
      clientId: client.id,
    },
  };
  let data = {};
  if (filter === 'All')
    data = {
      page,
      perPage: 100,
      query: {
        clientId: client.id,
        after: formatStandard(startOf(after, 'day'), false),
        before: formatStandard(endOf(before, 'day'), false),
      }
    };
  else
    data = {
      page,
      perPage: 100,
      query: {
        clientId: client.id,
        after: formatStandard(startOf(after, 'day'), false),
        before: formatStandard(endOf(before, 'day'), false),
        status: [
          filter
        ]
      }
    };
  return dispatch(
    remoteAction({
      type: at.CLIENT_REMOTE_GET,
      action: () => Promise.all([
        api.post('appointment/query', data),
        api.get(`client/${client.id}/group`),
        api.get(`client/optout/${client.id}`),
        api.get('client/optout/description'),
        api.get(`appointmentType/${client.defaultApptiontmentType}`),
        api.post('sentmsg/query', { page: 1, perPage: 50, query: { toClient: client.id } }),
        api.post('sentmsg/query', {
          page: 1,
          perPage: 50,
          query: {
            toOrFromClient: client.id,
            isToOrFromClient: [],
            smsOnly: [],
            includeDeleted: [],
          }
        }),
        api.get(`client/${client.id}/frequency/all`),
        hasAutomations ? api.post('list/client/clients/query', autoData) : api.get(`client/tag/client/${client.id}`),
        api.get(`client/app/${client.id}`),
      ].map(etd)).then(([
        appointments,
        group,
        optout,
        optoutList,
        type,
        messages,
        threadMessages,
        freq,
        tagz,
        appInfo,
      ]) => {
        return Promise.all([
          api.get(`professional/${type.data.professionalId}`),
          R.isEmpty(group.data) ? undefined : api.get(`group/get/${client.id}`),
        ].map(etd)).then(([pro, groupId]) => {
          let tags = tagz;
          if (hasAutomations) {
            const autosAsTags = tagz.data.data.map(({ list }) => ({
              tagId: list.id,
              tag: list.name,
            }));
            tags = {
              data: autosAsTags,
            };
          }
          return {
            threadMessages,
            messages,
            appointments,
            group,
            groupId: R.propOr(undefined, 'data', groupId),
            client: R.merge(client, { professional: pro, optout: optout.data }),
            optoutList: optoutList.data,
            freq,
            tags,
            appInfo,
          };
        });
      }),
    }));
};

export const updateRange = (client, startDate, endDate, page, filter) => (dispatch) => {
  dispatch({
    type: at.CLIENT_PATCH,
    data: {
      startDate,
      endDate,
    }
  });
  return dispatch(
    getAppointments(
      client.id,
      ZonedDateTime.of(
        LocalDate.parse(startDate),
        LocalTime.MIDNIGHT,
        ZoneId.of('UTC')
      ),
      ZonedDateTime.of(
        LocalDate.parse(endDate),
        LocalTime.MIDNIGHT,
        ZoneId.of('UTC')
      ),
      page,
      filter));
};

export const sendMessage = (body, id) => (dispatch) => {
  const tzNow = formatStandard(now('tz'));
  dispatch(
    remoteAction({
      type: at.CLIENT_REMOTE_POST_MESSAGE,
      params: {
        msgId: tzNow,
        to: {
          ToClient: [],
        },
        created: tzNow,
        smsStatus: 'Pending',
        smsData: {
          body,
          attachmentIds: [],
        },
      },
      action: () => api.post('sentmsg', {
        sms: {
          body,
          subject: '',
          attachmentIds: [],
        },
        sendTo: id,
      }).then((m) => {
        return api.get(`sentmsg/${m.msgId}`).then((msg) => {
          if (msg.smsStatus) {
            return {
              msg: {
                ...msg,
                smsData: {
                  body,
                  attachmentIds: [],
                },
              },
            };
          } else {
            return delay(1000).then(() =>
              api.get(`sentmsg/${msg.msgId}`)
            ).then((mzg) => ({
              msg: mzg,
            }));
          }
        });
      })
    })
  );
};

export const movePage = (client, startDate, endDate, newPage, filter) => (dispatch) => {
  dispatch({
    type: at.CLIENT_PATCH,
    data: {
      page: newPage,
    }
  });
  return dispatch(getCurrentClient(client.id, startDate, endDate, newPage, filter));
};

export const changeFilter = (client, startDate, endDate, page, { value }) => (dispatch) => {
  dispatch({
    type: at.CLIENT_PATCH,
    data: {
      filter: value,
    }
  });

  return dispatch(getAppointments(
    client.id,
    ZonedDateTime.of(
      LocalDate.parse(startDate),
      LocalTime.MIDNIGHT,
      ZoneId.of('UTC')
    ),
    ZonedDateTime.of(
      LocalDate.parse(endDate),
      LocalTime.MIDNIGHT,
      ZoneId.of('UTC')
    ),
    page,
    value));
};

export const reset = () => (dispatch) =>
  dispatch({
    type: at.CLIENT_RESET
  });

export const backToClientApt = () => (dispatch) => {
  return dispatch({
    type: at.CLIENT_PATCH,
    data: {
      state: 'CLIENT',
      appointment: {},
      aptData: [],
    }
  });
};

export const freqPatch = (idx, data) => ({
  type: at.CLIENT_FREQ_PATCH,
  data: {
    index: idx,
    data,
  },
});

export const addFreq = () => ({
  type: at.CLIENT_FREQ_ADD,
});

export const orderFreqs = () => ({
  type: at.CLIENT_FREQ_ORDER,
});

export const removeFreq = (idx) => ({
  type: at.CLIENT_FREQ_REMOVE,
  data: idx,
});

export const saveFreq = (id, freqs) => {
  const data = R.map((f) => {
    const unit = f.unit.split('s')[0];
    if (Number.isNaN(parseInt(f.number))) {
      const num = Number(f.number.split(' ')[1]);
      const realNum = Number.isNaN(num) ? 1 : num;
      return R.mergeRight(f, { number: { 'EveryOther': realNum }, unit });
    } else {
      return R.mergeRight(f, { number: { 'Num': Number(f.number) }, unit });
    }
  }, freqs);
  return remoteAction({
    type: at.CLIENT_PUT_FREQ,
    action: () => api.put(`client/${id}/frequency`, data),
  });
};

export const deleteTag = (clientId, tag) => remoteAction({
  type: at.CLIENT_REMOTE_TAG_DELETE,
  action: () => api.delete('client/tag/client', { data: { clients: [clientId], tagIds: [tag.tagId] } }).then(() => {
    return tag;
  }),
});

export const addTag = (clientId, tag) => remoteAction({
  type: at.CLIENT_REMOTE_ADD_TAG_TO_CLIENT_POST,
  action: () => api.post('client/tag', { clients: [clientId], tagIds: [tag.tagId] }).then(() => {
    return tag;
  }),
});
