import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
    margin: 20,
    minHeight: 200,
    width: '75%',
    minWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  emptyBox: {
    height: '100%',
    width: '100%',
    color: 'gray',
  },
  insideBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px auto auto auto',
    width: 350,
  },
  header: {
    fontSize: 17,
    textAlign: 'center',
    marginBottom: 10,
    fontWeight: 500,
  },
  button: {
    border: '1px solid #d4d4d4 !important',
    backgroundColor: 'white',
    '&:hover': {
      border: '1px solid #d4d4d4 !important',
    },
  },
  otherButton: {
    color: '#008bcf',
    margin: '0px 5px',
    textDecoration: 'none',
  },
}));

export const useModalStyles = makeStyles(() => ({
  root: {
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginRight: 5,
  },
  textContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  text: {
    marginBottom: 10
  },
  rendered: {
    borderRadius: 7,
    padding: '5px 10px',
    backgroundColor: '#f2f2f2',
    fontSize: 14,
    marginTop: 10,
    maxHeight: 200,
    overflowY: 'auto',
  },
}));
